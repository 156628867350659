import React from "react";
import {GetMe} from "../lib/api";
import styled from "react-emotion";
import {adsStorage, userSessionStorage} from "../lib/storage";
import fetch from "../lib/fetch";
import {isMobile, createRefMemoizer} from "../lib/utils";
import {stringify} from "query-string";

const {Provider: CampaignProvider, Consumer: CampaignConsumer} = React.createContext({
  campaigns: null,
  device: "unknown",
});

export class AdCampaigns extends React.Component {
  state = {campaigns: null, device: isMobile() ? "mobile" : "desktop"};
  timeoutId = null;

  componentDidMount() {
    this.fetchPartner();
  }

  componentWillUnmount() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }

  fetchPartner = () => {
    fetch(`/a/variants?device=${this.state.device}`).then(r => {
      if (r.status < 300) this.setState({campaigns: r.jsonData});
    });
    this.timeoutId = setTimeout(this.fetchPartner, 1000 * 3600 * 24);
  };

  render() {
    return <CampaignProvider value={this.state}>{this.props.children}</CampaignProvider>;
  }
}

// const partners = [
//   {
//     name: "vgpmb",
//     url: "https://www.videogamespaymybills.com/amember/aff/go/piq?i=10",
//     banners: [{url: require("../banners/vgpmb-728x90-banner.gif"), dimensions: [728, 90]}],
//   },
// ];

const OuterComp = styled("div")({
  display: "flex",
  flexDirection: "column",
  alignItems: "center",
});
const LinkComp = styled("a")({
  display: "block",
});

const loggedInThreshold = 3 * 24 * 3600 * 1000;
const loggedOutThreshold = 0.5 * 24 * 3600 * 1000;

const findUnsuitableCampaigns = (seenAds, isLoggedIn) => {
  const unsuitable = new Set();
  const now = new Date().getTime();
  Object.keys(seenAds)
    .filter(name => {
      const {lastSeen, timesSeen} = seenAds[name];
      const diff = now - lastSeen;
      if (isLoggedIn) {
        if (diff < loggedInThreshold) return true;
        return Math.random() > (diff - loggedInThreshold) / (loggedInThreshold * 3);
      } else {
        if (timesSeen < 3) return false;
        if (diff < loggedOutThreshold) return true;
        return Math.random() > (diff - loggedOutThreshold) / (loggedOutThreshold * 3);
      }
    })
    .forEach(name => unsuitable.add(parseInt(name, 10)));
  return unsuitable;
};

const createRandomString = () =>
  [1, 2]
    .map(() =>
      Math.random()
        .toString()
        .slice(2, 16)
    )
    .join("-");

class IsVisible extends React.Component {
  observer = null;
  isVisible = false;
  timeoutId = null;

  componentWillUnmount() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }

  handleCallback = ([e]) => {
    this.isVisible = e && e.isIntersecting && e.intersectionRatio >= 0.75;
    if (this.isVisible) {
      this.timeoutId = setTimeout(() => this.props.onVisible(e), 500);
    } else if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  };

  handleRef = n => {
    if (this.observer) {
      this.observer.disconnect();
      this.observer = null;
    }
    if (n) {
      this.observer = new IntersectionObserver(this.handleCallback, {threshold: 0.75});
      this.observer.observe(n);
    }
  };

  render() {
    return this.props.children(this.handleRef);
  }
}

class AdBannerInner extends React.PureComponent {
  memoizeRef = createRefMemoizer();

  constructor(props) {
    super(props);
    const {campaigns} = props;
    const seenAds = adsStorage.get() || {};
    let session = userSessionStorage.get();
    if (!session) {
      session = createRandomString();
      if (!userSessionStorage.set(session)) session = null;
    }
    // {
    //   "[campaignId]": {lastSeen: 123124214213, timesSeen: 4}
    // }
    const unsuitableCamps = findUnsuitableCampaigns(seenAds, props.me);
    const remainingCampaigns = campaigns.filter(c => !unsuitableCamps.has(c.id));
    if (remainingCampaigns.length === 0) {
      this.state = {
        variant: null,
        campaign: null,
        nonce: null,
        session,
        hasBeenSeen: false,
        imgIsLoaded: false,
      };
    } else {
      const campaign = remainingCampaigns[Math.floor(Math.random() * remainingCampaigns.length)];
      const variant = campaign.variants[Math.floor(Math.random() * campaign.variants.length)];
      const nonce = createRandomString();
      this.state = {variant, campaign, nonce, session, hasBeenSeen: false, imgIsLoaded: false};
    }
  }

  handleIsSeen = () => {
    if (this.state.hasBeenSeen) return;
    this.setState({hasBeenSeen: true}, this.checkIfSeenAndLoaded);
  };

  checkIfSeenAndLoaded = () => {
    const {hasBeenSeen, variant, imgIsLoaded} = this.state;
    if (!hasBeenSeen || !imgIsLoaded) return false;
    const {device, location} = this.props;
    fetch("/a/seen", {
      method: "post",
      body: JSON.stringify({variantId: variant.id, device, location}),
    });
    const {campaign} = this.state;
    const seenAds = adsStorage.get() || {};
    seenAds[campaign.id] = {
      lastSeen: new Date().getTime(),
      timesSeen: (seenAds[campaign.id] || {timesSeen: 0}).timesSeen + 1,
    };
    adsStorage.set(seenAds);
  };

  handleRef = img => {
    if (!img) {
      if (this.img) this.img.onload = null;
    } else {
      if (img.complete) {
        return this.setState({imgIsLoaded: true}, this.checkIfSeenAndLoaded);
      } else {
        img.onload = () => {
          this.setState({imgIsLoaded: true}, this.checkIfSeenAndLoaded);
        };
      }
    }
    this.img = img;
  };

  render() {
    const {variant, nonce, session} = this.state;
    if (!variant) return null;
    const {me, device, location, ...rest} = this.props;
    const params = {variantId: variant.id, device, location, nonce, userId: me && me.id, session};
    const url = `${process.env.REACT_APP_API_HOST}/a/click?${stringify(params)}`;
    return (
      <OuterComp {...rest}>
        <LinkComp href={url} rel="nofollow">
          <IsVisible onVisible={this.handleIsSeen}>
            {ref => (
              <img
                ref={this.memoizeRef(ref, this.handleRef)}
                src={variant.imgUrl}
                alt={variant.altText}
                width={variant.imgWidth}
                heigth={variant.imgHeight}
              />
            )}
          </IsVisible>
        </LinkComp>
      </OuterComp>
    );
  }
}

const AdBanner = props => (
  <GetMe>
    {me =>
      (!me || me.$meta.isLoaded) && (
        <CampaignConsumer>
          {({campaigns, device}) =>
            campaigns !== null && (
              <AdBannerInner me={me} device={device} campaigns={campaigns} {...props} />
            )
          }
        </CampaignConsumer>
      )
    }
  </GetMe>
);

export default AdBanner;
