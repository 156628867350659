import React from "react";
import {Link} from "react-router-dom";
import Piq, {SIZE_TO_PIXELS, Spacer, LoadedPiq} from "./Piq";
import styled, {css} from "react-emotion";
import Ui from "./ui/index";
import colors from "../lib/colors";

const shadowStyle = ({withShadow}) => ({
  backgroundColor: colors.white,
  boxShadow: withShadow ? "0 3px rgba(100,100,100,0.7), 0 3px 5px -1px rgba(0,0,0,0.4)" : undefined,
});
const StyledPiq = styled(Piq)(shadowStyle);
const StyledLoadedPiq = styled(LoadedPiq)(shadowStyle);

const getUserName = user => (user && user.name) || "anonymous";

const Avatar = ({user, size = "small", width, css, className, ...rest}) =>
  user && user.profilePicture ? (
    <StyledPiq
      picture={user.profilePicture}
      alt={`profile piq of ${getUserName(user)}`}
      title={getUserName(user)}
      size={size}
      css={css}
      className={className}
      {...rest}
    />
  ) : (
    <Spacer width={width || SIZE_TO_PIXELS[size]} className={className} css={css}>
      <StyledLoadedPiq
        title={getUserName(user)}
        alt={`anonymous profile piq of ${getUserName(user)}`}
        src={require("../img/anon.png")}
        {...rest}
      />
    </Spacer>
  );

export const AvatarWithName = ({user, size, withShadow, gap = 5, className, ...rest}) => (
  <Ui.Row className={css({alignItems: "center"}, className)} {...rest}>
    <Avatar user={user} size={size} withShadow={withShadow} />
    <div css={{marginLeft: gap}}>{getUserName(user)}</div>
  </Ui.Row>
);

const StyledLink = styled(Link)({display: "block", flex: "none"});

export const userLink = user => (user ? `/u/${user.name}` : null);

export const AvatarLink = ({user, size, withShadow, withName, ...rest}) =>
  user ? (
    <StyledLink to={userLink(user)} {...rest}>
      {React.createElement(withName ? AvatarWithName : Avatar, {user, size, withShadow})}
    </StyledLink>
  ) : (
    React.createElement(withName ? AvatarWithName : Avatar, {user, size, withShadow, ...rest})
  );

export default Avatar;
