import React from "react";

const Heart = ({inactive, height}) => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={(height * 7) / 6}
    height={height}
    viewBox="0 0 7 6"
  >
    <g fill="none" fillRule="evenodd">
      <path fill={inactive ? "#ccc" : "red"} d="M4 4v1H3V4H1V1h5v3H4z" />
      <path
        fill="#000"
        d="M1 0h2v1H1V0zm3 0h2v1H4V0zm2 1h1v2H6V1zM0 1h1v2H0V1zm1 2h1v1H1V3zm2-2h1v1H3V1zm2 2h1v1H5V3zM4 4h1v1H4V4zM2 4h1v1H2V4zm1 1h1v1H3V5z"
      />
    </g>
  </svg>
);

export default Heart;
