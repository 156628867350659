import React from "react";
import styled from "react-emotion";

const SVGContainer = styled("div")(
  {
    fontSize: 1,
  },
  ({width, height}) => ({
    width,
    height,
    "& svg": {width, height, fill: "currentColor"},
  })
);

const SVG = ({name, width = 24, height = 24, style, css}) => (
  <SVGContainer
    width={width}
    height={height}
    style={style}
    css={css}
    dangerouslySetInnerHTML={{__html: require(`!!svg-inline-loader!../icons/${name}.svg`)}}
  />
);

export default SVG;
