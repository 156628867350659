import React from "react";
import styled from "react-emotion";
import colors from "../lib/colors";
import {apiErrorEvent, api} from "../lib/api";
import Ui from "./ui";
import messenger from "../lib/messenger";
import Network from "react-network";

const Pill = styled("div")({
  position: "fixed",
  bottom: 10,
  left: "50%",
  width: 250,
  background: colors.danger,
  zIndex: 25,
  fontSize: 14,
  lineHeight: 1.3,
  marginLeft: -250 / 2,
  textAlign: "center",
  padding: "10px 20px",
  color: colors.white,
  boxShadow: "0 1px 15px rgba(0,0,0,0.1)",
});

export default class ApiErrorAlert extends React.Component {
  state = {
    showConnectionError: false,
  };

  componentDidMount() {
    this.unsubscribeApiError = apiErrorEvent.addListener(this.handleApiError);
  }

  componentWillUnmount() {
    this.unsubscribeApiError();
  }

  handleApiError = e => {
    if (e.ok) {
      if (this.state.showConnectionError) this.setState({showConnectionError: false});
    } else {
      switch (e.status) {
        case 401:
          api.cache.clear(() => {
            messenger.send("You need to be logged in", {type: "warning"});
          });
          break;
        case 403:
          api.cache.clear(() => {
            messenger.send("You are not authorized", {type: "danger"});
          });
          break;
        case 404:
          messenger.send("Ressource not found", {type: "danger"});
          break;
        case 500:
          this.setState({
            error: "The server is currently unresponsive. Please try again in a bit.",
          });
          break;
        default:
          this.setState({
            error: "Can't connect to server. Either you or the server is offline :(",
          });
      }
    }
  };

  handleRetry = () => {
    this.setState({error: false});
    api.cache.clear();
  };

  render() {
    return (
      <Network
        render={({online}) =>
          !online ? (
            <Pill>You are offline</Pill>
          ) : this.state.error ? (
            <Pill>
              <div>{this.state.error}</div>
              <Ui.MiniActionButton css={{marginTop: 10}} onClick={this.handleRetry}>
                Retry
              </Ui.MiniActionButton>
            </Pill>
          ) : null
        }
      />
    );
  }
}
