import {format} from "date-fns";

export function toDateString(d) {
  return format(d, "YYYY-MM-DD");
}

export function dateStringToDate(str) {
  const [year, month, day] = str.split("-").map(n => parseInt(n, 10));
  return new Date(year, month - 1, day);
}

export function shallowEqualArray(listA, listB) {
  if (listA.length !== listB.length) return false;
  for (let i = 0; i < listA.length; i += 1) {
    if (listA[i] !== listB[i]) return false;
  }
  return true;
}

export function createSimpleMemoizer(fn) {
  let lastArgs = null;
  let lastResult = null;
  return (...args) => {
    if (lastArgs && shallowEqualArray(lastArgs, args)) return lastResult;
    lastArgs = args;
    lastResult = fn(...args);
    return lastResult;
  };
}

export function createRefMemoizer() {
  return createSimpleMemoizer((...refFns) => n => refFns.forEach(refFn => refFn(n)));
}

export function isBot() {
  if (typeof navigator === "undefined") return true;
  const re = /(bot|googlebot|crawler|spider|robot|crawling)/i;
  return re.test(navigator.userAgent);
}

export function isMobile() {
  return navigator.userAgent.includes("Mobi") || typeof window.orientation !== "undefined";
}
