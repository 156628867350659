import React from "react";

export function createGetRootComponent(api) {
  return class GetRoot extends React.Component {
    componentDidMount() {
      if (this.props.sub) {
        this.unsubscribe = api.registerOnChangeListener(() => this.forceUpdate());
      }
    }

    componentWillUnmount() {
      if (this.unsubscribe) this.unsubscribe();
    }

    render() {
      return this.props.children(api.getRoot());
    }
  };
}

export function createIsReadyComponent(api) {
  return class IsReady extends React.Component {
    state = {ready: api.isCurrentlyLoading() === true ? false : "uncertain"};

    componentWillMount() {
      this.hadToLoad = false;
      this.lastApiChangeIndex = api.getChangeIndex();
      this.unsub = api.registerOnLoadListener(() => {
        this.hadToLoad = true;
        this.lastApiChangeIndex = api.getChangeIndex();
      });
    }

    componentDidMount() {
      this.check();
    }

    componentWillUpdate() {
      this.hadToLoad = false;
    }

    componentDidUpdate() {
      this.check();
    }

    check() {
      if (this.hadToLoad) {
        if (this.state.ready !== false) this.setState({ready: false});
      } else if (
        this.state.ready === "uncertain" ||
        api.getChangeIndex() > this.lastApiChangeIndex
      ) {
        if (this.state.ready !== true) this.setState({ready: true});
        this.unsub();
      }
    }

    render() {
      return this.props.children(this.state.ready);
    }
  };
}
