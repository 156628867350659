import React from "react";
import {api} from "../lib/api";

export default class DoAction extends React.Component {
  state = {
    isLoading: false,
    error: null,
  };

  componentDidMount() {
    if (this.props.callOnMount) this.handlePerformFn();
  }

  componentWillUnmount() {
    this.unmounted = true;
  }

  handlePerformFn = () => {
    const {name, data, onSuccess, onError} = this.props;
    this.setState({isLoading: true});
    const [namespace, actionName] = name.split(".");
    return api.mutate[namespace][actionName](data || {}).then(
      e => {
        if (this.unmounted) return;
        this.setState({isLoading: false});
        if (onSuccess) onSuccess(e);
      },
      e => {
        if (this.unmounted) return Promise.reject(e);
        if (onError) {
          this.setState({isLoading: false});
          return onError(e);
        } else {
          this.setState({isLoading: false, error: e});
          console.error(e);
          return Promise.reject(e);
        }
      }
    );
  };

  render() {
    const {isLoading, error} = this.state;
    return this.props.children({performFn: this.handlePerformFn, isLoading, error});
  }
}
