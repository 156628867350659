import styled from "react-emotion";
import colors from "../../lib/colors";

export const rawStyle = {
  padding: "20px 50px",
  margin: "50px auto",
  position: "relative",
  "@media(max-width: 800px)": {
    paddingLeft: 10,
    paddingRight: 10,
  },
};
export const whiteStyle = {
  backgroundColor: colors.white,
  color: colors.brandDarkest,
  boxShadow: "0 3px rgba(200,200,200,0.7), 0 3px 10px rgba(0,0,0,0.5)",
};

export const Simple = styled("div")(rawStyle, whiteStyle, {
  width: "100%",
  maxWidth: 800,
});

export const Flex = styled("div")(rawStyle, whiteStyle);

export const darkStyle = {
  backgroundColor: colors.dark,
  color: colors.white,
  boxShadow: "0 3px rgba(30,30,30,0.7), 0 3px 10px rgba(0,0,0,0.5)",
};

export const SimpleDark = styled("div")(rawStyle, darkStyle, {
  maxWidth: 800,
});

export const SimpleWithDarkEnd = styled("div")(rawStyle, whiteStyle, {
  width: "100%",
  maxWidth: 800,
  padding: 0,
  boxShadow: "0 3px rgba(30,30,30,0.7), 0 3px 10px rgba(0,0,0,0.5)",
});
