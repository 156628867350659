import React from "react";
import {ArrowOverlay, SpawnAnchoredOverlay} from "./Overlay";
import Toggler from "./Toggler";
import colors from "../lib/colors";

const Tooltip = ({tooltip, children, tooltipCss, maxWidth = 250}) => (
  <Toggler>
    {(isOpen, toggle) => (
      <SpawnAnchoredOverlay
        isOpen={isOpen && tooltip}
        distanceFromAnchor={8}
        renderOverlay={p => (
          <ArrowOverlay
            arrowSize={8}
            background={colors.dark}
            color={colors.white}
            contentCss={{
              padding: "10px 20px",
              fontSize: 14,
              lineHeight: 1.4,
              maxWidth,
              ...tooltipCss,
            }}
            {...p}
          >
            {typeof tooltip === "function" ? tooltip() : tooltip}
          </ArrowOverlay>
        )}
      >
        {measureRef => {
          const props = {onMouseEnter: toggle.on, onMouseLeave: toggle.off};
          return typeof children === "function" ? (
            children(props, measureRef)
          ) : (
            <div {...props} ref={measureRef}>
              {children}
            </div>
          );
        }}
      </SpawnAnchoredOverlay>
    )}
  </Toggler>
);

export default Tooltip;
