import React from "react";
import Spinner from "./Spinner";
import IfVisible from "./IfVisible";
import styled from "react-emotion";
import {FramedPiq} from "./Piq";
import Ui from "./ui";

const filterToKey = filter =>
  filter
    ? Object.entries(filter)
        .map(([k, v]) => `${k}:${v}`)
        .join("|")
    : "";

const Pager = ({
  collection,
  desc,
  orderProps: rawOrderProps,
  count,
  renderIfEmpty,
  className,
  style,
  children,
  containerCss,
  renderGroup,
  renderIfLoading,
  loadNextComp,
  passFullList,
  onNewItem,
  outerRef,
  reverse,
}) => {
  const PageComp = desc ? ManageHighestFirstPages : ManageLowestFirstPages;
  const key = `${collection.parent.id}_${collection.relName}${filterToKey(collection.filter)}`;
  const orderProps = Array.isArray(rawOrderProps) ? rawOrderProps : [rawOrderProps];
  return (
    <NewestValOnMount
      key={key}
      collection={collection}
      desc={desc}
      orderProps={orderProps}
      renderIfEmpty={renderIfEmpty}
    >
      {newestVals => (
        <PageComp
          count={count}
          newestVals={newestVals}
          collection={collection}
          orderProps={orderProps}
          onNewItem={onNewItem}
          reverse={reverse}
          key={key}
        >
          {({pages, onLoadMore, isLoadingNextPage}) => (
            <DisplayPages
              className={className}
              style={style}
              outerRef={outerRef}
              children={children}
              containerCss={containerCss}
              pages={pages}
              renderGroup={renderGroup}
              onLoadMore={onLoadMore}
              isLoadingNextPage={isLoadingNextPage}
              renderIfLoading={renderIfLoading}
              loadNextComp={loadNextComp}
              passFullList={passFullList}
              reverse={reverse}
            />
          )}
        </PageComp>
      )}
    </NewestValOnMount>
  );
};

Pager.defaultProps = {
  orderProps: ["createdAt"],
  desc: false,
  count: 50,
  newItemsCount: 5,
  loadNextComp: ({isLoadingPage, loadNext}) =>
    isLoadingPage ? null : <IfVisible onVisible={loadNext} />,
};

const NEW_TO_PAGE_COUNT = 10;

const extractOrderVals = (model, orderProps) => {
  let isMissing = false;
  const res = orderProps.map(p => {
    const val = model.$meta.get(p, null);
    if (val === null) isMissing = true;
    return [p, val];
  });
  return isMissing ? null : res;
};

class NewestValOnMount extends React.Component {
  constructor(props) {
    super(props);
    const {newestVals, newestEl} = this.getNewestElAndVal(props);
    this.state = {newestVals: newestEl && !newestEl.$meta.isLoaded ? null : newestVals};
  }

  getNewestElAndVal(props) {
    const {
      collection: {parent, relName, filter},
      desc,
      orderProps,
    } = props;
    const newestEl = parent.$meta[desc ? "first" : "last"](relName, filter);
    if (!newestEl) {
      return {newestEl: null, newestVals: null};
    } else {
      return {newestEl, newestVals: extractOrderVals(newestEl, orderProps)};
    }
  }

  componentWillReceiveProps(nextProps) {
    if (this.state.newestVals) return;
    const {newestEl, newestVals} = this.getNewestElAndVal(nextProps);
    if (!newestEl) {
      return this.setState({newestVals: null});
    } else {
      if (newestVals !== null) this.setState({newestVals});
    }
  }

  render() {
    const {renderIfEmpty, children} = this.props;
    const {newestVals} = this.state;
    if (newestVals === null) return (renderIfEmpty && renderIfEmpty()) || null;
    return newestVals !== null ? children(newestVals) : <Spinner />;
  }
}

class Page {
  constructor(count, relName, filter, reverse = false) {
    this.count = count;
    this.relName = relName;
    this.filter = filter;
    this.reverse = reverse;
  }

  query(parent) {
    if (this.reverse) {
      const res = parent.$meta.lastN(this.count, this.relName, this.filter);
      res.reverse();
      return res;
    } else {
      return parent.$meta.firstN(this.count, this.relName, this.filter);
    }
  }
}

const fixConstraint = constr => {
  if (constr instanceof Date) {
    return new Date(constr.getTime() + 1).toISOString(); // because psql uses more precise timing and 0.834 might be represented as 0.834361
  } else {
    return constr;
  }
};

const cmp = op => fieldAndVals => {
  if (fieldAndVals.length === 1) {
    return {[`${fieldAndVals[0][0]}$${op}`]: fixConstraint(fieldAndVals[0][1])};
  } else {
    const [k1, v1] = fieldAndVals[0];
    const [k2, v2] = fieldAndVals[1];
    const leftPart = `${k1}:${fixConstraint(v1)},${k2}$${op}:${fixConstraint(v2)}`;
    const rightPart = `${k1}$${op}:${fixConstraint(v1)}`;
    return {$or: `[${leftPart}],[${rightPart}]`};
  }
};

const greaterThan = cmp("gt");
const lessThan = cmp("lt");
const lessEqualThan = cmp("lte");

const ensureOrderVals = (model, orderProps) => {
  orderProps.forEach(p => model.$meta.get(p));
};

class ManageLowestFirstPages extends React.Component {
  constructor(props) {
    super(props);
    const {
      newestVals,
      collection: {relName, filter},
      count,
    } = props;

    this.state = {
      newestConstr: greaterThan(newestVals),
      pages: [new Page(count, relName, {...filter, ...lessEqualThan(newestVals)})],
    };
  }

  componentWillReceiveProps(nextProps) {
    if (this._newPageForNewestVals) {
      const {
        collection: {relName, filter},
      } = nextProps;
      const {pages, newestConstr} = this.state;
      pages.push(new Page(NEW_TO_PAGE_COUNT, relName, {...filter, ...newestConstr}));

      this.setState({
        pages,
        newestConstr: greaterThan(this._newPageForNewestVals),
      });
      this._newPageForNewestVals = false;
      this._hasReachedLastPage = true;
    }
  }

  handleLoadMore = () => {
    const {pages} = this.state;
    const {
      newestVals,
      orderProps,
      collection: {parent, relName, filter},
      count,
    } = this.props;
    const lastPageContent = pages[pages.length - 1].query(parent);
    const orderVals = extractOrderVals(lastPageContent[lastPageContent.length - 1], orderProps);
    pages.push(
      new Page(count, relName, {
        ...filter,
        ...lessEqualThan(newestVals),
        ...greaterThan(orderVals),
      })
    );
    this.setState({pages});
  };

  getNewest() {
    const {
      collection: {parent, relName, filter},
      orderProps,
    } = this.props;
    const res = parent.$meta.firstN(NEW_TO_PAGE_COUNT, relName, {
      ...filter,
      ...this.state.newestConstr,
    });
    if (res.length > 0) ensureOrderVals(res[0], orderProps);
    if (res.length === NEW_TO_PAGE_COUNT) {
      const latestVals = extractOrderVals(res[res.length - 1], orderProps);
      // TODO: orderProps
      if (latestVals) this._newPageForNewestVals = latestVals;
    }
    return res;
  }

  render() {
    const {pages} = this.state;
    const {
      orderProps,
      children,
      collection: {parent},
    } = this.props;
    const lastPage = pages[pages.length - 1];
    const lastPageContent = lastPage.query(parent);
    const isLoadingNextPage = lastPageContent.length > 0 && !lastPageContent[0].$meta.isLoaded;
    if (isLoadingNextPage) ensureOrderVals(lastPageContent[0], orderProps);
    const reachedLastPage =
      this._hasReachedLastPage || (!isLoadingNextPage && lastPage.count !== lastPageContent.length);
    const queries = [
      ...pages.slice(0, pages.length - 1).map(p => p.query(parent)),
      lastPageContent,
    ];
    if (reachedLastPage) queries.push(this.getNewest());

    return children({
      pages: queries,
      onLoadMore: !reachedLastPage && this.handleLoadMore,
      isLoadingNextPage,
    });
  }
}

class ManageHighestFirstPages extends React.Component {
  _newestCount = 0;
  _hasLoadedFirst = false;

  constructor(props) {
    super(props);
    const {
      newestVals,
      collection: {relName, filter},
      count,
    } = props;

    this.state = {
      newestConstr: greaterThan(newestVals),
      pages: [new Page(count, relName, {...filter, ...lessEqualThan(newestVals)})],
    };
  }

  componentDidUpdate() {
    if (this._newPageForNewestVals) {
      const {
        collection: {relName, filter},
        reverse,
      } = this.props;
      const {pages, newestConstr} = this.state;
      pages[reverse ? "push" : "unshift"](
        new Page(NEW_TO_PAGE_COUNT, relName, {...filter, ...newestConstr})
      );

      this.setState({
        pages,
        newestConstr: greaterThan(this._newPageForNewestVals),
      });
      this._newPageForNewestVals = false;
    }
  }

  handleNotifyAddNew() {
    if (this.props.onNewItem) setTimeout(this.props.onNewItem);
  }

  handleLoadMore = () => {
    const {pages} = this.state;
    const {
      orderProps,
      reverse,
      collection: {parent, relName, filter},
      count,
    } = this.props;
    const lastPageContent = pages[reverse ? 0 : pages.length - 1].query(parent);
    const lastPageOldestVals = extractOrderVals(
      lastPageContent[lastPageContent.length - 1],
      orderProps
    );
    if (lastPageOldestVals) {
      pages[reverse ? "unshift" : "push"](
        new Page(count, relName, {
          ...filter,
          ...lessThan(lastPageOldestVals),
        })
      );
    }
    this.setState({pages});
  };

  getNewest() {
    const {
      collection: {parent, relName, filter},
      reverse,
      orderProps,
    } = this.props;
    const res = parent.$meta.lastN(NEW_TO_PAGE_COUNT, relName, {
      ...filter,
      ...this.state.newestConstr,
    });
    if (!reverse) res.reverse();
    if (res.length > 0) ensureOrderVals(res[0], orderProps);
    if (res.length === NEW_TO_PAGE_COUNT) {
      const latest = extractOrderVals(res[reverse ? res.length - 1 : 0], orderProps);
      if (latest) this._newPageForNewestVals = latest;
    }
    return res;
  }

  render() {
    const {
      children,
      collection: {parent},
      orderProps,
      reverse,
    } = this.props;
    const {pages} = this.state;

    const lastPage = pages[reverse ? 0 : pages.length - 1];
    const lastPageContent = lastPage.query(parent);
    const isLoadingNextPage = lastPageContent.length > 0 && !lastPageContent[0].$meta.isLoaded;
    if (!this._hasLoadedFirst && !isLoadingNextPage && pages.length === 1) {
      this.handleNotifyAddNew();
      this._hasLoadedFirst = true;
    }
    if (isLoadingNextPage) ensureOrderVals(lastPageContent[0], orderProps);
    const reachedLastPage =
      this._hasReachedLastPage || (!isLoadingNextPage && lastPage.count !== lastPageContent.length);

    const newest = isLoadingNextPage && pages.length === 1 ? [] : [this.getNewest()];
    if (
      newest.length &&
      (newest[0].length === 0 || newest[0][0].$meta.isLoaded) &&
      this._newestCount !== newest[0].length
    ) {
      this.handleNotifyAddNew();
      this._newestCount = newest.length;
    }

    const queries = reverse
      ? [
          [...lastPageContent].reverse(),
          ...pages.slice(1).map(p => [...p.query(parent)].reverse()),
          ...newest,
        ]
      : [...newest, ...pages.slice(0, pages.length - 1).map(p => p.query(parent)), lastPageContent];

    return children({
      pages: queries,
      onLoadMore: !reachedLastPage && this.handleLoadMore,
      isLoadingNextPage,
    });
  }
}

const flattenPages = pages =>
  pages.reduce((m, p) => {
    m.push(...p);
    return m;
  }, []);

class DisplayPages extends React.Component {
  getElements(pages) {
    const {renderGroup, children} = this.props;
    return renderGroup
      ? pages.map(renderGroup)
      : pages.map((p, i) => <React.Fragment key={i}>{p.map(e => children(e))}</React.Fragment>);
  }

  render() {
    const {
      className,
      style,
      children,
      containerCss,
      pages,
      onLoadMore,
      isLoadingNextPage,
      renderIfLoading,
      loadNextComp,
      passFullList,
      outerRef,
      reverse,
    } = this.props;

    const content = passFullList ? (
      children(flattenPages(pages))
    ) : (
      <div css={containerCss}>{this.getElements(pages)}</div>
    );
    const loadMore =
      onLoadMore && loadNextComp({isLoadingPage: isLoadingNextPage, loadNext: onLoadMore});
    const loading = isLoadingNextPage && renderIfLoading && renderIfLoading();

    return reverse ? (
      <div className={className} style={style} ref={outerRef}>
        {loading}
        {loadMore}
        {content}
      </div>
    ) : (
      <div className={className} style={style} ref={outerRef}>
        {content}
        {loadMore}
        {loading}
      </div>
    );
  }
}

export default Pager;

export const GalleryPiq = styled(FramedPiq)({margin: "0 5px 10px"});
export const galleryStyle = {
  display: "flex",
  flexWrap: "wrap",
  margin: "0 -5px -10px",
};

const LoadMoreButtonContainer = styled("div")({
  display: "flex",
  justifyContent: "center",
});

export const loadMoreButton = css => ({isLoadingPage, loadNext}) => (
  <LoadMoreButtonContainer css={css}>
    <Ui.MiniActionButton disabled={isLoadingPage} onClick={loadNext} onDark>
      {isLoadingPage && <Spinner size={12} />}
      load more
    </Ui.MiniActionButton>
  </LoadMoreButtonContainer>
);

export const GalleryPager = ({getPiq, withLoadMoreButton, ...rest}) => (
  <Pager
    containerCss={galleryStyle}
    renderIfEmpty={() => <div>No pictures</div>}
    {...withLoadMoreButton && {loadNextComp: loadMoreButton(withLoadMoreButton)}}
    {...rest}
  >
    {child => {
      const p = getPiq ? getPiq(child) : child;
      return <GalleryPiq picture={p} key={p && p.id} />;
    }}
  </Pager>
);
