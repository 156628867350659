import "./lib/polyfills";
import "./lib/error-logger";

import ReactDOM from "react-dom";
import App from "./App";

import "typeface-lato";
import "typeface-nunito";
import "./index.css";
import startTrackers from "./lib/trackers";
import {accessPwStorage} from "./lib/storage";

startTrackers();

if (process.env.REACT_APP_ACCESS_HASH) {
  const jsSha256 = require("js-sha256");
  const lsPw = accessPwStorage.get();
  if (!lsPw || jsSha256(lsPw) !== process.env.REACT_APP_ACCESS_HASH) {
    let word;
    do {
      word = prompt(`${word ? "Nope! " : ""}What's the magic word?`, "");
    } while (jsSha256(word || "") !== process.env.REACT_APP_ACCESS_HASH);
    accessPwStorage.set(word);
  }
}

window.document.addEventListener("DOMContentLoaded", () => {
  const appEl = window.document.getElementById("root");

  ReactDOM.render(App, appEl);
});
