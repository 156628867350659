import React from "react";
import createHistory from "history/createBrowserHistory";
import {Redirect, Router, Link, matchPath} from "react-router-dom";
import RouterInfo from "./RouterInfo";
import MiniEvent from "../lib/mini-event";
import {trackPageView} from "../lib/trackers";

const wrappedHistoryEvents = new MiniEvent();

export const stripSpecialStateInfo = loc => {
  const {$visibleLocation, $passToRouterLocation, ...restState} = loc.state || {};
  return {...loc, state: restState};
};

const wrapHistory = realHistory => {
  const proxyAttrs = ["length", "action"];
  const proxyMethods = ["push", "replace", "go", "goBack", "goForward", "block", "createHref"];

  // `wrappedHistory.location` is the object that react-routes and RouteInfo will work with
  const wrappedHistory = {};

  proxyMethods.forEach(m => {
    wrappedHistory[m] = (...args) => realHistory[m](...args);
  });

  const modifyHistoryObj = () => {
    proxyAttrs.forEach(attr => (wrappedHistory[attr] = realHistory[attr]));
    const {$passToRouterLocation} = realHistory.location.state || {};
    if ($passToRouterLocation) {
      wrappedHistory.location = {
        ...$passToRouterLocation,
        state: {
          ...$passToRouterLocation.state,
          $visibleLocation: stripSpecialStateInfo(realHistory.location),
        },
      };
    } else {
      wrappedHistory.location = realHistory.location;
    }
  };
  wrappedHistory.listen = cb => wrappedHistoryEvents.addListener(cb);
  realHistory.listen((location, type) => {
    trackPageView(`${location.pathname}${location.search}`, type === "REPLACE");
    modifyHistoryObj();
    wrappedHistoryEvents.emit();
  });
  modifyHistoryObj();
  return wrappedHistory;
};

/**
 * The public API for a <Router> that uses HTML5 history.
 */
class PiqRouter extends React.Component {
  history = wrapHistory(createHistory(this.props));

  render() {
    return <Router history={this.history} children={this.props.children} />;
  }
}

// the url will change, we won't react-router know about this change though.
export const OpaqueLink = ({pathname, ...rest}) => (
  <RouterInfo>
    {({location}) => (
      <Link
        to={{
          pathname,
          state: {
            $passToRouterLocation: location,
          },
        }}
        {...rest}
      />
    )}
  </RouterInfo>
);

// if we're tricking ReactRouter already, we continue tricking them, if not then not.
export const WithinSameLayerLink = ({to, ...rest}) => (
  <RouterInfo>
    {({location}) => (
      <Link
        to={{
          ...(typeof to === "string" ? {pathname: to} : to),
          state: location.state &&
            location.state.$visibleLocation && {
              $passToRouterLocation: location,
            },
        }}
        {...rest}
      />
    )}
  </RouterInfo>
);

// if we're tricking ReactRouter already, we continue tricking them, if not then not.
export const WithinSameLayerRedirect = ({to, ...rest}) => (
  <RouterInfo>
    {({location}) => (
      <Redirect
        to={{
          ...(typeof to === "string" ? {pathname: to} : to),
          state: location.state &&
            location.state.$visibleLocation && {
              $passToRouterLocation: location,
            },
        }}
        {...rest}
      />
    )}
  </RouterInfo>
);

// Always match the visible URL (no matter if created by OpaqueLink or real Link)
export const VisibleUrlMatch = ({path, exact = false, children}) => (
  <RouterInfo>
    {({location}) =>
      children(
        matchPath(((location.state && location.state.$visibleLocation) || location).pathname, {
          path,
          exact,
        })
      )
    }
  </RouterInfo>
);

export default PiqRouter;
