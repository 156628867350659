import React from "react";
import styled from "react-emotion";
import {Link} from "react-router-dom";
import Ui from "./ui";
import colors from "../lib/colors";
import Pager from "./Pager";
import Tooltip from "./Tooltip";
import FromNow from "./FromNow";
import {api} from "../lib/api";
import {linkToContest} from "../lib/contest-utils";
import DoAction from "./DoAction";
import {threadLink} from "../pages/Forum/utils";
import {PiqFromId, TinyPiq} from "./Piq";
import {userLink} from "./Avatar";

const typeToKey = {
  new_comment: d => `${d.targetType}_${d.targetId}`,
  piq_collab: d => d.baseId,
  new_piq: d => d.userId,
  new_follower: () => null,
  piq_of_day: () => null,
  competition_end: () => null,
  piq_like: d => `${d.piqId}`,
  new_post: d => `${d.threadId}`,
  new_achievement: d => d.achievementId,
  null: () => null,
};

const Comment = ({targetType, targetId, user}) => {
  switch (targetType) {
    case "news":
      return (
        <span>
          news: <i>{api.getModel({modelName: "newsEntry", id: targetId}).title}</i>
        </span>
      );
    case "contest":
      return (
        <span>
          contest <b>{api.getModel({modelName: "contest", id: targetId}).keyword}</b>
        </span>
      );
    case "picture":
      return <PiqFromId id={targetId} />;
    case "user":
      const target = api.getModel({modelName: "user", id: targetId});
      return targetId === user.id
        ? "your shoutbox"
        : `${target ? target.name : "someone"}'s shoutbox`;
    default:
      return null;
  }
};

const ThreadTitle = ({threadId}) => (
  <b>{api.getModel({modelName: "thread", id: threadId}).title}</b>
);

const typeInfo = {
  new_post: {
    to: ({threadId, boardId}) =>
      threadLink(
        {
          thread: api.getModel({modelName: "thread", id: threadId}),
          board: api.getModel({modelName: "board", id: boardId}),
        },
        true
      ),
    Single: ({data: {threadId, postId}}) => (
      <span>
        New post in <ThreadTitle threadId={threadId} /> by{" "}
        <b>{api.getModel({modelName: "post", id: postId}).author.name}</b>
      </span>
    ),
    Multi: ({data: {threadId}, count}) => (
      <span>
        <b>{count}</b> new posts in <ThreadTitle threadId={threadId} />
      </span>
    ),
  },
  new_comment: {
    to: ({targetType, targetId}) => {
      if (targetType === "news") return `/news/${targetId}`;
      if (targetType === "contest")
        return linkToContest(api.getModel({modelName: "contest", id: targetId}));
      if (targetType === "picture") return `/picture/${targetId}`;
      if (targetType === "user") return userLink(api.getModel({modelName: "user", id: targetId}));
      return "/";
    },
    Single: ({data: {targetType, targetId, commentId}, user}) => (
      <span>
        New comment on <Comment targetType={targetType} targetId={targetId} user={user} /> by{" "}
        <b>{api.getModel({modelName: "comment", id: commentId}).author.name}</b>
      </span>
    ),
    Multi: ({data: {targetType, targetId}, count, user}) => (
      <span>
        <b>{count}</b> new comments on{" "}
        <Comment targetType={targetType} targetId={targetId} user={user} />
      </span>
    ),
  },
  piq_collab: {
    to: ({baseId, piqId}, count) => `/picture/${count > 1 ? baseId : piqId}`,
    Single: ({data: {baseId, piqId}}) => {
      const owner = api.getModel({modelName: "picture", id: piqId}).owner;
      return (
        <span>
          Your piq <PiqFromId id={baseId} /> was branched by <b>{owner ? owner.name : "someone"}</b>
        </span>
      );
    },
    Multi: ({data: {baseId}, count}) => (
      <span>
        Your piq <PiqFromId id={baseId} /> was branched <b>{count}</b> times!
      </span>
    ),
  },
  new_piq: {
    to: ({piqId, userId}, count) =>
      count === 1
        ? `/picture/${piqId}`
        : `/u/${api.getModel({modelName: "user", id: userId}).name}`,
    Single: ({data: {userId, piqId}}) => (
      <span>
        <b>{api.getModel({modelName: "user", id: userId}).name}</b> has drawn
        <PiqFromId id={piqId} />
      </span>
    ),
    Multi: ({data: {userId}, count}) => (
      <span>
        <b>{api.getModel({modelName: "user", id: userId}).name}</b> has drawn <b>{count}</b> new
        piqs
      </span>
    ),
  },
  new_follower: {
    to: ({followerId}, count, me) =>
      count === 1
        ? `/u/${api.getModel({modelName: "user", id: followerId}).name}`
        : `/u/${api.getModel({modelName: "user", id: me.id}).name}`,
    Single: ({data: {followerId}}) => (
      <span>
        <b>{api.getModel({modelName: "user", id: followerId}).name}</b> followed you
      </span>
    ),
    Multi: ({count}) => <span>You've got {count} new followers</span>,
  },
  piq_of_day: {
    to: ({piqId}, count) => (count === 1 ? `/picture/${piqId}` : "/"),
    Single: ({data: {piqId}}) => (
      <span>
        <PiqFromId id={piqId} /> became piq of the day!
      </span>
    ),
    Multi: ({count}) => <span>You received {count} piq of the days!</span>,
  },
  competition_end: {
    to: ({contestPictureId}) =>
      linkToContest(api.getModel({modelName: "contestPicture", id: contestPictureId}).contest),
    Single: ({data: {contestPictureId, rank}}) => (
      <span>
        Your piq{" "}
        <TinyPiq
          picture={api.getModel({modelName: "contestPicture", id: contestPictureId}).picture}
        />{" "}
        got place <b>{rank}</b> in the contest
      </span>
    ),
    Multi: ({count}) => (
      <span>
        You've participated in <b>{count}</b> contests
      </span>
    ),
  },
  piq_like: {
    to: ({piqId}) => `/picture/${piqId}`,
    Single: ({data: {userId, piqId}}) => (
      <span>
        <b>{api.getModel({modelName: "user", id: userId}).name}</b> liked <PiqFromId id={piqId} />
      </span>
    ),
    Multi: ({data: {piqId}, count}) => (
      <span>
        <b>{count}</b> people liked <PiqFromId id={piqId} />
      </span>
    ),
  },
  new_achievement: {
    to: (data, count, me) => `/u/${api.getModel({modelName: "user", id: me.id}).name}`,
    Single: ({data: {achievementId}}) => {
      const a = api.getModel({modelName: "achievement", id: achievementId});
      return (
        <span>
          You received a <b>{a && a.name}</b> achievement!
        </span>
      );
    },
    Multi: ({count}) => <span>You received {count} new achievements!</span>,
  },
};

const NotiRow = styled(Ui.Row, {shouldForwardProp: p => p !== "isSeen"})(({isSeen}) => ({
  alignItems: "center",
  ...(!isSeen && {
    boxShadow: `inset 5px 0 ${colors.danger}`,
  }),
}));

const LeftPart = styled(Link)({
  padding: "5px 15px",
  fontSize: 12,
  lineHeight: 1.4,
  color: colors.fade(colors.white, 0.8),
  paddingRight: 10,
  flex: "auto",
  display: "block",
  transitionProperty: "background-color",
  ":hover": {
    backgroundColor: colors.fade(colors.white, 0.1),
  },
  "& b": {
    color: colors.white,
  },
});

const DatePart = styled("div")({
  color: colors.fade(colors.white, 0.4),
  fontSize: 10,
});

class NotificationOverlay extends React.PureComponent {
  onSeen = ids => {
    return api.mutate.notifications.seen({ids, isSeen: true});
  };

  determineGroups(ns) {
    const groups = [];
    const groupByKey = {};
    ns.forEach(n => {
      const key = [n.isSeen ? "s" : "n", n.type, typeToKey[n.$meta.get("type", null)](n.data)].join(
        "-"
      );
      if (!n.$meta.get("type")) return;
      if (!groupByKey[key]) {
        const group = {
          key,
          date: n.createdAt,
          count: 1,
          type: n.type,
          isSeen: n.isSeen,
          data: n.data,
          ids: [n.id],
        };
        groupByKey[key] = group;
        groups.push(group);
      } else {
        groupByKey[key].count += 1;
        groupByKey[key].ids.push(n.id);
      }
    });
    return groups;
  }

  renderGroup = ns => {
    const {user} = this.props;
    if (ns.length === 0) return null;
    return (
      <div key={ns[0].id}>
        {this.determineGroups(ns).map(({key, date, count, type, isSeen, data, ids}) => {
          const info = typeInfo[type];
          return (
            <NotiRow isSeen={isSeen} key={key}>
              <LeftPart
                to={info.to(data, count, user)}
                onClick={isSeen ? null : () => this.onSeen(ids)}
              >
                {count === 1 ? (
                  <info.Single data={data} user={user} />
                ) : (
                  <info.Multi data={data} count={count} user={user} />
                )}
                <DatePart>
                  <FromNow date={date} />
                </DatePart>
              </LeftPart>
              {!isSeen && (
                <Tooltip tooltip="Mark as seen">
                  {(props, ref) => (
                    <Ui.PlainButton
                      innerRef={ref}
                      {...props}
                      css={{margin: 5}}
                      onClick={() => this.onSeen(ids)}
                    >
                      <Ui.Icon.Eye css={{fontSize: 14, color: colors.fade(colors.white, 0.5)}} />
                    </Ui.PlainButton>
                  )}
                </Tooltip>
              )}
            </NotiRow>
          );
        })}
      </div>
    );
  };

  render() {
    const {user} = this.props;
    const count = user.$meta.count("unseenNotifications");
    return (
      <div>
        {count > 0 && (
          <Ui.Row css={{justifyContent: "center", margin: 10}}>
            <DoAction key="contest" name="notifications.seenAll" data={{ids: []}}>
              {({performFn, isLoading}) => (
                <Ui.MiniActionButton disabled={isLoading} onDark onClick={performFn}>
                  mark all as seen
                </Ui.MiniActionButton>
              )}
            </DoAction>
          </Ui.Row>
        )}
        <Pager
          desc
          collection={{parent: user, relName: "notifications"}}
          containerCss={{width: 200}}
          count={30}
          renderIfEmpty={() => <div css={{padding: 15}}>No notifications yet</div>}
          renderGroup={this.renderGroup}
        />
      </div>
    );
  }
}

export default NotificationOverlay;
