import React from "react";
import RouterInfo from "./RouterInfo";
import queryString from "query-string";
import {DefaultModalSpawner} from "./Modal";
import Loadable from "react-loadable";
import Spinner from "./Spinner";
import {WithinSameLayerLink} from "./PiqRouter";

const createLoadableModalComp = loader =>
  Loadable({
    loader,
    loading: Spinner,
  });

const modals = {
  login: createLoadableModalComp(() => import(/* webpackChunkName: "Login" */ "../pages/Login")),
  register: createLoadableModalComp(() =>
    import(/* webpackChunkName: "Register" */ "../pages/Register")
  ),
  feedback: createLoadableModalComp(() =>
    import(/* webpackChunkName: "Feedback" */ "../pages/Feedback")
  ),
};

const ModalRegistry = () => (
  <RouterInfo>
    {({location: l, history}) => {
      const location = (l.state && l.state.$visibleLocation) || l;
      const Modal = modals[queryString.parse(location.search).modal];
      const onClose = () => {
        const search = queryString.stringify({...queryString.parse(l.search), modal: undefined});
        const link =
          l.state && l.state.$visibleLocation
            ? {
                pathname: l.state.$visibleLocation.pathname,
                search,
                state: {$passToRouterLocation: l},
              }
            : {
                pathname: l.pathname,
                search,
              };
        history.push(link);
      };
      return (
        <DefaultModalSpawner
          isOpen={Modal}
          renderOverlay={() => <Modal onClose={onClose} />}
          onClose={onClose}
        />
      );
    }}
  </RouterInfo>
);

export const ModalLocation = ({modal, children}) => (
  <RouterInfo>
    {({location: l}) => {
      const location = (l.state && l.state.$visibleLocation) || l;
      return children({
        ...location,
        search: queryString.stringify({...queryString.parse(location.search), modal}),
      });
    }}
  </RouterInfo>
);

export const ModalLink = ({modal, ...rest}) => (
  <ModalLocation modal={modal}>{to => <WithinSameLayerLink to={to} {...rest} />}</ModalLocation>
);
export default ModalRegistry;
