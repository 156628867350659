import {shallowEqualArray, dateStringToDate} from "./utils";
import slugify from "slugify";

export const localDateToUtc = local => {
  const localOffset = local.getTimezoneOffset() * 60 * 1000;
  const utc = local.getTime() + localOffset;
  return new Date(utc);
};

export const getUTCDate = () => localDateToUtc(new Date());

export const weekOfYearAndYear = inDate => {
  const d = new Date(inDate);
  d.setDate(d.getDate() + 4 - (d.getDay() || 7));
  return [
    Math.ceil(((d.getTime() - new Date(d.getFullYear(), 0, 1).getTime()) / 8.64e7 + 1) / 7),
    d.getFullYear(),
  ];
};

export const weekAndYearOfContest = c => {
  const monday = c.$meta.isFieldLoaded("startDate", true) && dateStringToDate(c.startDate);
  return monday && weekOfYearAndYear(monday);
};

export const weeksToDateLocal = (year, wn) => {
  const j10 = new Date(year, 0, 10, 12, 0, 0);
  const j4 = new Date(year, 0, 4, 12, 0, 0);
  const mon1 = j4.getTime() - j10.getDay() * 86400000;
  return new Date(mon1 + (wn - 1) * 7 * 86400000);
};

export const weeksToDate = (year, wn) => {
  return localDateToUtc(weeksToDateLocal(year, wn));
};

export const isVotingDay = contestWeekAndYear => {
  const now = getUTCDate();
  const nowWeekAndYear = weekOfYearAndYear(now);
  return shallowEqualArray(contestWeekAndYear, nowWeekAndYear) && ((now.getDay() + 6) % 7) + 1 > 5;
};

export const endOfVoting = contestWeekAndYear => {
  const d = weeksToDateLocal(contestWeekAndYear[1], contestWeekAndYear[0] + 1);
  d.setHours(0);
  return new Date(d.getTime() - d.getTimezoneOffset() * 60 * 1000);
};

export const startOfVoting = contestWeekAndYear => {
  const d = weeksToDateLocal(contestWeekAndYear[1], contestWeekAndYear[0] + 1);
  const saturdayMorning = new Date(d.getFullYear(), d.getMonth(), d.getDate() - 2);
  return new Date(saturdayMorning.getTime() - saturdayMorning.getTimezoneOffset() * 60 * 1000);
};

export const linkToContest = contest => {
  const weekAndYear = weekAndYearOfContest(contest);
  return `/contest/${weekAndYear[1]}/${weekAndYear[0]}/${slugify(contest.keyword)}`;
};
