import React from "react";
import slugify from "slugify";
import styled from "react-emotion";
import colors from "../lib/colors";
import {OpaqueLink} from "./PiqRouter";
import Ui from "./ui";
import Toggler from "./Toggler";
import Heart from "../pages/Picture/Heart";
import {InterpolateIfActive} from "./Effects";
import {IsImageLoaded} from "./IsImageLoaded";
import {api} from "../lib/api";

const OverlayComp = styled("div")(Ui.common.full, {
  overflow: "hidden",
});
const sharedOverlayBoxStyle = {
  position: "absolute",
  left: 0,
  right: 0,
  overflow: "hidden",
  fontSize: 11,
  padding: "4px 5px",
  backgroundColor: colors.fade(colors.dark, 0.9),
  color: colors.white,
};
const TitleBox = styled("div")(sharedOverlayBoxStyle, {
  top: 0,
  textAlign: "center",
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  boxShadow: "0 1px 2px rgba(0,0,0,0.3)",
});

const StatBox = styled("div")(sharedOverlayBoxStyle, {
  display: "flex",
  margin: "0 -5px",
  justifyContent: "center",
  bottom: 0,
  boxShadow: "0 -1px 2px rgba(0,0,0,0.3)",
});

const Stat = ({icon: Icon, iconProps, value}) => (
  <Ui.Row css={{margin: "0 5px"}}>
    <Icon {...iconProps} />
    <div css={{marginLeft: 5}}>{value}</div>
  </Ui.Row>
);

const OverlayInfo = ({picture}) => (
  <Toggler>
    {(isActive, toggle) => (
      <InterpolateIfActive isActive={isActive} springConfig={{stiffness: 350, damping: 26}}>
        {val => (
          <OverlayComp onMouseEnter={toggle.on} onMouseLeave={toggle.off}>
            {val !== 0 && (
              <TitleBox
                style={{opacity: val, transform: `translate3d(0, ${(1 - val) * -100}%, 0)`}}
              >
                {picture.title}
              </TitleBox>
            )}
            {val !== 0 && (
              <StatBox style={{opacity: val, transform: `translate3d(0, ${(1 - val) * 100}%, 0)`}}>
                <Stat
                  icon={Heart}
                  iconProps={{height: 12, inactive: picture.likes === 0}}
                  value={picture.likes}
                />
                <Stat
                  icon={Ui.Icon.Comment}
                  iconProps={picture.commentCount === 0 ? {css: {opacity: 0.5}} : {}}
                  value={picture.commentCount}
                />
              </StatBox>
            )}
          </OverlayComp>
        )}
      </InterpolateIfActive>
    )}
  </Toggler>
);

export const SIZE_TO_PIXELS = {
  tiny: 16,
  small: 25,
  normal: 100,
  big: 400,
};

const shared = {
  display: "block",
  overflow: "hidden",
  width: "100%",
  height: "auto",
};

const DeadPiq = styled("img")(shared, {
  backgroundColor: colors.midGray,
});
const LoadingPiq = styled("div")(shared, {
  backgroundColor: colors.borderGray,
  height: 0,
  paddingTop: "100%",
});

export const LoadedPiq = styled("img")(shared);

const PiqInfo = ({children, picture, size = "normal"}) => {
  if (!picture || picture.$meta.isDeleted() || picture.isDeleted) return children({dead: true});
  const pixels = SIZE_TO_PIXELS[size];
  const vals = ["title", "basePath", ...(pixels >= 100 ? ["likes", "commentCount"] : [])];
  const allLoaded = vals.map(v => picture.$meta.isFieldPresent(v, true)).reduce((m, v) => m && v);
  if (!allLoaded) return children({loading: true});
  return children({picture});
};

const inlineStyle = {
  display: "inline-block",
  position: "relative",
  top: 3,
};

export const Spacer = styled("div")(({width}) => ({
  position: "relative",
  width,
  maxWidth: "100%",
}));

class RawPiq extends React.Component {
  render() {
    const {
      info: {loading, dead, picture},
      inline,
      size = "normal",
      innerRef,
      noOverlay,
      width: customWidth,
      className,
      style,
      spacerCss,
      ...rest
    } = this.props;
    const fileSize = SIZE_TO_PIXELS[size];
    const width = customWidth || fileSize;
    const height = width;
    return (
      <Spacer
        width={width}
        css={{...(inline ? inlineStyle : undefined), ...spacerCss}}
        className={className}
        style={style}
      >
        {dead ? (
          <DeadPiq
            size={width}
            innerRef={innerRef}
            title="this piq is dead"
            alt="deleted image"
            src={require("../img/del_piq.png")}
            {...rest}
            width={width.toString()}
            height={height.toString()}
          />
        ) : loading ? (
          <LoadingPiq size={width} innerRef={innerRef} title="loading..." {...rest} />
        ) : (
          <React.Fragment>
            {width >= 100 && !noOverlay && <OverlayInfo picture={picture} />}
            <IsImageLoaded url={`${picture.basePath}_${fileSize}x${fileSize}.png`}>
              {isLoaded =>
                isLoaded ? (
                  <LoadedPiq
                    innerRef={this.handleImgRef}
                    title={picture.title}
                    alt={picture.title}
                    src={`${picture.basePath}_${fileSize}x${fileSize}.png`}
                    {...rest}
                    width={width.toString()}
                    height={height.toString()}
                  />
                ) : (
                  <LoadingPiq size={width} innerRef={innerRef} title="loading..." {...rest} />
                )
              }
            </IsImageLoaded>
          </React.Fragment>
        )}
      </Spacer>
    );
  }
}

const Piq = ({picture, ...rest}) => (
  <PiqInfo picture={picture} size={rest.size}>
    {info => <RawPiq info={info} {...rest} />}
  </PiqInfo>
);
export default Piq;

const LinkOrSpan = props => {
  if (props.pathname) {
    const {myInnerRef, ...rest} = props;
    return <OpaqueLink innerRef={myInnerRef} {...rest} />;
  } else {
    const {myInnerRef, ...rest} = props;
    return <span ref={myInnerRef} {...rest} />;
  }
};
const PiqLink = styled(LinkOrSpan)({display: "block", flexShrink: 0});

export const PiqAsLink = ({picture, size, noOverlay, width, innerRef, ...rest}) => (
  <PiqInfo picture={picture} size={size}>
    {info => (
      <PiqLink
        myInnerRef={innerRef}
        pathname={info.picture && `/picture/${picture.id}/${slugify(picture.title)}`}
        {...rest}
      >
        <RawPiq info={info} size={size} noOverlay={noOverlay} width={width} />
      </PiqLink>
    )}
  </PiqInfo>
);

export const FramedPiq = styled(PiqAsLink)({
  padding: 5,
  backgroundColor: colors.white,
  boxShadow: "0 2px 15px -2px rgba(0,0,0,0.8)",
});

export const PiqFromId = ({id, ...rest}) => (
  <TinyPiq picture={api.getModel({modelName: "picture", id})} {...rest} />
);
export const TinyPiq = ({picture, ...rest}) => (
  <Piq picture={picture} size="tiny" inline {...rest} />
);
export const PiqLinkFromId = ({id, ...rest}) => (
  <TinyPiqLink picture={api.getModel({modelName: "picture", id})} {...rest} />
);
export const TinyPiqLink = ({picture, ...rest}) => (
  <PiqAsLink picture={picture} size="tiny" {...rest} />
);
