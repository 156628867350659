export function getScrollParent(node) {
  let offsetParent = node;
  // eslint-disable-next-line no-cond-assign
  while ((offsetParent = offsetParent.offsetParent)) {
    const overflowYVal = window.getComputedStyle(offsetParent, null).getPropertyValue("overflow-y");
    if (overflowYVal === "auto" || overflowYVal === "scroll")
      return offsetParent.tagName === "BODY" ? document : offsetParent;
  }
  return document;
}
