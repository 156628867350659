import React from "react";
import styled from "react-emotion";

const IconContainer = styled("div")({
  lineHeight: 1,
  height: "1em",
  "& svg": {fill: "currentColor", height: "1em"},
});

const Icon = ({name, ...rest}) => (
  <IconContainer
    {...rest}
    dangerouslySetInnerHTML={{__html: require(`!!svg-inline-loader!../../icons/${name}.svg`)}}
  />
);

export default {
  ArrowRight: props => <Icon name="arrow-right" {...props} />,
  CaretDown: props => <Icon name="caret-down" {...props} />,
  Check: props => <Icon name="check" {...props} />,
  Cog: props => <Icon name="cog" {...props} />,
  Comment: props => <Icon name="comment" {...props} />,
  Colors: props => <Icon name="colors" {...props} />,
  Date: props => <Icon name="date" {...props} />,
  Duration: props => <Icon name="duration" {...props} />,
  Eye: props => <Icon name="eye" {...props} />,
  Logout: props => <Icon name="logout" {...props} />,
  Mail: props => <Icon name="mail" {...props} />,
  Notification: props => <Icon name="notification" {...props} />,
  Search: props => <Icon name="search" {...props} />,
  Star: props => <Icon name="star" {...props} />,
};
