import MiniEvent from "../mini-event";
import createCollectFn from "./collector";
import createCache from "./cache";
import createMutator from "./mutator";
import createInstancePool from "./instance-pool";

const today = new Date();

const defaults = {
  array: [],
  json: {},
  date: today,
  int: 0,
  boolean: false,
  day: {year: today.getFullYear(), month: today.getMonth() + 1, day: today.getDate()},
};

function fieldDescFromDesc(desc = {}) {
  return {
    ...{
      type: "default",
      defaultValue: defaults[desc.type] !== undefined ? defaults[desc.type] : "unknown",
      deps: [],
    },
    ...desc,
  };
}

function normalizeDescription(desc) {
  if (desc.name === "_root") {
    desc.idPropAsArray = [];
  } else {
    desc.idProp = desc.idProp || "id";
    desc.idPropAsArray = Array.isArray(desc.idProp) ? desc.idProp : [desc.idProp];
  }

  desc.fields = [...desc.fields, ...desc.idPropAsArray].reduce((fields, field) => {
    if (typeof field === "string") {
      fields[field] = fieldDescFromDesc();
    } else {
      Object.keys(field).forEach(fieldLabel => {
        const fieldDesc = field[fieldLabel];
        fields[fieldLabel] = fieldDescFromDesc(typeof fieldDesc === "string" ? {} : fieldDesc);
      });
    }
    return fields;
  }, {});

  function hasManyDescFromString(key) {
    return {model: key.replace(/s$/, ""), isSingleton: false, fk: `${desc.name}Id`, deps: []};
  }

  function belongsToDescFromString(key) {
    return {model: key, fk: `${key}Id`, deps: []};
  }

  desc.hasMany = (desc.hasMany || []).reduce((m, entry) => {
    if (typeof entry === "string") {
      m[entry] = hasManyDescFromString(entry);
    } else {
      Object.keys(entry).forEach(relName => {
        m[relName] = {...hasManyDescFromString(relName), ...entry[relName]};
      });
    }
    return m;
  }, {});

  desc.belongsTo = (desc.belongsTo || []).reduce((m, entry) => {
    if (typeof entry === "string") {
      m[entry] = belongsToDescFromString(entry);
    } else {
      Object.keys(entry).forEach(relName => {
        m[relName] = {...belongsToDescFromString(relName), ...entry[relName]};
      });
    }
    return m;
  }, {});

  return desc;
}

export default function createApi(descriptionList, fetcher, mutations, dispatcher) {
  let notifyUpdatesTimeoutId = null;
  let apiChangeIndex = 0;

  const descriptions = descriptionList.reduce((m, d) => {
    m[d.name] = normalizeDescription({...d});
    return m;
  }, {});

  const onChangeEvent = new MiniEvent();
  const onCacheModifiedEvent = new MiniEvent();
  const onLoadEvent = new MiniEvent();

  onCacheModifiedEvent.addListener(() => {
    // only fire once per tick by waiting for one tick before updating the listeners
    if (!notifyUpdatesTimeoutId) {
      notifyUpdatesTimeoutId = setTimeout(() => {
        notifyUpdatesTimeoutId = null;
        apiChangeIndex += 1;
        onChangeEvent.emit(apiChangeIndex);
      });
    }
  });

  /* eslint-disable no-use-before-define */
  // TODO: figure out a sane way of who knows about whom how

  const {collect, isCurrentlyLoading} = createCollectFn(
    fetcher,
    (...args) => cache.add(...args),
    onLoadEvent.emit,
    descriptions
  );
  const cache = createCache(
    descriptions,
    collect,
    onCacheModifiedEvent.emit,
    onChangeEvent.addListener
  );
  const mutate = createMutator(mutations, dispatcher, cache, descriptions);
  const instancePool = createInstancePool({
    cache,
    descriptions,
    collect,
    registerOnChangeListener: onChangeEvent.addListener,
    getApiChangeIndex: () => apiChangeIndex,
    onCacheModified: onCacheModifiedEvent.addListener,
  });

  /* eslint-disable no-use-before-define */

  return {
    descriptions,
    cache,
    mutate,
    getModel: instancePool.getInstanceViaId,
    registerOnLoadListener: onLoadEvent.addListener,
    registerOnChangeListener: onChangeEvent.addListener,
    getRoot: instancePool.getRoot,
    getChangeIndex: () => apiChangeIndex,
    isCurrentlyLoading,
  };
}
