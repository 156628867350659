let nextMutationId = 1;

export default function createMutator(mutations, dispatcher, cache) {
  const instance = {};
  Object.keys(mutations).forEach(fullActionName => {
    const mutationDescription = mutations[fullActionName];
    const [actionNamespace, actionName] = fullActionName.split(".");
    instance[actionNamespace] = instance[actionNamespace] || {};
    instance[actionNamespace][actionName] = data =>
      new Promise((resolve, reject) => {
        const id = nextMutationId;
        nextMutationId += 1;
        cache.addOptimisticAction(id, mutationDescription, data);
        dispatcher(`${actionNamespace}/${actionName}`, data, (err, retVal) => {
          if (err) {
            cache.failedOptimisticAction(id);
            reject(err);
          } else {
            cache.resolveOptimisticAction(id, mutationDescription, data, retVal);
            resolve(retVal);
            cache.invalidate(mutationDescription, data, retVal);
          }
        });
      });
  });

  instance.$descriptions = mutations;

  return instance;
}
