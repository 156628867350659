import React from "react";
import RouterInfo from "./RouterInfo";
import {DefaultModalSpawner} from "./Modal";
import {matchPath} from "react-router";
import colors from "../lib/colors";
import {stripSpecialStateInfo} from "./PiqRouter";
import Routes from "../Routes";

export const routes = [
  {
    path: "/picture/:pictureId/:title?",
    comp: Routes.Picture,
  },
  {
    path: "/messages",
    comp: Routes.Messages,
  },
];

const ModalWithRouteRegistry = () => (
  <RouterInfo>
    {({location, history}) => {
      const $visibleLocation = location.state && location.state.$visibleLocation;
      let match = null;
      let Comp = null;
      if ($visibleLocation) {
        routes.some(r => {
          const m = matchPath($visibleLocation.pathname, r.path);
          if (m) {
            match = m;
            Comp = r.comp;
            return true;
          }
          return false;
        });
      }
      const onClose = () => history.push(stripSpecialStateInfo(location));
      return (
        <DefaultModalSpawner
          isOpen={match}
          modalProps={{css: {backgroundColor: colors.white, color: "inherit"}}}
          renderOverlay={() => <Comp match={match} location={location} history={history} isModal />}
          onClose={onClose}
        />
      );
    }}
  </RouterInfo>
);

export default ModalWithRouteRegistry;
