import {createTheme} from "react-reform";
import React from "react";
import Ui from "../../components/ui";
import colors from "../colors";
import styled from "react-emotion";
import {InterpolateIfActive, HighlightIfActive} from "../../components/Effects";

const WithComp = ({comp: Comp, ...rest}) => <Comp {...rest} />;

const FormComp = styled(WithComp)({
  width: "100%",
});

const Label = styled("label", {shouldForwardProp: p => !["hasError", "onWhite"].includes(p)})(
  Ui.common.headerFont,
  ({hasError, onWhite}) => ({
    display: "block",
    cursor: "pointer",
    marginBottom: "10px",
    fontSize: "16px",
    color: hasError
      ? onWhite
        ? colors.danger
        : colors.dangerTextOnDark
      : onWhite
      ? "#888"
      : colors.paleWhite,
  })
);

const GlobalErrorBox = styled("div")({
  backgroundColor: colors.danger,
  color: colors.fade(colors.white, 0.9),
  padding: "5px 10px",
  marginBottom: "25px",
  boxShadow: `0 2px ${colors.dangerDark}`,
});

const StatusIconContainer = styled("div")({
  position: "absolute",
  left: "100%",
  top: 0,
  bottom: 0,
  display: "flex",
  alignItems: "center",
  marginLeft: 15,
});

export const Explanation = styled("div")({
  marginTop: 5,
  fontSize: 11,
  color: colors.fade(colors.white, 0.5),
});

export default createTheme({
  renderForm: (
    FormContainer,
    children,
    {
      directProps: {
        buttonLabel,
        buttonProps: {css, ...restButtonProps} = {},
        small,
        onWhite,
        ...rest
      },
      globalErrors,
      status,
    }
  ) => {
    return (
      <FormComp comp={FormContainer} noValidate {...rest}>
        {globalErrors.length > 0 && (
          <GlobalErrorBox>
            {globalErrors.map(error => (
              <div key={error}>{error.message || error.toString()}</div>
            ))}
          </GlobalErrorBox>
        )}
        <div>{children}</div>
        <Ui.RaisedButton
          type="submit"
          size={small ? "normal" : "big"}
          disabled={status === "pending"}
          css={{position: "relative", ...css}}
          {...restButtonProps}
        >
          {buttonLabel || "Submit"}
          <InterpolateIfActive isActive={status === "pending"}>
            {val =>
              val !== 0 && (
                <StatusIconContainer style={{opacity: val}}>
                  <Ui.Icon.Star
                    css={{
                      animation: `${Ui.animations.rotate} 4s infinite linear`,
                      color: colors.paleWhite,
                    }}
                  />
                </StatusIconContainer>
              )
            }
          </InterpolateIfActive>
          <HighlightIfActive isActive={status === "success"} activeMs={1500}>
            {active => (
              <InterpolateIfActive isActive={active}>
                {val =>
                  val !== 0 && (
                    <StatusIconContainer style={{opacity: val, transform: `scale(${val * 1.2})`}}>
                      <Ui.Icon.Check css={{color: colors.success, fontSize: 24}} />
                    </StatusIconContainer>
                  )
                }
              </InterpolateIfActive>
            )}
          </HighlightIfActive>
        </Ui.RaisedButton>
      </FormComp>
    );
  },

  renderField: (
    Field,
    {
      name,
      directProps: {label = name, explanation, fieldWrapperCss, ...rest},
      directFormProps: {small, onWhite},
      wrapperProps: {type, withError},
      isFocused,
      isDirty,
      formStatus,
      validations,
      id,
    }
  ) => {
    const errors = validations
      .filter(({isValid, name: errorType}) => {
        if (isValid) return false;
        if (isDirty && (withError || !isFocused)) return true;
        if (formStatus === "preSubmitFail") return true;
        if (errorType === "server") return true;
        return false;
      })
      .map(({errorMessage, name}) => ({errorMessage, name}));

    const showError = errors.length > 0;
    const fieldProps = {id, disabled: formStatus === "pending", ...rest};
    if (withError) fieldProps.errors = errors;
    if (type === "Checkbox") {
      return (
        <Label
          htmlFor={id}
          hasError={showError}
          css={{
            display: "flex",
            alignItems: "center",
            marginBottom: small ? 20 : 40,
            minWidth: "1px",
            ...fieldWrapperCss,
          }}
          onWhite={onWhite}
        >
          <Field {...fieldProps} />
          <Ui.Row css={{marginLeft: "10px", flexWrap: "wrap", alignItems: "flex-start"}}>
            {label || name}
            {(explanation || showError) && (
              <div>
                {showError
                  ? errors.map(e => (
                      <div css={{marginBottom: 5}} key={e.name}>
                        {e.errorMessage}
                      </div>
                    ))
                  : explanation}
              </div>
            )}
          </Ui.Row>
        </Label>
      );
    } else {
      return (
        <div css={{marginBottom: small ? 20 : 40, ...fieldWrapperCss}}>
          {label !== null && (
            <Label htmlFor={id} hasError={showError} onWhite={onWhite}>
              {label || name}
            </Label>
          )}
          <WithComp comp={Field} {...fieldProps} />
          {withError
            ? explanation && <Explanation>{explanation}</Explanation>
            : (explanation || showError) && (
                <Explanation>
                  {showError
                    ? errors.map(e => (
                        <div css={{marginBottom: 5}} key={e.name}>
                          {e.errorMessage}
                        </div>
                      ))
                    : explanation}
                </Explanation>
              )}
        </div>
      );
    }
  },
});
