import React from "react";
import Ui from "./ui";
import styled from "react-emotion";

const SpinContainer = styled("div")(Ui.common.full, Ui.common.center, {
  zIndex: 1,
});

const SpinG = styled("g")({
  animation: `${Ui.animations.rotate} 2s infinite linear`,
  transformOrigin: "50% 50%",
});

const Spinner = ({size = 75, ...rest}) => (
  <SpinContainer {...rest}>
    <svg
      style={{width: 50, height: 50, transform: `scale(${Math.min(3, size / 50)})`}}
      viewBox="0 0 40 40"
      xmlns="http://www.w3.org/2000/svg"
      stroke="#777"
      fill="none"
    >
      <SpinG strokeWidth="2" fill="none">
        <circle strokeOpacity=".25" cx="20" cy="20" r="18" />
        <path strokeOpacity=".5" d="M38 20A18 18 0 0 0 20 2" />
      </SpinG>
    </svg>
  </SpinContainer>
);

export default Spinner;

const LoadingContainer = styled("div")(Ui.common.full, ({withCover}) => ({
  ...(withCover && {backgroundColor: "rgba(250,250,250,0.8)"}),
  zIndex: 1,
  opacity: 0,
  transitionProperty: "opacity",
  pointerEvents: "none",
  ".active": {
    pointerEvents: "auto",
    opacity: 1,
  },
}));

export const FadeSpinner = ({
  isActive,
  noDefaultStyle,
  size = 75,
  withCover,
  children,
  ...rest
}) => (
  <div css={{position: "relative"}} {...rest}>
    <LoadingContainer withCover={withCover} className={isActive ? "active" : null}>
      {isActive && <Spinner size={size} />}
    </LoadingContainer>
    {children}
  </div>
);
