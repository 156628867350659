import React from "react";

const loadedUrlCache = {};

export class IsImageLoaded extends React.Component {
  constructor(props) {
    super(props);
    this.state = {isLoaded: this.loadImage(props.url)};
  }

  componentWillUnmount() {
    if (this.unsub) this.unsub();
  }

  componentWillReceiveProps(nextProps) {
    if (this.props.url !== nextProps.url) {
      if (this.unsub) this.unsub();
      const isLoaded = this.loadImage(nextProps.url);
      if (isLoaded !== this.state.isLoaded) this.setState({isLoaded});
    }
  }

  loadImage(url) {
    if (!url) return false;
    if (loadedUrlCache[url]) return true;
    const img = new window.Image();
    img.src = url;
    if (img.complete) {
      return true;
    } else {
      img.onload = () => {
        loadedUrlCache[url] = true;
        if (this.unsub) this.setState({isLoaded: true});
        this.unsub = null;
      };
      this.unsub = () => {
        img.onload = null;
        this.unsub = null;
      };
      return false;
    }
  }

  render() {
    return this.props.children(this.state.isLoaded);
  }
}
