import React from "react";
import {Switch, Route} from "react-router";
import {ReformContext} from "react-reform";
import Helmet from "react-helmet";
import validations from "./lib/forms/validations";
import PiqRouter from "./components/PiqRouter";
import AppShell from "./components/AppShell";
import ModalRegistry from "./components/ModalRegistry";
import ModalWithRouteRegistry, {routes} from "./components/ModalWithRouteRegistry";
import {ClickOutsideProvider} from "./components/ClickOutside";
import defaultTheme from "./lib/forms/defaultTheme";
import {Simple} from "./components/ui/layouts";
import {GetRoot} from "./lib/api";
import {ConfirmationRegistry} from "./lib/confirm";
import ClaimAlert from "./components/ClaimAlert";
import FeedbackTab from "./components/FeedbackTab";
import {MessengerArea} from "./lib/messenger";
import PushUpdates from "./components/PushUpdates";
import {SetAppShellProps} from "./contexts/AppShell";
import ExceptionAlert from "./components/ExceptionAlert";
import ApiErrorAlert from "./components/ApiErrorAlert";
import {AdCampaigns} from "./components/AdBanner";
import Routes from "./Routes";

const App = (
  <GetRoot sub>
    {() => (
      <ReformContext themes={{default: defaultTheme}} validations={validations}>
        <AdCampaigns>
          <ClickOutsideProvider>
            {onClick => (
              <PiqRouter>
                <AppShell onClick={onClick}>
                  <Helmet>
                    <title>piq - pixel art maker </title>
                    <meta
                      name="description"
                      content="piq is a fun, easy-to-use online pixel art program with a number of cool unique features. It's designed to let you create and share great pixel art, logos or favicons quickly and easily."
                    />
                    <meta
                      name="keywords"
                      content="piq, pixel art, pixel art creator, free pixel art, logo design, logo creator, pixel art maker, pixel art programm, isometric, 8-bit pixel art"
                    />
                  </Helmet>
                  <Switch>
                    <Route exact path="/" component={Routes.Home} />
                    <Route exact path="/draw" component={Routes.Draw} />
                    <Route path="/gallery" component={Routes.Gallery} />
                    <Route path="/u/:userName" component={Routes.User} />
                    <Route path="/reset-password/:token" component={Routes.ResetPassword} />
                    <Route path="/verify-email/:token" component={Routes.VerifyEmail} />
                    <Route exact path="/contest" component={Routes.ContestIndex} />
                    <Route
                      exact
                      path="/contest/:year/:week/:title"
                      component={Routes.ContestEntries}
                    />
                    <Route
                      exact
                      path="/contest/:year/:week/:title/vote"
                      component={Routes.ContestVote}
                    />
                    <Route exact path="/news" component={Routes.News} />
                    <Route exact path="/news/:entryId" component={Routes.NewsEntry} />
                    <Route exact path="/claim" component={Routes.ClaimPictures} />

                    <Route exact path="/about" component={Routes.About} />
                    <Route exact path="/imprint" component={Routes.Imprint} />
                    <Route exact path="/tos" component={Routes.ToS} />

                    <Route exact path="/forum" component={Routes.Forum} />
                    <Route exact path="/forum/thread/:threadId" component={Routes.RedirectThread} />
                    <Route exact path="/forum/:board" component={Routes.Board} />
                    <Route exact path="/forum/:board/:thread" component={Routes.Thread} />
                    <Route path="/settings" component={Routes.Settings} />
                    <Route path="/admin" component={Routes.Admin} />
                    {routes.map(r => (
                      <Route key={r.path} path={r.path} component={r.comp} />
                    ))}
                    <Route
                      render={() => (
                        <SetAppShellProps>
                          <Simple>Nothing found</Simple>
                        </SetAppShellProps>
                      )}
                    />
                  </Switch>
                  <ModalRegistry />
                  <ModalWithRouteRegistry />
                  <ConfirmationRegistry />
                  <ClaimAlert />
                  <MessengerArea />
                  <FeedbackTab />
                  <PushUpdates />
                  <ApiErrorAlert />
                  <ExceptionAlert />
                </AppShell>
              </PiqRouter>
            )}
          </ClickOutsideProvider>
        </AdCampaigns>
      </ReformContext>
    )}
  </GetRoot>
);

export default App;
