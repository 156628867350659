import React from "react";
import Ui from "../components/ui";
import styled from "react-emotion";
import {DefaultModalSpawner} from "../components/Modal";
import MiniEvent from "./mini-event";

export const confirmEvent = new MiniEvent();

const Question = styled("div")(Ui.common.headerFont, {
  fontSize: "30px",
  lineHeight: 1.0,
  marginBottom: "50px",
  textAlign: "center",
});

export class ConfirmationRegistry extends React.Component {
  state = {
    current: null,
  };

  componentDidMount() {
    this.unsub = confirmEvent.addListener(this.handleChange);
  }

  componentWillUnmount() {
    this.unsub();
  }

  handleChange = confirm => this.setState({current: confirm});
  handleNo = () => {
    if (this.state.current) {
      this.setState({current: null}, this.state.current.resolve(false));
    }
  };
  handleYes = () => {
    if (this.state.current) {
      this.setState({current: null}, this.state.current.resolve(true));
    }
  };

  render() {
    const {current} = this.state;
    return (
      <DefaultModalSpawner
        isOpen={current}
        onClose={this.handleNo}
        renderOverlay={() => (
          <div css={{padding: 40}}>
            <Question>{current.question}</Question>
            <Ui.Row>
              <Ui.RaisedButton onClick={this.handleYes}>{current.yesLabel}</Ui.RaisedButton>
              {current.noLabel && (
                <Ui.RaisedButton white onClick={this.handleNo} css={{marginLeft: "auto"}}>
                  {current.noLabel}
                </Ui.RaisedButton>
              )}
            </Ui.Row>
          </div>
        )}
      />
    );
  }
}

const confirm = ({question, yesLabel = "Okay", noLabel = "Nope"}) =>
  new Promise(resolve => {
    confirmEvent.emit({question, yesLabel, noLabel, resolve});
  });

export default confirm;
