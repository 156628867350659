import {gaTrackPageView, gaStart, gaTrackEvent} from "./ga";

export default function start() {
  gaStart();
}

let lastPath = null;
export const trackPageView = (path, isReplace) => {
  if (path === lastPath) return;
  lastPath = path;

  // to allow new route to change document.title etc
  setTimeout(() => {
    gaTrackPageView(path, isReplace);
  });
};

export const trackEvent = ({category, action, label, value}) => {
  gaTrackEvent({category, action, label, value});
};
