import React from "react";
import styled from "react-emotion";
import Ui from "./ui";
import colors from "../lib/colors";
import {handleError} from "../lib/error-logger";

const Error = styled("div")(Ui.common.full, Ui.common.center, Ui.common.headerFont, {
  fontSize: 12,
  textTransform: "uppercase",
  backgroundColor: colors.danger,
  color: colors.white,
  padding: 10,
  minHeight: 50,
});

export default class Catch extends React.Component {
  state = {hasError: false};

  componentDidCatch(error, info) {
    this.setState({hasError: true});
    handleError(error, info);
  }

  render() {
    return this.state.hasError ? <Error>Something went wrong here</Error> : this.props.children;
  }
}
