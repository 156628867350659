import Color from "color";

const contrastCache = {};

const getContrastColor = col => {
  if (!contrastCache[col]) {
    const colObj = new Color(col);
    contrastCache[col] = colObj.luminosity() < 0.5 ? "#fff" : colObj.darken(0.75).toString();
  }
  return contrastCache[col];
};

const mixCache = {};
const mix = (col1, col2, ratio = 0.5) => {
  const key = `${col1}-${col2}-${ratio}`;
  return (mixCache[key] =
    mixCache[key] ||
    Color(col1)
      .mix(Color(col2), ratio)
      .toString());
};

const fadeCache = {};
const fade = (col1, fadeVal) => {
  const key = `${col1}-${fadeVal}`;
  return (fadeCache[key] =
    fadeCache[key] ||
    Color(col1)
      .alpha(fadeVal)
      .toString());
};

export default {
  brand: "#b72a75",
  brandPaler: "#fbf5f8",
  brandPale: "#eee2e8",
  brandLighter: "#cb6c9f",
  brandLight: "#cd478e",
  brandBg: "#5a2943",
  brandDark: "#8d1153",
  brandDarkest: "#13010b",
  brandTextOnDark: "#d44e95",

  focus: "rgb(59, 153, 252)",
  focusShadow: "#0274ed",
  focusDark: "#002144",
  danger: "#ff205e",
  dangerDark: "#bf0c3e",
  dangerPale: "#fff0f4",
  dangerTextOnDark: "#ffb5c8",
  warning: "#ffb52a",
  warningDark: "#da981d",
  success: "#129b54",
  successDark: "#128D15",

  white: "#fff",
  nearWhite: "#eee",
  paleWhite: "#ddd",
  borderGray: "#ddd",
  shadowWhite: "#ccc",
  lightText: "#bbb",
  midGray: "#777",
  dark: "#444",
  darkerHover: "#333",
  darker: "#222",
  black: "#000",
  getContrastColor,
  mix,
  fade,
};
