import ErrorStackParser from "error-stack-parser";
import MiniEvent from "./mini-event";
import fetch from "./fetch";

export const exceptionEvent = new MiniEvent();

export const handleError = error => {
  const stackFrames = ErrorStackParser.parse(error);
  fetch("/services/send-error", {
    method: "post",
    body: JSON.stringify({stackFrames, message: error.message}),
  });
};

window.onerror = (msg, file, line, col, error) => {
  if (msg.indexOf("ResizeObserver loop limit exceeded") >= 0) return;
  if (msg.indexOf("Minified exception occurred") >= 0) return;
  if (msg.indexOf("Cannot read property '_currentElement'") >= 0) return;
  exceptionEvent.emit();
  const stackFrames = error
    ? ErrorStackParser.parse(error)
    : [{fileName: file, lineNumber: line, columnNumber: col, functionName: "unknown"}];
  fetch("/services/send-error", {
    method: "post",
    body: JSON.stringify({stackFrames, message: msg}),
  });
};
