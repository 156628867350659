import React from "react";
import styled from "react-emotion";
import SVG from "./SVG";
import Toggler from "./Toggler";
import DoAction from "./DoAction";
import {Route, Link} from "react-router-dom";
import {GetMe} from "../lib/api";
import {ModalLink} from "./ModalRegistry";
import {DropDown, DefaultOverlay} from "./Overlay";
import {logout} from "../lib/auth";
import Ui from "./ui";
import colors from "../lib/colors";
import {AvatarLink, userLink} from "./Avatar";
import {MinRoleWithUser} from "./MinRole";
import NotificationOverlay from "./NotificationOverlay";
import Feed from "./Feed";
import AppShellContext from "../contexts/AppShell";
import Media from "react-media";
import {OpaqueLink} from "./PiqRouter";

const renderNotifications = (overlayProps, user) => (
  <DefaultOverlay css={{background: colors.dark, color: colors.white}} {...overlayProps}>
    <NotificationOverlay user={user} />
  </DefaultOverlay>
);

const MenuButtonContent = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: "10px 15px 8px",
  fontSize: 12,
  fontWeight: "bold",
});

const MenuButton = ({children, onClick, to}) => (
  <Ui.PlainButton onClick={onClick} to={to}>
    <MenuButtonContent>{children}</MenuButtonContent>
  </Ui.PlainButton>
);

const renderProfileMenu = (overlayProps, user) => (
  <MinRoleWithUser user={user} minRole="admin">
    {isAdmin => (
      <DefaultOverlay css={{background: colors.dark, color: colors.white}} {...overlayProps}>
        <Ui.Col css={{minWidth: 150}}>
          <MenuButton to="/settings">Settings</MenuButton>
          {isAdmin && <MenuButton to="/admin/news">Edit News</MenuButton>}
          {isAdmin && <MenuButton to="/admin/contests">Edit Contests</MenuButton>}
          {isAdmin && <MenuButton to="/admin/ads">Manage Ads</MenuButton>}
          <MenuButton onClick={() => logout(null)}>
            <div>Logout</div>
            <Ui.Icon.Logout css={{marginLeft: "auto", fontSize: 18}} />
          </MenuButton>
        </Ui.Col>
      </DefaultOverlay>
    )}
  </MinRoleWithUser>
);

const NotificationIcon = ({user}) => {
  const count = user.$meta.count("unseenNotifications");
  return (
    <div css={{position: "relative"}}>
      {count > 0 && <Ui.UnreadPill>{count}</Ui.UnreadPill>}
      <Ui.Icon.Notification css={{fontSize: 20}} />
    </div>
  );
};

const MessagesIcon = ({user}) => {
  const umgm = user.unseenMessageGroupMembers;
  const sumMessages = umgm.reduce((s, mgm) => s + mgm.numUnseenMessages, 0);
  return (
    <div css={{position: "relative"}}>
      {sumMessages > 0 && (
        <Ui.UnreadPill>
          {sumMessages}
          {umgm.length > 1 && <small>&nbsp;|&nbsp;{umgm.length}</small>}
        </Ui.UnreadPill>
      )}
      <Ui.Icon.Mail css={{fontSize: 20}} />
    </div>
  );
};

const sharedHeaderLinkStyle = [
  Ui.common.headerFont,
  ({match}) => ({
    alignItems: "center",
    display: "flex",
    textTransform: "uppercase",
    padding: "5px 10px",
    transitionProperty: "background-color, color",
    color: match ? colors.white : colors.fade(colors.white, 0.8),
    boxShadow: match && "0 3px rgba(255, 255, 255, 0.5)",
    fontSize: "14px",
    ":hover": {backgroundColor: colors.fade(colors.white, 0.2)},
  }),
];

const AvatarHeaderLink = styled(AvatarLink)(sharedHeaderLinkStyle);
const MessagesLink = styled(OpaqueLink)(sharedHeaderLinkStyle);
const HeaderButton = styled(({myInnerRef, ...rest}) => (
  <Ui.PlainButton innerRef={myInnerRef} {...rest} />
))(
  sharedHeaderLinkStyle,
  ({active}) =>
    active && {
      color: colors.white,
      backgroundColor: colors.brandDark,
      ":hover": {backgroundColor: colors.brand},
    }
);

const AuthSection = () => (
  <GetMe>
    {user =>
      user ? (
        <Ui.Row>
          <Media query="(max-width: 650px)">
            {small => (
              <Route path={userLink(user)}>
                {({match}) => <AvatarHeaderLink user={user} match={match} withName={!small} />}
              </Route>
            )}
          </Media>
          <DropDown renderOverlay={p => renderNotifications(p, user)}>
            {(isOpen, toggle, measureRef) => (
              <HeaderButton active={isOpen} myInnerRef={measureRef} onClick={toggle}>
                <NotificationIcon user={user} />
              </HeaderButton>
            )}
          </DropDown>
          <MessagesLink pathname="/messages">
            <MessagesIcon user={user} />
          </MessagesLink>
          <DropDown renderOverlay={p => renderProfileMenu(p, user)}>
            {(isOpen, toggle, measureRef) => (
              <HeaderButton active={isOpen} myInnerRef={measureRef} onClick={toggle}>
                <Ui.Icon.CaretDown />
              </HeaderButton>
            )}
          </DropDown>
        </Ui.Row>
      ) : (
        <Ui.Row css={{alignItems: "center"}}>
          <Ui.RaisedButton
            component={ModalLink}
            modal="login"
            size="small"
            css={{marginRight: "10px"}}
          >
            log in
          </Ui.RaisedButton>
          <Ui.RaisedButton white component={ModalLink} modal="register" size="small">
            register
          </Ui.RaisedButton>
        </Ui.Row>
      )
    }
  </GetMe>
);

const HeaderComp = styled("div")({
  display: "flex",
  backgroundColor: colors.brandDarkest,
  color: colors.white,
  position: "relative",
  minHeight: 45,
  boxShadow: "0 3px rgba(0,0,0,0.3)",
  zIndex: 3,
});

const LinkList = styled("div")({
  display: "flex",
  flexWrap: "wrap",
  position: "relative",
});

const StyledLink = styled(Link, {shouldForwardProp: p => p !== "isLogo"})(
  sharedHeaderLinkStyle,
  ({isLogo}) =>
    isLogo
      ? {}
      : {
          "@media(max-width: 550px)": {
            width: "50%",
            textAlign: "center",
            display: "block",
            fontSize: 12,
            padding: "10px 5px",
            boxShadow: "none",
          },
        }
);

const HeaderLink = ({to, exact, ...rest}) => (
  <Route path={to} exact={exact}>
    {({match}) => <StyledLink to={to} match={match} {...rest} />}
  </Route>
);

const RightPart = styled("div")({
  display: "flex",
  marginLeft: "auto",
  flex: "none",
  marginRight: 25,
  "@media(max-width: 450px)": {marginRight: 5},
});

const Header = () => (
  <HeaderComp>
    <HeaderLink to="/" exact margin="0 15px" isLogo>
      <SVG height="20px" name="logo" css={{display: "block", color: "#fff"}} />
    </HeaderLink>
    <LinkList>
      <HeaderLink to="/draw">Draw</HeaderLink>
      <HeaderLink to="/gallery">Gallery</HeaderLink>
      <HeaderLink to="/contest">Contest</HeaderLink>
      <HeaderLink to="/forum">Forum</HeaderLink>
    </LinkList>
    <RightPart>
      <AuthSection />
    </RightPart>
  </HeaderComp>
);

const MessageContainer = styled("div")({
  backgroundColor: colors.warning,
  padding: "10px 20px",
  fontSize: 14,
  textAlign: "center",
  color: colors.fade(colors.white, 0.9),
});

const Messages = () => (
  <GetMe>
    {user =>
      user
        ? user.createdAt.getTime() + 15 * 60 * 1000 < new Date().getTime() &&
          !user.primaryEmail.isVerified && (
            <MessageContainer>
              Please verify your email '{user.primaryEmail.email}' to use all of piq's features.
              <Toggler>
                {(isSent, toggle) => (
                  <div css={{marginLeft: 10, display: "inline-block"}}>
                    {isSent ? (
                      "Mail Sent"
                    ) : (
                      <React.Fragment>
                        <DoAction
                          name="users.resendVerificationEmail"
                          data={{userId: user.id, id: user.primaryEmail.id}}
                          onSuccess={toggle}
                        >
                          {({performFn, isLoading}) => (
                            <Ui.WhiteMiniActionButton disabled={isLoading} onClick={performFn}>
                              Resend verification mail
                            </Ui.WhiteMiniActionButton>
                          )}
                        </DoAction>{" "}
                        or{" "}
                        <Ui.WhiteMiniActionButton to="/settings/email">
                          Manage emails
                        </Ui.WhiteMiniActionButton>
                      </React.Fragment>
                    )}
                  </div>
                )}
              </Toggler>
            </MessageContainer>
          )
        : null
    }
  </GetMe>
);

const Body = styled("div")(({white, dark}) => ({
  flex: "auto",
  display: "flex",
  position: "relative",
  transitionProperty: "background-color",
  ...(white && {backgroundColor: colors.white, color: colors.dark}),
  ...(dark && {backgroundColor: colors.dark, color: colors.fade(colors.white, 0.8)}),
  ...(!white &&
    !dark && {
      backgroundColor: colors.brandBg,
      color: colors.fade(colors.white, 0.8),
    }),
}));

const Content = styled("div")(Ui.common.stretchCol, {
  minWidth: 1,
  position: "relative",
});

const FooterComp = styled("div")({
  display: "flex",
  alignItems: "center",
  padding: "15px 25px",
  backgroundColor: colors.paleWhite,
  color: colors.midGray,
  fontSize: 10,
  boxShadow: "inset 0 15px 15px -15px rgba(0,0,0,0.3)",
  position: "relative",
  zIndex: 1,
});

const FooterLink = styled(Link)(Ui.common.headerFont, {
  textTransform: "uppercase",
  marginRight: 10,
  marginBottom: 10,
  transitionProperty: "color",
  ":hover": {color: colors.dark},
});

const Footer = () => (
  <FooterComp>
    <SVG height="20px" name="logo" css={{marginRight: 20}} />
    <Ui.Row
      css={{
        marginLeft: "auto",
        flexWrap: "wrap",
        marginRight: -10,
        marginBottom: -10,
        justifyContent: "flex-end",
      }}
    >
      <FooterLink to="/news">News</FooterLink>
      <FooterLink to="/about">About</FooterLink>
      <FooterLink to="/tos">Terms of Service</FooterLink>
      <FooterLink to="/imprint">Imprint & Privacy</FooterLink>
    </Ui.Row>
  </FooterComp>
);

export default class AppShell extends React.Component {
  state = {ctxProps: {}};

  handleSetProps = ctxProps => this.setState({ctxProps});

  render() {
    const {children, onClick} = this.props;
    const {white, dark, closedFeed} = this.state.ctxProps;
    return (
      <AppShellContext.Provider value={{setProps: this.handleSetProps}}>
        <Ui.Col css={{minHeight: "100vh", overflowX: "hidden"}} onClick={onClick}>
          <Messages />
          <Header />
          <Body white={white} dark={dark}>
            <Content>{children}</Content>
            <Feed closed={closedFeed} />
          </Body>
          <Footer />
        </Ui.Col>
      </AppShellContext.Provider>
    );
  }
}
// const AppShell = ({children, white, dark, padding, closedFeed, onClick}) => (
//   <Ui.Col css={{minHeight: "100vh"}} onClick={onClick}>
//     <Messages />
//     <Header />
//     <Body white={white} dark={dark}>
//       <Content css={{padding}}>{children}</Content>
//       <Feed closed={closedFeed} />
//     </Body>
//   </Ui.Col>
// );
