import React from "react";
import {Motion, spring} from "react-motion";

export const InterpolateIfActive = ({
  isActive,
  children,
  springConfig = {stiffness: 90, damping: 15},
  noDefaultStyle,
}) => (
  <Motion
    defaultStyle={noDefaultStyle ? undefined : {val: 0}}
    style={{val: spring(isActive ? 1 : 0, springConfig)}}
  >
    {({val}) => children(val)}
  </Motion>
);

export class HighlightIfActive extends React.Component {
  constructor(props) {
    super(props);
    this.state = {active: props.isActive};
    this.timeoutId = null;
    if (props.isActive) this.setTimeout(props);
  }

  componentWillReceiveProps(nextProps) {
    if (nextProps.isActive && !this.props.isActive) {
      this.setState({active: true});
      if (this.timeoutId) clearTimeout(this.timeoutId);
      this.setTimeout(nextProps);
    }
  }

  componentWillUnmount() {
    if (this.timeoutId) clearTimeout(this.timeoutId);
  }

  setTimeout(props) {
    this.timeoutId = setTimeout(() => {
      this.setState({active: false});
      this.timeoutId = null;
    }, props.activeMs);
  }

  render() {
    return this.props.children(this.state.active);
  }
}
