export default function createCollector(fetcher, addFn, onLoad, descriptions) {
  let timeoutStarted = false;
  let toBeCollected = {};
  const inflight = {};
  let fetchCounter = 0;

  let aggrInflight = {};

  function updateAggrInflight() {
    aggrInflight = {};

    function mergeInto(query, dict) {
      Object.keys(query).forEach(modelName => {
        const entry = query[modelName];
        const alreadyThere = dict[modelName];
        if (!alreadyThere) {
          dict[modelName] = entry;
        } else {
          alreadyThere.fields = {...alreadyThere.fields, ...entry.fields};
          mergeInto(entry.children, alreadyThere.children);
        }
      });
    }

    Object.keys(inflight).forEach(key => {
      mergeInto(inflight[key], aggrInflight);
    });
  }

  function fetched(error, counter, ...args) {
    delete inflight[counter];
    updateAggrInflight();
    if (!error) addFn(...args);
  }

  function initiateFetch() {
    function addToQuery(data, query) {
      Object.keys(data).forEach(key => {
        const val = data[key];
        query[key] = Object.keys(val.fields);
        if (Object.keys(val.children).length) {
          query[key].push(addToQuery(val.children, {}));
        }
      });
      return query;
    }

    const query = addToQuery(toBeCollected, {});

    const fetchCount = fetchCounter;
    fetchCounter += 1;

    inflight[fetchCount] = toBeCollected;
    updateAggrInflight();
    toBeCollected = {};
    timeoutStarted = false;

    fetcher(query, (error, ...args) => fetched(error, fetchCount, ...args));
  }

  function isInflight(path, name, type) {
    let currentLevel = aggrInflight;
    const notPresent = path.some((entry, index) => {
      if (!currentLevel[entry]) return true;
      currentLevel = index + 1 === path.length ? currentLevel[entry] : currentLevel[entry].children;
      return false;
    });
    if (notPresent) {
      return false;
    } else {
      switch (type) {
        case "hasMany":
          return currentLevel.children[name];
        case "field":
          return currentLevel.fields[name];
        default:
          throw new Error(`unknown type '${type}'`);
      }
    }
  }

  return {
    isCurrentlyLoading() {
      return timeoutStarted || Object.keys(inflight).length > 0;
    },
    collect(path, name, type) {
      onLoad();
      if (process.env.NODE_ENV === "development") {
        const modelName = path.reduce((lastModel, pathPart) => {
          const thisModelName = (pathPart.modelName || pathPart).match(
            /^(?:(?:last|first)(?:N\d+)?:)?(\w+)\(?/
          )[1];
          if (!lastModel) return thisModelName;
          const desc = descriptions[lastModel];
          return (desc.hasMany[thisModelName] || desc.belongsTo[thisModelName]).model;
        }, null);
        const [, qualifier, pureName] = name.match(
          /^(?:(last|first|exists|count)(?:N\d+)?:)?(\w+)\(?/
        ); // turn 'undismissedResolvables(project:1)' into 'undismissedResolvables'
        const desc = descriptions[modelName];
        const isRelation = type === "hasMany" || ["exists", "count"].includes(qualifier);
        if (isRelation && !desc.hasMany[pureName] && !desc.belongsTo[pureName]) {
          throw new Error(
            `${modelName} doesn't have ${pureName} relation. I can only offer: ${[
              ...Object.keys(desc.hasMany),
              ...Object.keys(desc.belongsTo),
            ].join(", ")}`
          );
        }
        if (!isRelation && !desc.fields[pureName]) {
          throw new Error(
            `${modelName} doesn't have ${pureName} field. I can only offer: ${Object.keys(
              desc.fields
            ).join(", ")}`
          );
        }
      }
      if (path.length && path[0].modelName) {
        if (process.env.NODE_ENV === "development") {
          if (path[0].modelName !== "_root" && (!path[0].id || path[0].id === "unknown")) {
            throw new Error(
              `You're not supposed to collect data from an unknown root! ${JSON.stringify(path)}`
            );
          }
        }
        path[0] =
          path[0].modelName === "_root" ? path[0].modelName : `${path[0].modelName}(${path[0].id})`;
      }

      if (isInflight(path, name, type)) {
        // console.log(path, name, type, "is inflight!");
        return;
      }

      let currentLevel = toBeCollected;
      path.forEach((entry, index) => {
        currentLevel[entry] = currentLevel[entry] || {fields: {}, children: {}};
        currentLevel =
          index + 1 === path.length ? currentLevel[entry] : currentLevel[entry].children;
      });

      switch (type) {
        case "hasMany":
          currentLevel.children[name] = currentLevel.children[name] || {fields: {}, children: {}};
          break;
        case "field":
          currentLevel.fields[name] = true;
          break;
        default:
          throw new Error(`unknown type '${type}'`);
      }

      if (!timeoutStarted) {
        setTimeout(initiateFetch);
        timeoutStarted = true;
      }
    },
  };
}
