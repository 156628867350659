import {createFetch, base, accept, parse} from "http-client";
import {getToken} from "./auth";

const setHeader = (options, name, value) => {
  (options.headers || (options.headers = {}))[name] = value;
};

const authHeader = (fetch, input, options = {}) => {
  const authToken = getToken();
  if (authToken) {
    setHeader(options, "X-Auth-Token", authToken);
  }
  return fetch(input, options);
};

const contentTypeJsonIfPost = (fetch, input, options = {}) => {
  if (options.method === "post") {
    setHeader(options, "Content-Type", "application/json");
  }
  return fetch(input, options);
};

export default createFetch(
  base(process.env.REACT_APP_API_HOST),
  accept("application/json"),
  authHeader,
  contentTypeJsonIfPost,
  parse("json", "jsonData")
);
