let hasSS = false;
try {
  hasSS = typeof sessionStorage !== "undefined";
} catch (e) {}

let hasLS = false;
try {
  hasLS = typeof localStorage !== "undefined";
} catch (e) {}

const ssGet = key => {
  try {
    return sessionStorage.getItem(key);
  } catch (e) {
    return null;
  }
};
const lsGet = key => {
  try {
    return localStorage.getItem(key);
  } catch (e) {
    return null;
  }
};

const ssSet = (key, val) => {
  try {
    sessionStorage.setItem(key, val);
    return true;
  } catch (e) {
    return false;
  }
};

const lsSet = (key, val) => {
  try {
    localStorage.setItem(key, val);
    return true;
  } catch (e) {
    return false;
  }
};

const ssRemove = key => {
  try {
    sessionStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};
const lsRemove = key => {
  try {
    localStorage.removeItem(key);
    return true;
  } catch (e) {
    return false;
  }
};

const parseJson = str => {
  try {
    return JSON.parse(str);
  } catch (e) {
    return null;
  }
};

const asSSMap = prefix => ({
  get(key) {
    if (!hasSS) return null;
    const str = ssGet(`${prefix}-${key}`);
    return str && parseJson(str);
  },
  set(key, val) {
    if (!hasSS) return null;
    return ssSet(`${prefix}-${key}`, JSON.stringify(val));
  },
  remove(key) {
    if (!hasSS) return null;
    return ssRemove(`${prefix}-${key}`);
  },
});

const asLSVal = key => ({
  get() {
    if (!hasLS) return null;
    const str = lsGet(key);
    return str && parseJson(str);
  },
  set(val) {
    if (!hasLS) return null;
    return lsSet(key, JSON.stringify(val));
  },
  remove() {
    if (!hasLS) return null;
    return lsRemove(key);
  },
});

const asSSVal = key => ({
  get() {
    if (!hasSS) return null;
    const str = ssGet(key);
    return str && parseJson(str);
  },
  set(val) {
    if (!hasSS) return null;
    return ssSet(key, JSON.stringify(val));
  },
  remove() {
    if (!hasSS) return null;
    return ssRemove(key);
  },
});

export const formStateStorage = asSSMap("FORMSTATE");
export const accessPwStorage = asLSVal("ACCESS_PW");
export const piqClaimStorage = asLSVal("piqClaims");
export const feedClosedStorage = asSSVal("feedClosed");
export const adsStorage = asLSVal("banner");
export const userSessionStorage = asSSVal("session");
