import React from "react";
import {getScrollParent} from "../lib/scroll";
import debounce from "lodash/debounce";

export default class IfVisible extends React.Component {
  componentDidMount() {
    window.addEventListener("resize", this.handleCheck);
  }

  componentWillUnmount() {
    this.handleCheck.cancel();
    window.removeEventListener("resize", this.handleCheck);
  }

  handleRegisterNode = n => {
    this.node = n;
    if (n) {
      this.scrollPane = getScrollParent(n);
      this.scrollPane.addEventListener("scroll", this.handleCheck);
      this.handleCheck();
    } else {
      this.scrollPane.removeEventListener("scroll", this.handleCheck);
    }
  };

  handleCheck = debounce(
    () => {
      if (!this.node) return;
      const {top, bottom} = this.node.getBoundingClientRect();
      if (top >= 0 && bottom <= document.documentElement.clientHeight * 1.1) {
        this.props.onVisible();
      }
    },
    50,
    {maxWait: 1000}
  );

  render() {
    return this.props.children ? (
      this.props.children(this.handleRegisterNode)
    ) : (
      <div ref={this.handleRegisterNode} />
    );
  }
}

export class RenderIfVisible extends React.Component {
  state = {isVisible: false};

  componentDidMount() {
    window.addEventListener("resize", this.handleCheck);
  }

  componentWillUnmount() {
    this.handleCheck.cancel();
    window.removeEventListener("resize", this.handleCheck);
  }

  handleVisibleNode = n => {
    this.node = n;
    if (n) {
      this.scrollPane = getScrollParent(n);
      this.scrollPane.addEventListener("scroll", this.handleCheck);
      this.handleCheck();
    } else {
      this.scrollPane.removeEventListener("scroll", this.handleCheck);
      window.addEventListener("resize", this.handleCheck);
      this.handleCheck.cancel();
    }
  };

  componentWillReceiveProps(nextProps) {
    if (this.props.disableCheck && !nextProps.disableCheck) {
      if (!this.state.isVisible) this.handleCheck();
    }
  }

  handleCheck = debounce(
    () => {
      if (!this.node) return;
      if (this.props.disableCheck) return;
      const {top, bottom} = this.node.getBoundingClientRect();
      if (top >= 0 && bottom <= document.documentElement.clientHeight * 1.1) {
        this.setState({isVisible: true});
      }
    },
    50,
    {maxWait: 200}
  );

  render() {
    return this.state.isVisible ? this.props.children() : <div ref={this.handleVisibleNode} />;
  }
}
