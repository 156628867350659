import MiniEvent from "./mini-event";
import {piqClaimStorage} from "./storage";

const events = new MiniEvent();

export function addClaim(token, pictureId) {
  const claims = {...getClaims(), [pictureId]: token};
  piqClaimStorage.set(claims);
  events.emit();
}

export function getClaims() {
  return piqClaimStorage.get() || {};
}

export function removeClaim(pictureId) {
  const claims = getClaims();
  delete claims[pictureId];
  piqClaimStorage.set(claims);
  events.emit();
}

export const onChangeClaims = cb => events.addListener(cb);
