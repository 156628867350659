const getIdAsKey = (id, modelName) => {
  if (modelName === "$raw") {
    // here id has this shape: {assigneeId: 4, date: xxx}
    // we transform the idAsKey to '4-xxx' in this case
    return (id.$id =
      id.$id ||
      Object.keys(id)
        .map(f => (id[f] === null ? "null" : id[f].toString()))
        .join("|"));
  } else {
    return Array.isArray(id) ? id.join("|") : id;
  }
};

export default function createPool({
  cache,
  descriptions,
  collect: rawCollect,
  registerOnChangeListener,
  getApiChangeIndex,
  onCacheModified,
}) {
  let loadedInstances = {};
  let instancesWithoutKnownId = {};
  let rootInstance = null;
  let references = {};

  const createQueryStringFromQuery = (modelDesc, query, relName) => {
    const sortedQueryKeys = Object.keys(query).sort();
    const hasManyDesc = modelDesc.hasMany[relName];
    const hasManyModelDesc =
      hasManyDesc.model === "$raw" ? {idPropAsArray: []} : descriptions[hasManyDesc.model];
    const isSingleton =
      hasManyDesc.isSingleton ||
      (hasManyDesc.model !== "$raw" &&
        hasManyModelDesc.idPropAsArray.every(
          idProp => query[idProp] && !Array.isArray(query[idProp])
        ));

    const allConstraintsLoaded = !sortedQueryKeys.some(key => {
      const entry = query[key];
      if (entry === undefined || entry === null)
        throw new Error(
          `please pass a non-empty constraint for ${modelDesc.model} ${relName}'s [${key}]`
        );
      if (entry.$meta && !entry.$meta.isLoaded) {
        if (entry.id === null) {
          console.warn(
            `trying to load ${key} for as ${relName} which seems to be a deleted/inaccessible source.`
          );
          return false;
        } else {
          return true;
        }
      }
      return false;
    });

    const queryString = allConstraintsLoaded
      ? sortedQueryKeys
          .map(key => {
            let entry = query[key];
            if (entry.$meta) entry = entry.id;
            if (Array.isArray(entry)) {
              entry = `[${entry.join(";")}]`;
            } else if (entry instanceof Date) {
              entry = entry.toISOString();
            } else if (typeof entry === "object") {
              throw new Error(
                `constraint for ${modelDesc.model}'s ${relName} should be no object!`
              );
            }
            return key === hasManyModelDesc.idProp ? entry : `${key}:${entry}`;
          })
          .join(",")
      : null;

    return {
      isSingleton,
      allConstraintsLoaded,
      queryString,
    };
  };

  const createMetaMethods = (getRelation, getField, modelDesc) => {
    const methods = {
      find(relName, query) {
        const {isSingleton, allConstraintsLoaded, queryString} = createQueryStringFromQuery(
          modelDesc,
          query,
          relName
        );
        if (!allConstraintsLoaded) return isSingleton ? null : [];
        return getRelation(
          `${relName}(${queryString})`,
          isSingleton,
          modelDesc.hasMany[relName].model
        ).get();
      },
    };
    [{name: "exists", defaultValue: false}, {name: "count", defaultValue: 0}].forEach(
      ({name, defaultValue}) => {
        methods[name] = (relName, query = null, userDefault) => {
          if (query && Object.keys(query).length) {
            const {queryString, allConstraintsLoaded} = createQueryStringFromQuery(
              modelDesc,
              query,
              relName
            );
            if (!allConstraintsLoaded)
              return userDefault !== undefined ? userDefault : defaultValue;
            return getField(
              `${name}:${relName}(${queryString})`,
              userDefault !== undefined ? userDefault : defaultValue
            );
          } else {
            return getField(
              `${name}:${relName}`,
              userDefault !== undefined ? userDefault : defaultValue
            );
          }
        };
      }
    );
    [{name: "last"}, {name: "first"}].forEach(({name}) => {
      methods[name] = (relName, query = null) => {
        if (query && Object.keys(query).length) {
          const {queryString, allConstraintsLoaded} = createQueryStringFromQuery(
            modelDesc,
            query,
            relName
          );
          if (!allConstraintsLoaded) return null;
          return getRelation(
            `${name}:${relName}(${queryString})`,
            true,
            modelDesc.hasMany[relName].model
          ).get();
        } else {
          return getRelation(`${name}:${relName}`, true, modelDesc.hasMany[relName].model).get();
        }
      };
    });
    [{name: "lastN"}, {name: "firstN"}].forEach(({name}) => {
      methods[name] = (n, relName, query = null) => {
        if (query && Object.keys(query).length) {
          const {queryString, allConstraintsLoaded} = createQueryStringFromQuery(
            modelDesc,
            query,
            relName
          );
          if (!allConstraintsLoaded) return null;
          return getRelation(
            `${name}${n}:${relName}(${queryString})`,
            false,
            modelDesc.hasMany[relName].model
          ).get();
        } else {
          return getRelation(
            `${name}${n}:${relName}`,
            false,
            modelDesc.hasMany[relName].model
          ).get();
        }
      };
    });
    methods.idProps = modelDesc.idPropAsArray;

    return methods;
  };

  const pool = {
    clearPool() {
      loadedInstances = {};
      instancesWithoutKnownId = {};
      rootInstance = null;
      references = {};
    },
    invalidateInstances(initialChanges) {
      if (initialChanges === "all") {
        pool.clearPool();
      } else {
        const invalidated = [];
        const alreadyUpdated = new Set();
        let nextChanges = initialChanges;
        let hasMoreChanges = true;
        rootInstance = null;
        instancesWithoutKnownId = {};
        const update = changes => {
          Object.keys(changes).forEach(modelName => {
            if (modelName !== "_root") {
              changes[modelName].forEach(id => {
                const idAsKey = getIdAsKey(id, modelName);
                const key = `${modelName}:${idAsKey}`;
                if (alreadyUpdated.has(key)) return;
                alreadyUpdated.add(key);
                if (loadedInstances[modelName]) {
                  if (process.env.NODE_ENV === "development" && loadedInstances[modelName][idAsKey])
                    invalidated.push(key);
                  delete loadedInstances[modelName][idAsKey];
                }
                const refs = references[key];
                if (refs) {
                  refs.forEach(([relModel, relId]) => {
                    hasMoreChanges = true;
                    (nextChanges[relModel] = nextChanges[relModel] || new Set()).add(relId);
                  });
                }
              });
            }
          });
        };
        while (hasMoreChanges) {
          const currentChanges = nextChanges;
          nextChanges = {};
          hasMoreChanges = false;
          update(currentChanges);
        }
        if (process.env.NODE_ENV === "development")
          console.log(`invalidated [${invalidated.join(", ")}]`);
      }
    },
    getRoot() {
      if (rootInstance) return rootInstance;
      rootInstance = pool.createInstanceViaId({modelName: "_root", id: null});
      return rootInstance;
    },
    createInstanceViaId({modelName, id}) {
      if (modelName === "$raw") {
        const instance = {$meta: {isLoaded: true}};
        Object.keys(id).forEach(key => {
          instance[key] = id[key];
        });
        return instance;
      }
      if (cache.isDeleted(modelName, id)) return null;
      const {fields, idProp, hasMany, belongsTo, idPropAsArray} = descriptions[modelName];
      const path = [{modelName, id}];

      const instance = {};
      const props = {};
      const cachedRelations = {};

      const collect = (cPath, field, type) => {
        // if (instance.$meta.changeIndex !== getApiChangeIndex()) {
        //   console.error(`Requesting "${field}" from stale "${modelName}(${id})" instance. Forbidden. (instance change index: ${instance.$meta.changeIndex} vs current: ${getApiChangeIndex()})`);
        // }
        return rawCollect(cPath, field, type);
      };

      Object.keys(fields).forEach(field => {
        if (cache.has(modelName, id, field)) {
          instance[field] = cache.get(modelName, id, field);
        } else if (idPropAsArray.length === 1 && field === idPropAsArray[0]) {
          // TODO: consider composite ids
          instance[field] = id;
        } else {
          const fieldDesc = fields[field];
          props[field] = {
            enumerable: true,
            get() {
              collect(path, field === "id" ? idProp : field, "field");
              return fieldDesc.defaultValue;
            },
          };
        }
      });

      if (idPropAsArray.length === 1 && idProp !== "id") instance.id = instance[idProp];

      const getRelation = (relName, isSingleton, relModel) => {
        let getter;
        if (cache.has(modelName, id, relName)) {
          const thisInstanceKey = [modelName, id];
          if (isSingleton) {
            getter = () => {
              const relId = cache.get(modelName, id, relName);
              const otherInstanceKey = `${relModel}:${getIdAsKey(relId, relModel)}`;
              if (modelName !== "_root")
                (references[otherInstanceKey] = references[otherInstanceKey] || []).push(
                  thisInstanceKey
                );
              const retVal = relId && pool.getInstanceViaId({modelName: relModel, id: relId});
              return retVal;
            };
          } else {
            getter = () => {
              const relIds = cache.get(modelName, id, relName);
              return relIds
                .map(relId => {
                  const otherInstanceKey = `${relModel}:${getIdAsKey(relId, relModel)}`;
                  // references["card-123"] = [[project, 4], [deck, 12]]
                  // TODO: ensure that it won't turn into 'references["card-123"] = [[project, 4], [project, 4], [project, 4]]''
                  if (modelName !== "_root")
                    (references[otherInstanceKey] = references[otherInstanceKey] || []).push(
                      thisInstanceKey
                    );
                  return pool.getInstanceViaId({modelName: relModel, id: relId});
                })
                .filter(Boolean);
            };
          }
        } else {
          // not cached
          getter = () => {
            collect(path, relName, "hasMany");
            const relInstance = pool.getInstanceViaPath({
              modelName: relModel,
              path: [...path, relName],
            });
            return isSingleton ? relInstance : [relInstance];
          };
        }
        return {
          enumerable: false,
          get: () => {
            // memoize it to ensure that the same instance *always* returns the same data
            if (!(relName in cachedRelations)) cachedRelations[relName] = getter();
            return cachedRelations[relName];
          },
        };
      };

      Object.keys(hasMany).forEach(relName => {
        const {isSingleton, model} = hasMany[relName];
        props[relName] = getRelation(relName, isSingleton, model);
      });

      Object.keys(belongsTo).forEach(relName => {
        const {model} = belongsTo[relName];
        props[relName] = getRelation(relName, true, model);
      });

      if (Object.keys(props).length) Object.defineProperties(instance, props);

      const getField = (fieldName, defaultValue) => {
        const existing = cachedRelations[fieldName];
        if (existing !== undefined) return existing;
        if (cache.has(modelName, id, fieldName)) {
          const val = cache.get(modelName, id, fieldName);
          cachedRelations[fieldName] = val;
          return val;
        } else {
          collect(path, fieldName, "field");
          return defaultValue;
        }
      };

      instance.$meta = {
        isLoaded: true,
        modelName,
        changeIndex: getApiChangeIndex(),
        isFieldLoaded(fieldName, collectIfNotPresent) {
          // returns false if not loaded or invalid
          const isPresent = cache.hasValid(modelName, id, fieldName);
          if (!isPresent && collectIfNotPresent) collect(path, fieldName, "field");
          return isPresent;
        },
        isFieldPresent(fieldName, collectIfNotPresent) {
          // returns false if not loaded
          const isPresent = cache.has(modelName, id, fieldName);
          if (!isPresent && collectIfNotPresent) collect(path, fieldName, "field");
          return isPresent;
        },
        get(fieldName, defaultValue) {
          if (fields[fieldName]) return getField(fieldName, defaultValue);
          const val = instance[fieldName];
          return val && !val.$meta.isLoaded ? defaultValue : val;
        },
        isDeleted() {
          return cache.isDeleted(modelName, id);
        },
        isFieldDeleted(fieldName) {
          if (!cache.has(modelName, id, fieldName)) return false;
          return cache.isDeleted(belongsTo[fieldName].model, cache.get(modelName, id, fieldName));
        },
        ensure(...args) {
          let unsubscribeListener = null;
          const cb = args[args.length - 1];
          const ensureFields = args.slice(0, -1).map(f =>
            belongsTo[f]
              ? {
                  field: f,
                  fk: belongsTo[f].fk,
                  modelName: belongsTo[f].model,
                }
              : {
                  field: f,
                  fk: null,
                  modelName: null,
                }
          );

          const callIfAllAvailable = () => {
            if (ensureFields.every(f => cache.hasValid(modelName, id, f.field))) {
              cb(
                ...ensureFields.map(f =>
                  f.modelName
                    ? pool.getInstanceViaId({
                        modelName: f.modelName,
                        id: cache.get(modelName, id, f.field),
                      })
                    : cache.get(modelName, id, f.field)
                )
              );
              if (unsubscribeListener) unsubscribeListener();
              return true;
            } else {
              ensureFields.forEach(f => {
                if (!cache.hasValid(modelName, id, f.field)) {
                  collect(path, f.field, f.modelName || hasMany[f.field] ? "hasMany" : "field");
                }
              });
              return false;
            }
          };

          if (!callIfAllAvailable()) {
            unsubscribeListener = registerOnChangeListener(callIfAllAvailable);
          }
        },
        ...createMetaMethods(getRelation, getField, descriptions[modelName]),
      };

      return instance;
    },

    getInstanceViaId({modelName, id}) {
      loadedInstances[modelName] = loadedInstances[modelName] || {};
      const idAsKey = getIdAsKey(id, modelName);
      if (loadedInstances[modelName][idAsKey]) return loadedInstances[modelName][idAsKey];

      const newInstance = pool.createInstanceViaId({modelName, id});
      loadedInstances[modelName][idAsKey] = newInstance;
      return newInstance;
    },

    createInstanceViaPath({modelName, path}) {
      if (modelName === "$raw") return {$meta: {isLoaded: false}};
      const {fields, idProp, hasMany, belongsTo, idPropAsArray} = descriptions[modelName];

      const instance = {};
      const props = {};
      const collected = new Set();

      const collect = (cPath, field, type) => {
        if (collected.has(field)) return;
        collected.add(field);
        rawCollect(cPath, field, type);
      };

      Object.keys(fields).forEach(field => {
        const fieldDesc = fields[field];
        props[field] = {
          enumerable: true,
          get() {
            collect(path, field, "field");
            return fieldDesc.defaultValue;
          },
        };
      });

      if (idPropAsArray.length === 1 && idProp !== "id") {
        props.id = {
          enumerable: true,
          get() {
            collect(path, idProp, "field");
            return fields[idProp].defaultValue;
          },
        };
      }

      const getRelation = (relName, isSingleton, relModel) => ({
        enumerable: false,
        get() {
          collect(path, relName, "hasMany");
          const relInstance = pool.getInstanceViaPath({
            modelName: relModel,
            path: [...path, relName],
          });
          return isSingleton ? relInstance : [relInstance];
        },
      });

      Object.keys(hasMany).forEach(relName => {
        const {isSingleton, model} = hasMany[relName];
        props[relName] = getRelation(relName, isSingleton, model);
      });

      Object.keys(belongsTo).forEach(relName => {
        const {model} = belongsTo[relName];
        props[relName] = getRelation(relName, true, model);
      });

      const getField = (fieldName, defaultValue) => {
        collect(path, fieldName, "field");
        return defaultValue;
      };

      instance.$meta = {
        isLoaded: false,
        modelName,
        changeIndex: getApiChangeIndex(),
        isFieldLoaded(fieldName, collectIfNotPresent) {
          if (collectIfNotPresent) collect(path, fieldName, "field");
          return false;
        },
        isFieldPresent(fieldName, collectIfNotPresent) {
          if (collectIfNotPresent) collect(path, fieldName, "field");
          return false;
        },
        get(fieldName, defaultValue) {
          if (fields[fieldName]) return getField(fieldName, defaultValue);
          collect(path, fieldName, "hasMany");
          return defaultValue;
        },
        isDeleted() {
          return false;
        },
        isFieldDeleted() {
          return false;
        },
        ensure() {
          throw new Error(`Don't know what todo if I don't know the id of this '${modelName}'!`);
        },
        ...createMetaMethods(getRelation, getField, descriptions[modelName]),
      };

      Object.defineProperties(instance, props);

      return instance;
    },

    getInstanceViaPath({modelName, path}) {
      instancesWithoutKnownId[modelName] = instancesWithoutKnownId[modelName] || {};

      const pathAsKey = path
        .reduce((m, p) => {
          switch (p.modpName) {
            case undefined:
              m.push(p);
              break;
            case "_root":
              m.push(p.modelName);
              break;
            default:
              m.push(`${p.modelName}:${p.id}`);
              break;
          }
          return m;
        }, [])
        .join("|");

      if (instancesWithoutKnownId[modelName][pathAsKey])
        return instancesWithoutKnownId[modelName][pathAsKey];

      const newInstance = pool.createInstanceViaPath({modelName, path});
      instancesWithoutKnownId[modelName][pathAsKey] = newInstance;
      return newInstance;
    },
  };

  onCacheModified(pool.invalidateInstances);

  return pool;
}
