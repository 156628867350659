import React from "react";

const AppShellContext = React.createContext({setProps: () => {}});

export default AppShellContext;

class InnerSetAppShellProps extends React.Component {
  componentDidMount() {
    const {setProps, props} = this.props;
    setProps(props);
  }

  render() {
    return this.props.children;
  }
}

export const SetAppShellProps = ({children, ...rest}) => (
  <AppShellContext.Consumer>
    {({setProps}) => (
      <InnerSetAppShellProps setProps={setProps} props={rest}>
        {children}
      </InnerSetAppShellProps>
    )}
  </AppShellContext.Consumer>
);
