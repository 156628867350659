import * as Button from "./Button";
import common from "./common";
import Arrow from "./Arrow";
import Icon from "./Icon";
import * as basics from "./basics";
import {keyframes} from "react-emotion";

const animations = {
  rotate: keyframes`
    from {transform: rotate(0deg)}
    to {transform: rotate(360deg)}
  `,
};

export default {
  common,
  animations,
  Arrow,
  PlainButton: Button.Plain,
  RaisedButton: Button.Raised,
  MiniActionButton: Button.MiniAction,
  PaleMiniActionButton: Button.PaleMiniAction,
  WhiteMiniActionButton: Button.WhiteMiniAction,
  Icon,
  ...basics,
};
