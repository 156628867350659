import React from "react";
import {Motion, spring} from "react-motion";
import Portal from "./Portal";
import Ui from "./ui";
import ClickOutside from "./ClickOutside";
import SVG from "./SVG";
import styled, {injectGlobal} from "react-emotion";
import colors from "../lib/colors";

injectGlobal`body.with-modal {overflow: "hidden"}`;

const Backdrop = styled("div")(Ui.common.fixed, {
  backgroundColor: "rgba(100, 100, 100, 0.97)",
});
const ModalOuterContainer = styled("div")(Ui.common.fixed);
const ModalContentContainer = styled("div")(Ui.common.full, {
  bottom: "auto",
  minHeight: "100%",
  display: "flex",
  flexDirection: "column",
  padding: "100px 50px",

  "@media(max-width: 600px)": {
    padding: "50px 20px",
  },
});
const ModalContent = styled("div")({
  position: "relative",
  margin: "auto",
  width: "100%",
  maxWidth: "800px",
});

let modalCount = 0;

export class Modal extends React.Component {
  constructor(props) {
    super(props);
    if (modalCount === 0) if (document.body.classList) document.body.classList.add("with-modal");
    modalCount += 1;
    this.layerNum = modalCount;
  }

  componentWillUnmount() {
    modalCount -= 1;
    if (modalCount === 0) if (document.body.classList) document.body.classList.remove("with-modal");
  }

  render() {
    const {progress, children, onClose} = this.props;
    const withProgress = progress !== undefined && progress !== 1;
    return (
      <React.Fragment>
        <Backdrop
          style={withProgress ? {opacity: progress} : null}
          onClick={onClose}
          css={{zIndex: 20 + 2 * this.layerNum}}
        />
        <ModalOuterContainer
          css={{zIndex: 21 + 2 * this.layerNum}}
          style={{
            opacity: Math.max(0, Math.min(1, progress)),
            overflowY: progress !== 0 ? "auto" : "hidden",
          }}
        >
          <ModalContentContainer style={{transform: `translateY(${-50 * (1 - progress)}px)`}}>
            <ModalContent>
              <ClickOutside onClick={onClose}>{children}</ClickOutside>
            </ModalContent>
          </ModalContentContainer>
        </ModalOuterContainer>
      </React.Fragment>
    );
  }
}

export class ModalSpawner extends React.Component {
  render() {
    const {isOpen, children, onClose, renderOverlay} = this.props;
    return (
      <Motion style={{val: spring(isOpen ? 1 : 0, {stiffness: 300, damping: 20})}}>
        {({val}) => (
          <Portal renderInPlace={children}>
            {val !== 0 && (
              <Modal progress={val} onClose={onClose}>
                {(this.lastContent = isOpen ? renderOverlay(val) : this.lastContent)}
              </Modal>
            )}
          </Portal>
        )}
      </Motion>
    );
  }
}

const DefaultModalContainer = styled("div")({
  width: "100%",
  maxWidth: "700px",
  backgroundColor: colors.dark,
  color: colors.white,
  boxShadow: "0 0 25px rgba(0,0,0,0.25), 0 7px rgba(0,0,0,0.5)",
  position: "relative",
  margin: "auto",
});

const CloseButton = styled(Ui.PlainButton)({
  position: "absolute",
  left: "100%",
  color: colors.white,
  marginLeft: "5px",
  "@media(max-width: 600px)": {
    left: "auto",
    right: 0,
    bottom: "100%",
    marginLeft: 0,
    marginBottom: 0,
  },
});

export const DefaultModal = ({children, progress = 1, onClose, ...rest}) => (
  <DefaultModalContainer {...rest}>
    {onClose && (
      <CloseButton onClick={onClose} style={{transform: `translateY(${100 * (1 - progress)}px)`}}>
        <SVG name="close" height={24} />
      </CloseButton>
    )}
    {children}
  </DefaultModalContainer>
);

export const DefaultModalSpawner = ({renderOverlay, onClose, modalProps, ...rest}) => (
  <ModalSpawner
    onClose={onClose}
    renderOverlay={progress => (
      <DefaultModal onClose={onClose} progress={progress} {...modalProps}>
        {renderOverlay()}
      </DefaultModal>
    )}
    {...rest}
  />
);
