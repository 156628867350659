import deepEqual from "deep-equal";

export const STATUS = {
  valid: "valid",
  invalid: "invalid",
  loading: "loading",
};

class Changes {
  changes = {};
  add(model, id) {
    if (!this.changes[model]) {
      this.changes[model] = new Set([id]);
    } else {
      this.changes[model].add(id);
    }
  }
}

const updateExistingValues = (existingData, newData) => {
  let hasChanged = false;
  // eslint-disable-next-line no-restricted-syntax, guard-for-in
  for (const field in newData) {
    const newValue = newData[field];
    if (!(field in existingData) || !deepEqual(existingData[field], newValue, {strict: true})) {
      existingData[field] = newValue;
      hasChanged = true;
    }
  }
  return hasChanged;
};

function collectFieldDeps(descriptions) {
  // fieldDeps = {Card: {content: {fields: ["title", "tags"], rels: []}}
  const deps = {};
  Object.keys(descriptions).forEach(modelName => {
    deps[modelName] = {};
    const belongsToDescriptions = descriptions[modelName].belongsTo;
    Object.keys(belongsToDescriptions).forEach(relName => {
      const belongsToDesc = belongsToDescriptions[relName];
      const {rels} = (deps[modelName][belongsToDesc.fk] = deps[modelName][belongsToDesc.fk] || {
        fields: [],
        rels: [],
      });
      const counterParts = descriptions[belongsToDesc.model].hasMany;
      Object.keys(counterParts)
        .filter(counterRelName => {
          const hasManyDesc = counterParts[counterRelName];
          return hasManyDesc.model === modelName && hasManyDesc.fk === belongsToDesc.fk;
        })
        .forEach(counterRelName => {
          rels.push({
            modelName: belongsToDesc.model,
            relName: counterRelName,
            fk: belongsToDesc.fk,
          });
        });
    });
    const fieldDescriptions = descriptions[modelName].fields;
    Object.keys(fieldDescriptions).forEach(fieldName => {
      deps[modelName][fieldName] = {fields: [], rels: []};
    });
    Object.keys(fieldDescriptions).forEach(fieldName => {
      fieldDescriptions[fieldName].deps.forEach(dep => deps[modelName][dep].fields.push(fieldName));
    });
    Object.keys(belongsToDescriptions).forEach(relName => {
      const belongsToDesc = belongsToDescriptions[relName];
      belongsToDesc.deps.forEach(fieldName =>
        deps[modelName][fieldName].fields.push(belongsToDesc.fk, relName)
      );
    });
  });
  Object.keys(descriptions).forEach(modelName => {
    const hasManyDescriptions = descriptions[modelName].hasMany;
    Object.keys(hasManyDescriptions).forEach(relName => {
      const hasManyDesc = hasManyDescriptions[relName];
      hasManyDesc.deps.forEach(dep =>
        deps[hasManyDesc.model][dep].rels.push({modelName, relName, fk: hasManyDesc.fk})
      );
    });
  });
  return deps;
}

function collectHasManyModelDeps(descriptions) {
  // hasManyModelDeps = {attachment: [{model: "card", relName: "attachments", fk: "cardId"}]}
  const deps = {};
  Object.keys(descriptions).forEach(modelName => {
    deps[modelName] = [];
  });
  Object.keys(descriptions).forEach(modelName => {
    const hasManyDescriptions = descriptions[modelName].hasMany;
    Object.keys(hasManyDescriptions).forEach(relName => {
      const {model, fk, via} = hasManyDescriptions[relName];
      if (model === "$raw") return;
      deps[model].push({model: modelName, relName, fk, via: (via && via.table) || via});
    });
  });
  return deps;
}

function collectBelongsToModelDeps(descriptions) {
  // belongsToModelDeps = {milestone: [{model: "card", relName: "milestone", fk: "cardId"}]}
  const deps = {};
  Object.keys(descriptions).forEach(modelName => {
    deps[modelName] = [];
  });
  Object.keys(descriptions).forEach(modelName => {
    const belongsToDescriptions = descriptions[modelName].belongsTo;
    Object.keys(belongsToDescriptions).forEach(relName => {
      const {model, fk} = belongsToDescriptions[relName];
      deps[model].push({model: modelName, relName, fk});
    });
  });
  return deps;
}

function collectFksToFields(descriptions) {
  const fksToFields = {};
  Object.keys(descriptions).forEach(modelName => {
    fksToFields[modelName] = {};
    const belongsDesc = descriptions[modelName].belongsTo;
    Object.entries(belongsDesc).forEach(([relName, {fk}]) => {
      fksToFields[modelName][fk] = relName;
    });
  });
  return fksToFields;
}

export default function createCache(descriptions, collect, onChange, registerOnChangeListener) {
  const fieldDeps = collectFieldDeps(descriptions);
  const hasManyModelDeps = collectHasManyModelDeps(descriptions);
  const belongsToModelDeps = collectBelongsToModelDeps(descriptions);
  const fksToFields = collectFksToFields(descriptions);

  const modelCache = {};
  const optModelCache = {}; // optimistic cache
  // optModelCache = {
  //   card: {
  //     1: {
  //       priority: [{value: 3, mutationId: 2}, {value: 2, mutationId: 4}]
  //     }
  //   }
  // };

  const popFromOptModelCache = (mutationId, cb) => {
    Object.keys(optModelCache).forEach(modelName => {
      const modelIds = Object.keys(optModelCache[modelName]);
      let deletedModelIds = 0;
      modelIds.forEach(id => {
        const fieldNames = Object.keys(optModelCache[modelName][id]);
        let deletedNames = 0;
        fieldNames.forEach(field => {
          const deleteIdx = [];
          const fields = optModelCache[modelName][id][field];
          fields.forEach(({value, mutationId: entryMutationId, type}, i) => {
            if (entryMutationId === mutationId) {
              if (cb) cb({modelName, id, field, value, type});
              deleteIdx.push(i);
            }
          });
          if (deleteIdx.length > 0) {
            let offset = 0;
            deleteIdx.forEach(i => fields.splice(i - (offset += 1), 1));
            if (fields.length === 0) {
              delete optModelCache[modelName][id][field];
              deletedNames += 1;
            }
          }
        });
        if (deletedNames === fieldNames.length) {
          delete optModelCache[modelName][id];
          deletedModelIds += 1;
        }
      });
      if (deletedModelIds === modelIds.length) {
        delete optModelCache[modelName];
      }
    });
  };

  const findAffectedRelations = (mutationDesc, data, cb) => {
    const {model: modelName, via, type} = mutationDesc;
    const cacheEntry = (modelCache[modelName] && modelCache[modelName][data.id]) || {value: {}};
    // hasManyModelDeps = {attachment: [{model: "card", relName: "attachments", fk: "cardId"}]}
    hasManyModelDeps[modelName].forEach(({model: parentModelName, relName, fk, via: parentVia}) => {
      if (via && via !== parentVia) return;
      const parentCacheEntries = [];
      if (parentModelName === "_root") {
        parentCacheEntries.push({parentCacheEntry: modelCache[parentModelName], parentId: null});
      } else {
        if (data[fk] === null) return; // explicitly has no parent
        const parentId = data[fk] || cacheEntry.value[fk] || data.id;
        const ids = parentId ? [parentId] : data.ids;
        if (ids && ids.length) {
          const parentModelCache = modelCache[parentModelName];
          if (parentModelCache)
            ids.forEach(
              id =>
                parentModelCache[id] &&
                parentCacheEntries.push({parentCacheEntry: parentModelCache[id], parentId: id})
            );
        }
        if (!parentCacheEntries.length) {
          Object.keys(modelCache[parentModelName] || {}).forEach(k => {
            if (modelCache[parentModelName][k])
              parentCacheEntries.push({
                parentCacheEntry: modelCache[parentModelName][k],
                parentId: k,
              });
          });
          console.info(
            `could not find fk "${fk}" within modified "${modelName}" instance or within passed data, therefore invalidating ${
              parentCacheEntries.length
            } ${parentModelName} for ${relName}`
          );
        }
      }
      // if we create a new item, don't look up for rels which exclude the new one via order
      const desc = descriptions[parentModelName].hasMany[relName];
      let ignoreIfOrder = null;
      if (type === "create" && desc && desc.order) {
        const orderM = desc.order.match(/^(\S+)( desc|asc)?(?:,|$)/);
        if (orderM) {
          ignoreIfOrder = new RegExp(`${orderM[1]}\\$lte?`);
        }
      }
      // explaining the last bit of the regexp: if creating a new item, don't invalidate any relations with id contraints, since they're not affected. (exception: adding a via relation)
      const fieldVariantsRegex = new RegExp(
        `^(count:|exists:|((last|first)(N\\d+)?:))?${relName}($|\\(${
          type === "create" && !via ? ".+:.+\\)" : ""
        })`
      );
      parentCacheEntries.forEach(({parentCacheEntry, parentId}) => {
        Object.keys(parentCacheEntry.value).forEach(relField => {
          // HINT: could optimize delete by looking if data.id is part of the relation
          const m = relField.match(fieldVariantsRegex);
          if (m && !(m[5] && ignoreIfOrder && ignoreIfOrder.test(m[5]))) {
            cb(parentCacheEntry, relField, parentModelName, parentId);
          }
        });
      });
    });
  };

  const cache = {
    modelCache,
    add(data) {
      const changes = new Changes();
      const now = new Date();
      Object.keys(data).forEach(modelName => {
        if (!(modelName in descriptions)) throw new Error(`Don't know model "${modelName}"`);
        if (modelName === "_root") {
          const existing = modelCache[modelName];
          const entry = data[modelName];
          if (existing) {
            existing.lastLoaded = now;
            const hasUpdated = updateExistingValues(existing.value, entry);
            if (hasUpdated) {
              changes.add(modelName, null);
            }
            existing.value = {...existing.value, ...entry};
            Object.keys(entry).forEach(fieldName => delete existing.status[fieldName]);
          } else {
            modelCache[modelName] = {
              lastLoaded: now,
              lastAccessed: null,
              value: entry,
              status: {},
            };
          }
        } else {
          modelCache[modelName] = modelCache[modelName] || {};
          const entries = data[modelName];
          const desc = descriptions[modelName];
          Object.keys(entries).forEach(entryId => {
            const entry = entries[entryId];
            if (entry === null) {
              console.warn(
                `"${modelName}" with id "${entryId}" is a deleted/inaccessible resource. Loading such instances should be avoided.`
              );
              changes.add(modelName, entryId);
              modelCache[modelName][entryId] = null;
            } else {
              Object.keys(entry).forEach(fieldName => {
                if (desc.fields[fieldName] && desc.fields[fieldName].type === "date") {
                  entry[fieldName] = entry[fieldName] === null ? null : new Date(entry[fieldName]);
                } else if (desc.fields[fieldName] && desc.fields[fieldName].type === "day") {
                  const parts = entry[fieldName].split("-");
                  entry[fieldName] = {
                    year: parseInt(parts[0], 10),
                    month: parseInt(parts[1], 10),
                    day: parseInt(parts[2], 10),
                  };
                }
              });
              const existing = modelCache[modelName][entryId];
              if (existing) {
                existing.lastLoaded = now;
                const hasUpdated = updateExistingValues(existing.value, entry);
                if (hasUpdated) {
                  changes.add(modelName, entryId);
                }
                Object.keys(entry).forEach(fieldName => delete existing.status[fieldName]);
              } else {
                changes.add(modelName, entryId);
                modelCache[modelName][entryId] = {
                  lastLoaded: now,
                  lastAccessed: null,
                  value: entry,
                  status: {},
                };
              }
            }
          });
        }
      });
      onChange(changes.changes);
    },
    // hint: could also be just inaccessible.
    isDeleted(modelName, id) {
      return !!modelCache[modelName] && modelCache[modelName][id] === null;
    },
    has(modelName, id, field) {
      const cachedEntry =
        modelName === "_root"
          ? modelCache[modelName]
          : modelCache[modelName] && modelCache[modelName][id];
      if (cachedEntry === null) return true; // this indicates a deleted resource for which we mark all fields as "cached"
      return cachedEntry !== undefined && cachedEntry.value[field] !== undefined;
    },
    hasValid(modelName, id, field) {
      const cachedEntry =
        modelName === "_root"
          ? modelCache[modelName]
          : modelCache[modelName] && modelCache[modelName][id];
      if (cachedEntry === null) return true; // this indicates a deleted resource for which we mark all fields as "cached"
      return (
        cachedEntry !== undefined &&
        cachedEntry.value[field] !== undefined &&
        !cachedEntry.status[field]
      );
    },
    get(modelName, id, field) {
      const optCachedVals = optModelCache[modelName];
      if (optCachedVals) {
        const optCachedEntry = optCachedVals[id];
        if (optCachedEntry) {
          const fieldVals = optCachedEntry[field];
          if (fieldVals && fieldVals.length) return fieldVals[fieldVals.length - 1].value;
        }
      }
      const cachedVals = modelCache[modelName];
      let cachedEntry;
      if (modelName === "_root") {
        cachedEntry = cachedVals;
      } else {
        cachedEntry = cachedVals && cachedVals[id];
      }
      if (cachedEntry === null) {
        console.warn(
          `tried to get "${field}" on "${modelName}" with id "${id}" which is a deleted/inaccessible resource. Prevent this via checking for \`$meta.isDeleted()\``
        );
        return null;
      }
      cachedEntry.lastAccessed = new Date();
      if (cachedEntry.status[field] === STATUS.invalid) {
        const isHasMany =
          descriptions[modelName].belongsTo[field] ||
          descriptions[modelName].hasMany[
            field.replace(/\(.*\)/g, "").replace(/(last|first)(N\d+)?:/g, "")
          ];
        collect([{modelName, id}], field, isHasMany ? "hasMany" : "field");
        cachedEntry.status[field] = STATUS.loading;
      }
      return cachedEntry.value[field];
    },
    clear(cb) {
      Object.keys(modelCache).forEach(key => delete modelCache[key]);
      Object.keys(optModelCache).forEach(key => delete optModelCache[key]);
      if (cb) {
        const unsub = registerOnChangeListener(() => {
          cb();
          unsub();
        });
      }
      onChange("all");
    },
    addOptimisticAction(
      mutationId,
      mutationDesc,
      rawData,
      isImplicit = false,
      changes = new Changes()
    ) {
      const {model: modelName, convertDataForOptimistic} = mutationDesc;
      const data = convertDataForOptimistic ? convertDataForOptimistic(rawData) : rawData;
      const modelDesc = descriptions[modelName];
      if (mutationDesc.type === "update") {
        /* eslint-disable prefer-const */
        let {id: entryId, ids, ...changedFields} = data;
        /* eslint-enable prefer-const */
        if (!entryId && modelDesc.idProp !== "id") {
          entryId = changedFields[modelDesc.idProp];
          delete changedFields[modelDesc.idProp];
        }
        const changeFieldNames = Object.keys(changedFields);
        const changedFieldNamesWithValue = changeFieldNames.filter(
          n => changedFields[n] !== undefined
        );
        if ((entryId || ids) && changedFieldNamesWithValue.length) {
          const models = (optModelCache[modelName] = optModelCache[modelName] || {});
          (entryId ? [entryId] : ids).forEach(id => {
            const entry = (models[id] = models[id] || {});
            changes.add(modelName, id);
            changedFieldNamesWithValue.forEach(field => {
              const fieldName = fksToFields[modelName][field] || field;
              (entry[fieldName] = entry[fieldName] || []).push({
                value: changedFields[field],
                mutationId,
                type: "update",
              });
            });
          });
        }
      } else if (mutationDesc.type === "create") {
        const fields = Object.keys(data).filter(
          field => field !== "id" && (fksToFields[modelName][field] || modelDesc.fields[field])
        );
        if (fields.length === 0) return;
        const models = (optModelCache[modelName] = optModelCache[modelName] || {});
        const id = `$opt$${mutationId}`;
        const entry = (models[id] = models[id] || {});
        fields.forEach(field => {
          const fieldName = fksToFields[modelName][field] || field;
          (entry[fieldName] = entry[fieldName] || []).push({
            value: data[field],
            mutationId,
            type: "create",
          });
        });
      } else if (mutationDesc.type === "delete") {
        const idParts = [];
        const hasAllParts = modelDesc.idPropAsArray.every(idProp => {
          if (data[idProp]) {
            idParts.push(data[idProp]);
            return true;
          }
          return false;
        });
        if (hasAllParts) {
          const id = idParts.length > 1 ? idParts.join("-") : idParts[0];
          findAffectedRelations(
            mutationDesc,
            data,
            (parentCacheEntry, relName, parentModelName) => {
              const parentDesc = descriptions[parentModelName];
              const parentVal = parentCacheEntry.value[relName];
              if (Array.isArray(parentVal)) {
                const indexOfId = parentVal.indexOf(id);
                if (indexOfId >= -1) {
                  const parentId = parentCacheEntry.value[parentDesc.idProp];
                  const models = (optModelCache[parentModelName] =
                    optModelCache[parentModelName] || {});
                  const entry = (models[parentId] = models[parentId] || {});
                  changes.add(parentModelName, parentId);
                  const fieldVals = (entry[relName] = entry[relName] || []);
                  const latestValue = fieldVals.length
                    ? fieldVals[fieldVals.length - 1].value
                    : parentVal;
                  fieldVals.push({
                    value: latestValue.filter(relId => relId !== id),
                    mutationId,
                    type: "deleteFromCollection",
                  });
                }
              } else if (parentVal && parentVal === id) {
                const parentId = parentCacheEntry.value[parentDesc.idProp];
                const models = (optModelCache[parentModelName] =
                  optModelCache[parentModelName] || {});
                const entry = (models[parentId] = models[parentId] || {});
                changes.add(parentModelName, parentId);
                (entry[relName] = entry[relName] || []).push({
                  value: null,
                  mutationId,
                  type: "deleteFromCollection",
                });
              }
            }
          );
        }
      } else if (mutationDesc.type === "custom") {
        mutationDesc.fn(
          modelCache,
          optModelCache,
          data,
          mutationId,
          cache.addOptimisticAction,
          changes
        );
      }
      if (mutationDesc.implicit) {
        mutationDesc
          .implicit(data, {_isOptimistic: true}, modelCache[mutationDesc.model] || {})
          .forEach(({desc: implDesc, data: implData}) =>
            cache.addOptimisticAction(mutationId, implDesc, implData, true, changes)
          );
      }
      if (!isImplicit) onChange(changes.changes);
    },
    failedOptimisticAction(mutationId) {
      popFromOptModelCache(mutationId);
      onChange("all");
    },
    resolveOptimisticAction(mutationId, mutationDesc, data, retVal) {
      popFromOptModelCache(mutationId, ({modelName, id, field, value, type}) => {
        if (type === "update" || type === "deleteFromCollection") {
          const cacheEntry = (modelCache[modelName] || {})[id];
          if (!cacheEntry) return;
          cacheEntry.value[field] = value;
        } else if (type === "create") {
          if (!retVal.id) return;
          const entries = modelCache[modelName] || {};
          const cacheEntry = (entries[retVal.id] = entries[retVal.id] || {
            lastLoaded: null,
            lastAccessed: null,
            value: {},
            status: {},
          });
          cacheEntry.value[field] = value;
          cacheEntry.status[field] = STATUS.invalid;
        }
      });
      // no call of onChange() since this is gonna be set by invalidate!
    },
    invalidate(desc, data, retVal, isImplicit = false, changes = new Changes()) {
      if (desc.type === "void") return;
      if (desc.type === "update") {
        /* eslint-disable prefer-const */
        let {id: entryId, ids, ...changedFields} = data;
        /* eslint-enable prefer-const */
        const {model: modelName} = desc;
        const modelDesc = descriptions[modelName];
        if (
          !entryId &&
          modelDesc.idProp !== "id" &&
          modelDesc.idPropAsArray.every(p => p in changedFields)
        ) {
          entryId = modelDesc.idPropAsArray
            .map(p => {
              const val = changedFields[p];
              delete changedFields[p];
              return val;
            })
            .join("|");
        }
        if (!entryId && !ids)
          console.info(
            `updating all ${
              Object.keys(modelCache[modelName] || {}).length
            } instances of ${modelName} since no id was given`
          );
        ids = entryId ? [entryId] : ids || Object.keys(modelCache[modelName] || {});
        ids.forEach(id => {
          let cacheEntry = (modelCache[modelName] || {})[id];
          if (!cacheEntry) {
            // we're using a fake entry here, to evaluate whether stuff like `deckId` affects already loaded models
            cacheEntry = {value: data, status: {}};
          } else {
            changes.add(modelName, id);
          }
          Object.keys(changedFields).forEach(field => {
            const fieldName = fksToFields[modelName][field] || field;
            cacheEntry.status[fieldName] = STATUS.invalid;
            const fieldDep = fieldDeps[modelName][field];
            if (fieldDep) {
              fieldDep.fields.forEach(dep => {
                cacheEntry.status[dep] = STATUS.invalid;
              });
              fieldDep.rels.forEach(({modelName: relModelName, relName, fk}) => {
                const relCacheEntry = modelCache[relModelName];
                if (!relCacheEntry) return;

                // if we know who owned the element before, we now can tell which relation to change
                let relIds = Object.keys(relCacheEntry).filter(
                  relId => relCacheEntry[relId] !== null
                );
                let filterIds = null;
                let hasFkInValue = fk in cacheEntry.value;
                let sourceFkVal;
                if (!hasFkInValue) {
                  // maybe `deckId` is not present as a field, but the belongsTo-value `deck`!?
                  const alternativeFk = Object.keys(descriptions[modelName].belongsTo).find(
                    belongsToRelName =>
                      descriptions[modelName].belongsTo[belongsToRelName].fk === fk
                  );
                  hasFkInValue = alternativeFk in cacheEntry.value;
                  if (hasFkInValue) sourceFkVal = cacheEntry.value[alternativeFk];
                } else {
                  sourceFkVal = cacheEntry.value[fk];
                }
                if (hasFkInValue) {
                  filterIds = [];
                  if (sourceFkVal) filterIds.push(sourceFkVal);
                  if (data[fk] && data[fk] !== sourceFkVal) filterIds.push(data[fk]);
                  relIds = relIds.filter(relId =>
                    filterIds.includes(
                      relCacheEntry[relId].value[descriptions[relModelName].idProp]
                    )
                  );
                }

                const fieldVariantsRegex = new RegExp(
                  `^(count:|exists:|((last|first)(N\\d+)?:))?${relName}($|\\()`
                );
                relIds.forEach(relId => {
                  const entry = relCacheEntry[relId];
                  Object.keys(entry.value).forEach(relField => {
                    if (fieldVariantsRegex.test(relField)) {
                      entry.status[relField] = STATUS.invalid;
                      changes.add(relModelName, relId);
                    }
                  });
                });
              });
            }
          });
        });
      } else if (desc.type === "create" || desc.type === "delete") {
        if (desc.type === "delete") {
          if (!data.id) {
            console.warn("deleting a resource without 'data.id'", data);
          } else {
            belongsToModelDeps[desc.model].forEach(({model: parentModelName, relName, fk}) => {
              Object.keys(modelCache[parentModelName] || {}).forEach(id => {
                const parentCacheEntry = modelCache[parentModelName][id];
                if (!parentCacheEntry) return;
                if (parentCacheEntry.value[relName] === data.id) {
                  parentCacheEntry.status[relName] = STATUS.invalid;
                  changes.add(parentModelName, id);
                }
                if (parentCacheEntry.value[fk] === data.id) {
                  parentCacheEntry.status[fk] = STATUS.invalid;
                  changes.add(parentModelName, id);
                }
              });
            });
          }
        }
        findAffectedRelations(desc, data, (parentCacheEntry, relName, parentModelName, relId) => {
          changes.add(parentModelName, relId);
          parentCacheEntry.status[relName] = STATUS.invalid;
        });
      } else if (desc.type === "custom") {
        desc.fn(modelCache, optModelCache, data);
      } else {
        throw new Error(`don't know action type "${desc.type}"!`);
      }
      if (desc.implicit) {
        desc
          .implicit(data, retVal, modelCache[desc.model] || {})
          .forEach(({desc: implDesc, data: implData}) =>
            cache.invalidate(implDesc, implData, retVal, true, changes)
          );
      }
      if (!isImplicit) onChange(changes.changes);
    },
  };
  return cache;
}
