import React from "react";
import PropTypes from "prop-types";
import {GetMe} from "../lib/api";

const roleToSortIndex = {admin: 0, mod: 1, user: 3, loggedOut: 3};

const MinRole = props => <GetMe>{me => <MinRoleWithUser user={me} {...props} />}</GetMe>;

export const MinRoleWithUser = ({minRole, user, children}) => {
  const hasRole =
    user &&
    user.$meta.isFieldLoaded("role", true) &&
    roleToSortIndex[user.role] <= roleToSortIndex[minRole];
  return children(hasRole ? user : null);
};

MinRoleWithUser.propTypes = {minRole: PropTypes.oneOf(["admin", "mod"]).isRequired};

export default MinRole;
