import cookies from "js-cookie";
import {api} from "./api";

export const login = token => {
  cookies.set("auth-token", token, {
    expires: 2 * 365,
    secure: process.env.NODE_ENV !== "development",
  });
};

export const logout = cb => {
  cookies.remove("auth-token");
  api.cache.clear(cb);
};

export const getToken = () => {
  return cookies.get("auth-token");
};
