const dimensions = {
  TRACKING_VERSION: "dimension1",
};

const TRACKING_VERSION = "2";

const trackError = error => {
  window.ga("send", "event", {
    eventCategory: "Script",
    eventAction: "error",
    eventLabel: (error && error.stack) || "(not set)",
    nonInteraction: true,
  });
};

const trackErrors = () => {
  const loadErrorEvents = (window.__e && window.__e.q) || [];
  for (let event of loadErrorEvents) trackError(event.error);
  window.addEventListener("error", event => trackError(event.error));
};

export function gaStart() {
  const ga = (window.ga = window.ga || ((...args) => (window.ga.q = window.ga.q || []).push(args)));

  ga("create", process.env.REACT_APP_GA_ID, "auto");
  ga("set", "transport", "beacon");
  ga("set", dimensions.TRACKING_VERSION, TRACKING_VERSION);
  ga("send", "pageview");
  ga("set", "anonymizeIp", true);

  trackErrors();
}

export const gaTrackPageView = (path, isReplace) => {
  window.ga("set", "page", path);
  if (!isReplace) {
    window.ga("send", {
      hitType: "pageview",
      page: path,
    });
  }
};

export const gaTrackEvent = ({category, action, label, value}) => {
  window.ga("send", {
    hitType: "event",
    eventCategory: category,
    eventAction: action,
    eventLabel: label,
    eventValue: value,
  });
};
