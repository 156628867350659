import React from "react";
import styled from "react-emotion";
import colors from "../lib/colors";
import {exceptionEvent} from "../lib/error-logger";

const Pill = styled("div")({
  position: "fixed",
  bottom: 10,
  left: "50%",
  width: 250,
  background: colors.danger,
  zIndex: 26,
  fontSize: 14,
  lineHeight: 1.3,
  marginLeft: -250 / 2,
  textAlign: "center",
  padding: "10px 20px",
  color: colors.white,
  boxShadow: "0 1px 15px rgba(0,0,0,0.1)",
});

export default class ExceptionAlert extends React.Component {
  state = {
    showExceptionThrown: false,
  };

  componentDidMount() {
    this.unsubscribeExceptionEvent = exceptionEvent.addListener(() =>
      this.setState({showExceptionThrown: true})
    );
  }

  componentWillUnmount() {
    this.unsubscribeExceptionEvent();
  }

  render() {
    return this.state.showExceptionThrown ? (
      <Pill>
        Something just broke :(
        <br />
        You might need to reload this page.
      </Pill>
    ) : null;
  }
}
