import {Link} from "react-router-dom";
import React from "react";
import colors from "../../lib/colors";
import styled from "react-emotion";
import common from "./common";
import messenger from "../../lib/messenger";

class ButtonBehaviour extends React.Component {
  state = {pending: false};

  componentWillUnmount() {
    this.umounted = true;
  }

  handleClick = (...args) => {
    const retVal = this.props.forwardProps.onClick(...args);
    if (retVal && typeof retVal.then === "function") {
      this.setState({pending: true});
      return retVal.then(
        () => !this.umounted && this.setState({pending: false}),
        error => {
          messenger.send(error.message || error.toString(), 10000);
          if (!this.umounted) this.setState({pending: false});
        }
      );
    }
  };

  render() {
    const {
      comp: Comp,
      forwardProps: {onClick, disabled, innerRef, ...rest},
    } = this.props;
    const {pending} = this.state;
    return (
      <Comp
        myInnerRef={innerRef}
        onClick={onClick && this.handleClick}
        disabled={disabled || pending}
        {...rest}
      />
    );
  }
}

/* eslint-disable jsx-a11y/anchor-has-content */
class LinkOrButton extends React.Component {
  render() {
    const {
      myInnerRef,
      disabled,
      component: Comp,
      active,
      size,
      danger,
      white,
      onDark,
      ...rest
    } = this.props;
    return Comp ? (
      <Comp innerRef={myInnerRef} disabled={disabled} {...rest} />
    ) : rest.to ? (
      <Link innerRef={myInnerRef} {...rest} />
    ) : "href" in rest ? (
      <a ref={myInnerRef} {...rest} />
    ) : (
      <button ref={myInnerRef} disabled={disabled} type="button" {...rest} />
    );
  }
}

const sharedStyles = ({disabled}) => ({
  display: "inline-block",
  textAlign: "center",
  width: "auto",
  border: "none",
  lineHeight: 1,
  textTransform: "uppercase",
  ...(disabled ? {opacity: 0.5} : {cursor: "pointer"}),
  transitionProperty: "background-color, box-shadow, color",
  color: "inherit",
  backgroundColor: "transparent",
});

const sizeInfo = {
  small: {
    padding: "5px 10px 3px",
    fontSize: "12px",
  },
  normal: {
    padding: "10px 20px 8px",
    fontSize: "14px",
  },
  big: {
    padding: "15px 25px 13px",
    fontSize: "16px",
    boxShadow: `0 4px ${colors.brandDark}, 0 4px 8px rgba(0,0,0,0.35)`,
    ":hover": {
      boxShadow: `0 4px ${colors.brand}, 0 2px 8px rgba(0,0,0,0.35)`,
    },
  },
};

const PlainButton = styled(LinkOrButton)(sharedStyles, ({onDark}) => ({
  padding: "2px",
  ":hover": {
    backgroundColor: onDark ? colors.fade(colors.white, 0.1) : colors.fade(colors.black, 0.1),
  },
}));

const RaisedButton = styled(LinkOrButton)(
  sharedStyles,
  common.headerFont,
  ({size = "normal", white}) => ({
    ...sizeInfo[size],
    color: white ? colors.brandDark : colors.white,
    backgroundColor: white ? colors.white : colors.brand,
    boxShadow: [
      `0 2px ${white ? colors.shadowWhite : colors.brandDark}`,
      white ? "0 2px 5px rgba(0,0,0,0.25)" : "0 2px 8px rgba(0,0,0,0.35)",
    ].join(","),
    ":hover": {
      backgroundColor: white ? colors.brandPale : colors.brandLight,
      boxShadow: [
        `0 2px ${white ? colors.brandLighter : colors.brand}`,
        white ? "0 2px 5px rgba(0,0,0,0.25)" : "0 2px 8px rgba(0,0,0,0.35)",
      ].join(","),
    },
  })
);

const sharedMiniActionStyles = [
  sharedStyles,
  common.headerFont,
  {
    letterSpacing: 1,
    fontSize: "9px",
    padding: "4px 10px 2px",
  },
];

export const miniActionStyles = [
  ...sharedMiniActionStyles,
  ({danger, active, onDark}) => {
    if (onDark) {
      return {
        ...(active
          ? {
              color: colors.brandPale,
              backgroundColor: colors.fade(colors.brand, 0.8),
              ":hover": {
                backgroundColor: colors.brand,
                color: colors.white,
              },
            }
          : {
              color: colors.shadowWhite,
              backgroundColor: "#888",
              ":hover": {
                backgroundColor: "#999",
                color: colors.white,
              },
            }),
        boxShadow: `0 2px rgba(30,30,30,0.5), 0 2px 5px rgba(0,0,0,0.15)`,
      };
    } else {
      const col = danger ? colors.danger : colors.brand;
      if (active) {
        return {
          color: colors.white,
          backgroundColor: colors.fade(col, 0.8),
          boxShadow: `0 2px ${colors.mix(col, colors.dark, 0.3)}, 0 2px 5px rgba(0,0,0,0.15)`,
          ":hover": {
            backgroundColor: col,
          },
        };
      } else {
        return {
          backgroundColor: colors.white,
          color: colors.fade(col, 0.5),
          boxShadow: `0 2px ${colors.fade(col, 0.05)}, 0 2px 5px rgba(0,0,0,0.0)`,
          ":hover": {
            color: colors.fade(col, 0.8),
            backgroundColor: colors.mix(colors.white, col, 0.05),
            boxShadow: `0 2px ${colors.fade(col, 0.2)}, 0 2px 5px rgba(0,0,0,0.15)`,
          },
        };
      }
    }
  },
];

const MiniActionButton = styled(LinkOrButton)(miniActionStyles);

const PaleMiniActionButton = styled(LinkOrButton)(
  ...sharedMiniActionStyles,
  {
    color: colors.fade(colors.black, 0.1),
    boxShadow: "0 2px rgba(0,0,0,0.05), 0 2px 5px rgba(0,0,0,0.0)",
    ":hover": {
      color: colors.fade(colors.black, 0.5),
      backgroundColor: "rgba(0,0,0,0.05)",
      boxShadow: "0 2px rgba(0,0,0,0.2), 0 2px 5px rgba(0,0,0,0.15)",
    },
  },
  ({danger}) =>
    danger && {
      ":hover": {
        color: colors.danger,
        backgroundColor: colors.dangerPale,
        boxShadow: `0 2px ${colors.danger}, 0 2px 5px rgba(0,0,0,0.15)`,
      },
    }
);

const WhiteMiniActionButton = styled(LinkOrButton)(...sharedMiniActionStyles, {
  color: colors.fade(colors.white, 0.7),
  boxShadow: "0 2px rgba(0,0,0,0.25), 0 2px 5px rgba(0,0,0,0.0)",
  ":hover": {
    color: colors.fade(colors.white, 0.9),
    backgroundColor: "rgba(0,0,0,0.05)",
    boxShadow: "0 2px rgba(0,0,0,0.2), 0 2px 5px rgba(0,0,0,0.15)",
  },
});

export const Plain = props => <ButtonBehaviour forwardProps={props} comp={PlainButton} />;
export const Raised = props => <ButtonBehaviour forwardProps={props} comp={RaisedButton} />;
export const MiniAction = props => <ButtonBehaviour forwardProps={props} comp={MiniActionButton} />;
export const PaleMiniAction = props => (
  <ButtonBehaviour forwardProps={props} comp={PaleMiniActionButton} />
);
export const WhiteMiniAction = props => (
  <ButtonBehaviour forwardProps={props} comp={WhiteMiniActionButton} />
);
