import React from "react";
import {Link} from "react-router-dom";
import styled from "react-emotion";
import colors from "../../lib/colors";
import common from "./common";

const PixelTop = styled("div")({
  backgroundColor: colors.dark,
  color: colors.white,
  backgroundImage: `url(${require("../../img/pixel-back-16x.png")})`,
  backgroundRepeat: "repeat-x",
  backgroundSize: "320px 32px",
  position: "relative",
});

const Col = styled("div")({
  display: "flex",
  flexDirection: "column",
});
const Row = styled("div")({display: "flex"});
const DarkH1 = styled("h1")(
  ({isOnDark}) => ({
    color: isOnDark ? colors.white : colors.brand,
    textShadow: `${!isOnDark ? `0 1px 0 ${colors.white}, ` : ""}0 4px 1px rgba(0,0,0,${
      isOnDark ? 0.75 : 0.15
    })`,
  }),
  {marginBottom: 30}
);
const H1 = styled(DarkH1)(common.headerFont);
const H1OnModal = styled(p => <DarkH1 isOnDark {...p} />)(common.headerFont, {
  position: "relative",
  top: -60,
  marginBottom: -30,
});

const pillBgColors = {
  success: {bg: colors.success, dark: colors.successDark},
  warning: {bg: colors.warning, dark: colors.warningDark},
  danger: {bg: colors.danger, dark: colors.dangerDark},
};

const PillOverlay = styled(Col, {shouldForwardProp: p => p !== "type"})(({type}) => ({
  fontSize: 14,
  backgroundColor: pillBgColors[type || "warning"].bg,
  color: colors.fade(colors.white, 0.9),
  padding: "10px 20px",
  boxShadow: `0 3px ${pillBgColors[type || "warning"].dark}`,
  justifyContent: "center",
  alignItems: "center",
  textAlign: "center",
}));

const UnreadPill = styled("div")(common.headerFont, {
  position: "absolute",
  top: -4,
  right: -4,
  padding: "2px 3px 1px",
  backgroundColor: colors.danger,
  color: colors.white,
  fontSize: 10,
});

const SmallHeader = styled("h2")(common.headerFont, {
  fontSize: 16,
});

const Hole = styled("div")({
  padding: "20px",
  backgroundColor: "#666",
  boxShadow: "inset 0 4px #333, inset 0 4px 15px rgba(0,0,0,0.3)",
});

const LinkOrSpan = props => (props.to ? <Link {...props} /> : <span {...props} />);

const UserLink = styled(LinkOrSpan)({
  display: "block",
  fontWeight: "bold",
  transitionProperty: "color",
  ":hover": {
    color: colors.brand,
  },
});

export {Col, Row, H1, H1OnModal, PixelTop, PillOverlay, SmallHeader, Hole, UserLink, UnreadPill};
