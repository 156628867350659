import React from "react";
import {TransitionMotion, spring, presets} from "react-motion";
import MiniEvent from "./mini-event";
import styled from "react-emotion";
import {PillOverlay} from "../components/ui/basics";

const messagesEvent = new MiniEvent();

let nextId = 1;

// opts = {msToRemove, type}
export default {
  send: (val, opts) => messagesEvent.emit(val, opts || {}),
};

const MessageComp = styled(PillOverlay)({
  position: "absolute",
  bottom: 0,
  left: 0,
  minHeight: 75,
  width: 200,
});

class Message extends React.Component {
  componentDidMount() {
    this.checkTimeout();
  }

  componentDidUpdate() {
    this.checkTimeout();
  }

  checkTimeout() {
    if (this.props.frontMost) {
      if (!this.timeoutId) {
        this.timeoutId = setTimeout(() => {
          this.props.onRemove(this.props.id);
          this.timeoutId = null;
        }, this.props.opts.msToRemove || 3000);
      }
    } else if (this.timeoutId) {
      clearTimeout(this.timeoutId);
      this.timeoutId = null;
    }
  }

  timeoutId = null;

  render() {
    const {message, onRemove, style, id, opts} = this.props;
    return (
      <MessageComp onClick={() => onRemove(id)} style={style} type={opts.type}>
        {message}
      </MessageComp>
    );
  }
}

const MessengerAreaComp = styled("div")({
  position: "fixed",
  bottom: 15,
  left: 15,
  zIndex: 30,
});

export class MessengerArea extends React.Component {
  state = {
    messages: [],
  };

  componentDidMount() {
    this.unsubscribe = messagesEvent.addListener((message, opts) =>
      this.setState(s => {
        const id = nextId;
        nextId += 1;
        return {messages: [...s.messages, {id, message, opts}]};
      })
    );
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  willLeave = orgStyle => ({
    val: spring(0, presets.gentle),
    stackVal: spring(orgStyle.style.stackVal.val - 1, presets.gentle),
  });

  willEnter = () => ({val: 0, stackVal: this.state.messages.length + 4});

  handleRemove = msgId => {
    this.setState({messages: this.state.messages.filter(msg => msg.id !== msgId)});
  };

  render() {
    const {messages} = this.state;
    return (
      <TransitionMotion
        willLeave={this.willLeave}
        willEnter={this.willEnter}
        styles={() =>
          messages.map((message, i) => ({
            key: message.id,
            style: {val: spring(1, presets.gentle), stackVal: spring(i, presets.gentle)},
            data: message,
          }))
        }
      >
        {styles => (
          <MessengerAreaComp>
            {styles.map(({key, style: {val, stackVal}, data: {id, message, opts}}) => (
              <Message
                key={key}
                id={id}
                message={message}
                frontMost={messages.length > 0 && id === messages[messages.length - 1].id}
                onRemove={this.handleRemove}
                opts={opts}
                style={{
                  opacity: val,
                  transform: `translateX(${10 * stackVal}px)`,
                  pointerEvents: val < 0.3 ? "none" : undefined,
                }}
              />
            ))}
          </MessengerAreaComp>
        )}
      </TransitionMotion>
    );
  }
}
