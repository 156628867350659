import defaultValidations from "react-reform/opt/validations";
import debounce from "lodash/debounce";
import fetch from "../fetch";
import {stringify} from "query-string";

const unique = getUrl => {
  const cachedData = {};

  const check = debounce((value, cb) => {
    cachedData[value] = "pending";
    fetch(getUrl(value))
      .then(res => {
        if (res.status < 300) {
          cachedData[value] = res.jsonData.ok;
          cb();
        } else {
          return Promise.reject({status: res.status, error: new Error(res.jsonData.error)});
        }
      })
      .catch(e => console.warn(e));
  }, 200);

  return (value, ctx, validateAgainCb) => {
    if (cachedData[value] === undefined) {
      if (value) {
        check(value, validateAgainCb);
        return "pending";
      } else {
        cachedData[value] = true;
      }
    }
    return cachedData[value];
  };
};

export default {
  ...defaultValidations,
  uniqueEmail: () => {
    return {
      isValid: unique(email => `/services/check-email-unique?${stringify({email})}`),
      errorMessage: () => "This email address is already registered",
      hintMessage: () => "needs to be a unique email",
    };
  },
  validUsername: {
    isValid: val => /^(|[A-Za-z0-9][\w-]*)$/.test(val),
    hintMessage: () => "may only consist of letters, numbers, '-' and '_'",
    errorMessage: () => "not a valid user name (may only consist of letters, numbers, '-' and '_')",
  },
  uniqueUsername: () => {
    return {
      isValid: unique(name => `/services/check-name-unique?${stringify({name})}`),
      errorMessage: () => "This name is already taken",
      hintMessage: () => "needs to be a unique name",
    };
  },
};
