import React from "react";
import ReactDOM from "react-dom";
import PropTypes from "prop-types";

const originatesFromNode = (elem, goal) => {
  if (elem === goal) return true;
  if (!elem) return false;
  return originatesFromNode(elem.parentNode, goal);
};

export class ClickOutsideProvider extends React.Component {
  static childContextTypes = {
    deselect: PropTypes.func,
  };

  constructor(props) {
    super(props);
    this.listeners = [];
  }

  getChildContext() {
    return {
      deselect: fn => {
        this.listeners.unshift(fn);
        return () => {
          this.listeners.splice(this.listeners.indexOf(fn), 1);
        };
      },
    };
  }

  handleClick = e => {
    const selection = window.getSelection();
    if (selection.rangeCount > 0) {
      const range = selection.getRangeAt(0);
      if (!(range.startContainer === range.endContainer && range.startOffset === range.endOffset))
        return;
    }
    // eslint-disable-next-line no-underscore-dangle
    if (Array.isArray(e._dispatchListeners) && e._dispatchListeners.length > 1) {
      // there's already another onClick handler. So we don't want to deselect nothing. Maybe.
    } else {
      this.listeners.some(listener => listener(e) !== false);
    }
  };

  render() {
    return this.props.children(this.handleClick);
  }
}

export default class ClickOutside extends React.Component {
  static propTypes = {
    onClick: PropTypes.func.isRequired,
  };

  static contextTypes = {
    deselect: PropTypes.func.isRequired,
  };

  componentWillMount() {
    this.unsubscribe = this.context.deselect(this.checkIfDeselect);
  }

  componentWillUnmount() {
    this.unsubscribe();
  }

  checkIfDeselect = e => {
    if (
      e.target.nodeName === "TEXTAREA" ||
      e.target.nodeName === "INPUT" ||
      e.target.nodeName === "SELECT"
    )
      return null;
    if (e.target.getAttribute("contenteditable")) return null;
    const node = ReactDOM.findDOMNode(this);
    if (!originatesFromNode(e.target, node)) {
      return this.props.onClick(e); // if this returns "false" propagate to next listener
    } else {
      return null;
    }
  };

  render() {
    return this.props.children;
  }
}
