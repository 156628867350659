import React from "react";
import Ui from "./ui";
import styled from "react-emotion";
import colors from "../lib/colors";
import {ModalLocation} from "./ModalRegistry";

const Pill = styled(Ui.PlainButton)({
  position: "fixed",
  bottom: 0,
  right: 15,
  fontSize: 11,
  fontWeight: "bold",
  backgroundColor: colors.fade(colors.white, 0.6),
  boxShadow: "0 0 5px rgba(0,0,0,0.1)",
  padding: "5px 7px 3px",
  color: colors.dark,
  "&:hover": {
    backgroundColor: colors.white,
    boxShadow: "0 0 5px rgba(0,0,0,0.2)",
  },
  zIndex: 30,
});

const FeedbackTab = () => (
  <ModalLocation modal="feedback">{to => <Pill to={to}>Feedback</Pill>}</ModalLocation>
);

export default FeedbackTab;
