export default {
  headerFont: {
    fontFamily: "Nunito, sans-serif",
    fontWeight: 900,
  },
  bodyFont: {
    fontFamily: "Aleo, serif",
  },
  full: {position: "absolute", top: 0, left: 0, right: 0, bottom: 0},
  fixed: {position: "fixed", top: 0, bottom: 0, right: 0, left: 0},
  stretchCol: {
    display: "flex",
    flexDirection: "column",
    flex: "auto",
  },
  center: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
  },
  buttonReset: {
    textAlign: "inherit",
    color: "inherit",
    border: "none",
    backgroundColor: "transparent",
    padding: 0,
    fontWeight: "inherit",
    borderRadius: 0,
  },
};
