import React from "react";
import styled from "react-emotion";
import colors from "../lib/colors";
import {InterpolateIfActive} from "./Effects";
import Ui from "./ui";
import {GetRoot, api} from "../lib/api";
import Pager from "./Pager";
import {Link} from "react-router-dom";
import {linkToContest} from "../lib/contest-utils";
import {PiqAsLink} from "./Piq";
import {AvatarLink} from "./Avatar";
import shrinkText from "../lib/shrinkText";
import FromNow from "./FromNow";
import Media from "react-media";
import {isBot} from "../lib/utils";
import {feedClosedStorage} from "../lib/storage";
import {threadLink} from "../pages/Forum/utils";
import Catch from "./Catch";

const tinyPiqStyle = {
  position: "relative",
  top: 3,
  display: "inline-block",
  padding: 0,
  backgroundColor: colors.white,
  boxShadow: `0 1px 5px -1px rgba(0,0,0,0.4)`,
};
const feedBreakRaw = "(max-width: 650px)";
export const feedBreak = `@media${feedBreakRaw}`;

const PiqFromId = ({id, css}) =>
  id ? <TinyPiq picture={api.getModel({modelName: "picture", id})} css={css} /> : null;
const TinyPiq = ({picture, css}) => (
  <PiqAsLink picture={picture} size="tiny" css={{...tinyPiqStyle, ...css}} />
);
const TinyAvatar = ({user}) => (
  <React.Fragment>
    <AvatarLink user={user} size="tiny" withShadow css={tinyPiqStyle} /> {user && user.name}
  </React.Fragment>
);
const CommentTarget = ({targetType, targetId}) => {
  if (targetType === "news")
    return (
      <Link to={`/news/${targetId}`}>
        news: <i>{api.getModel({modelName: "newsEntry", id: targetId}).title}</i>
      </Link>
    );
  if (targetType === "contest") return <TinyContest id={targetId} />;
  if (targetType === "picture") return <PiqFromId id={targetId} />;
  if (targetType === "user")
    return <TinyAvatar user={api.getModel({modelName: "user", id: targetId})} />;
  return null;
};

const CommentContentComp = styled("div")({
  padding: "5px 10px",
  fontSize: 12,
  backgroundColor: colors.white,
  boxShadow: "0 3px rgba(180,180,180,0.9), 0 3px 10px rgba(0,0,0,0.2)",
  overflowX: "hidden",
});
const CommentContent = ({model, prop}) => (
  <CommentContentComp>
    {model && model[prop] ? shrinkText(model[prop], 60) : "[deleted]"}
  </CommentContentComp>
);

const FillerComp = styled("span")({
  color: "#aaa",
});
const Filler = ({children}) => <FillerComp> {children} </FillerComp>;

const ContestLink = styled(Link)({fontWeight: "bold"});
const TinyContest = ({id}) => {
  if (!id) return null;
  const contest = api.getModel({modelName: "contest", id});
  if (!contest) return null;
  return <ContestLink to={linkToContest(contest)}>{contest.keyword}</ContestLink>;
};

const ThreadLink = ({threadId, boardId, showLast}) => {
  const thread = api.getModel({modelName: "thread", id: threadId});
  if (!thread) return <span>[unknown thread]</span>;
  return (
    <ContestLink
      to={threadLink({thread, board: api.getModel({modelName: "board", id: boardId})}, showLast)}
    >
      {shrinkText(thread.title, 40)}
    </ContestLink>
  );
};

const typeInfo = {
  enter_contest: {
    to: ({contestStartDate}) =>
      linkToContest(api.getModel({modelName: "contest", id: contestStartDate})),
    Multi: ({user, dataList}) => (
      <React.Fragment>
        <TinyAvatar user={user} />
        <Filler>entered</Filler>
        {dataList.map(({contestStartDate, piqId}, i) => (
          <React.Fragment key={i}>
            <PiqFromId id={piqId} />
            <Filler>into</Filler>
            <TinyContest id={contestStartDate} />
          </React.Fragment>
        ))}
      </React.Fragment>
    ),
  },
  finish_voting: {
    Multi: ({user, dataList}) => (
      <React.Fragment>
        <TinyAvatar user={user} />
        <Filler>finished voting for</Filler>
        {dataList.map(({contestStartDate}, i) => (
          <TinyContest key={i} id={contestStartDate} />
        ))}
      </React.Fragment>
    ),
  },
  piq_like: {
    Multi: ({user, dataList}) => (
      <React.Fragment>
        <TinyAvatar user={user} />
        <Filler>liked</Filler>
        {dataList.map(({piqId}, i) => (
          <PiqFromId key={piqId || i} id={piqId} css={{marginRight: 5}} />
        ))}
      </React.Fragment>
    ),
  },
  signup: {
    Multi: ({user}) => (
      <React.Fragment>
        <TinyAvatar user={user} />
        <Filler>has signed up</Filler>
      </React.Fragment>
    ),
  },
  profile_piq: {
    Multi: ({user, dataList}) => (
      <React.Fragment>
        <TinyAvatar user={user} />
        <Filler>set their profile piq to</Filler>
        {dataList.map(({piqId}, i) => (
          <PiqFromId key={piqId || i} id={piqId} css={{marginRight: 5}} />
        ))}
      </React.Fragment>
    ),
  },
  draw_piq: {
    Multi: ({user, dataList}) => (
      <React.Fragment>
        <TinyAvatar user={user} />
        <Filler>has drawn</Filler>
        {dataList.map(({piqId}, i) => (
          <PiqFromId key={piqId || i} id={piqId} css={{marginRight: 5}} />
        ))}
      </React.Fragment>
    ),
  },
  branch_piq: {
    Multi: ({user, dataList}) => (
      <React.Fragment>
        <TinyAvatar user={user} />
        <Filler>branched</Filler>
        {dataList.map(
          ({baseId, piqId}, i) =>
            piqId && (
              <React.Fragment key={piqId || i}>
                <PiqFromId id={baseId} />
                <Filler>to</Filler>
                <PiqFromId id={piqId} css={{marginRight: 5}} />
              </React.Fragment>
            )
        )}
      </React.Fragment>
    ),
  },
  comment: {
    Multi: ({user, dataList}) => (
      <div>
        <div css={{marginBottom: 10}}>
          <TinyAvatar user={user} />
          <Filler>{dataList[0].targetType === "user" ? "shouted to" : "commented on"}</Filler>
          {dataList.map(({targetType, targetId, commentId}, i) => (
            <CommentTarget key={commentId || i} targetType={targetType} targetId={targetId} />
          ))}
        </div>
        {dataList.map(
          ({commentId}, i) =>
            commentId && (
              <CommentContent
                key={commentId || i}
                model={api.getModel({modelName: "comment", id: commentId})}
                prop="content"
              />
            )
        )}
      </div>
    ),
  },
  new_thread: {
    Multi: ({user, dataList}) => (
      <div>
        <div css={{marginBottom: 10}}>
          <TinyAvatar user={user} />
          <Filler>created a new thread: </Filler>
          <ThreadLink {...dataList[0]} />
        </div>
        {dataList.map(
          ({postId}, i) =>
            postId && (
              <CommentContent
                key={postId || i}
                model={api.getModel({modelName: "post", id: postId})}
                prop="excerpt"
              />
            )
        )}
      </div>
    ),
  },
  new_post: {
    Multi: ({user, dataList}) => (
      <div>
        <div css={{marginBottom: 10}}>
          <TinyAvatar user={user} />
          <Filler>posted to: </Filler>
          <ThreadLink {...dataList[0]} showLast />
        </div>
        {dataList.map(
          ({postId}, i) =>
            postId && (
              <CommentContent
                key={postId || i}
                model={api.getModel({modelName: "post", id: postId})}
                prop="excerpt"
              />
            )
        )}
      </div>
    ),
  },
};

const typeToKey = {
  enter_contest: i => i.user.id,
  finish_voting: i => i.user.id,
  piq_like: i => i.user.id,
  signup: i => i.user.id,
  profile_piq: i => i.user.id,
  draw_piq: i => i.user.id,
  branch_piq: i => i.user.id,
  comment: i => i.id,
  new_thread: i => i.id,
  new_post: i => i.id,
  null: () => null,
};

const determineGroups = items => {
  const groups = [];
  const groupByKey = {};
  items.forEach(i => {
    const key = [i.type, (typeToKey[i.$meta.get("type", null)] || typeToKey.null)(i)].join("-");
    i.$meta.get("data");
    i.$meta.get("user");
    i.$meta.get("createdAt");
    if (!i.$meta.get("type")) return;
    if (!groupByKey[key]) {
      const group = {
        key,
        user: i.user,
        date: i.createdAt,
        count: 1,
        type: i.type,
        dataList: [i.data],
      };
      groupByKey[key] = group;
      groups.push(group);
    } else {
      groupByKey[key].count += 1;
      groupByKey[key].dataList.push(i.data);
    }
  });
  return groups;
};

const FeedItemRow = styled("div")({
  fontSize: 14,
  lineHeight: 1.4,
  textAlign: "center",
  marginBottom: 25,
});

const DateComp = styled("div")({
  marginBottom: 1,
  fontSize: 10,
  textTransform: "uppercase",
  color: "#aaa",
  textAlign: "center",
});

const renderGroup = feedItems =>
  feedItems.length === 0 ? null : (
    <div key={feedItems[0].id}>
      {determineGroups(feedItems).map(({key, date, user, count, type, dataList}) => {
        const info = typeInfo[type];
        if (!info) {
          console.warn(`unknown feed type: "${type}"`);
          return null;
        }
        return (
          <FeedItemRow key={key}>
            <DateComp>
              – <FromNow date={date} /> –
            </DateComp>
            {count === 1 && info.Single ? (
              <info.Single data={dataList[0]} user={user} />
            ) : (
              <info.Multi dataList={dataList} user={user} count={count} ids />
            )}
          </FeedItemRow>
        );
      })}
    </div>
  );

const UNIntro = styled("div")({
  fontSize: 10,
  textTransform: "uppercase",
  color: colors.fade(colors.white, 0.7),
});

const UNTitle = styled("div")(Ui.common.headerFont, {
  marginBottom: 5,
  whiteSpace: "nowrap",
  textOverflow: "ellipsis",
  overflow: "hidden",
});

const UnseenNews = ({root, me}) => {
  if (!me) return null;
  const seeNews = () => api.mutate.users.seenNews({id: me.id});
  const date = me.$meta.get("lastNewsSeenAt", null);
  if (!date) return null;
  const latestNews = root.$meta.first("news", {createdAt$gt: date});
  if (!latestNews) return null;
  const title = latestNews.$meta.get("title", null);
  if (!title) return null;
  return (
    <Ui.PillOverlay css={{display: "block", margin: "-20px -20px 20px"}}>
      <UNIntro>New news!</UNIntro>
      <UNTitle>{title}</UNTitle>
      <Ui.Row css={{marginRight: -10, justifyContent: "center"}}>
        <Ui.MiniActionButton
          css={{marginRight: 10}}
          to={`/news/${latestNews.id}`}
          onClick={seeNews}
        >
          View
        </Ui.MiniActionButton>
        <Ui.MiniActionButton css={{marginRight: 10}} onClick={seeNews}>
          Close
        </Ui.MiniActionButton>
      </Ui.Row>
    </Ui.PillOverlay>
  );
};

class Content extends React.PureComponent {
  render() {
    return (
      <GetRoot sub>
        {root => (
          <div>
            <UnseenNews root={root} me={root.loggedInUser} />
            <Ui.SmallHeader css={{textAlign: "center", marginBottom: 15, color: colors.midGray}}>
              Activity Feed
            </Ui.SmallHeader>

            <Pager
              desc
              collection={{parent: root, relName: "feedItems"}}
              count={15}
              renderIfEmpty={() => <div>Nothing to show yet</div>}
              renderGroup={renderGroup}
              loadNextComp={({isLoadingPage, loadNext}) => (
                <Ui.Col css={{alignItems: "center", marginTop: 25}}>
                  <Ui.MiniActionButton white disabled={isLoadingPage} onClick={loadNext}>
                    load more
                  </Ui.MiniActionButton>
                </Ui.Col>
              )}
            />
          </div>
        )}
      </GetRoot>
    );
  }
}

const Backdrop = styled("div")(Ui.common.fixed, {
  backgroundColor: "rgba(100, 100, 100, 0.97)",
  zIndex: 1,
});

const Placemaker = styled("div")(({isClosed, isMobile}) => ({
  flex: "none",
  position: "relative",
  zIndex: 2,
  width: isMobile || isClosed ? 0 : 250,
}));

const Container = styled("div")({
  position: "absolute",
  top: 0,
  right: 0,
  bottom: 0,
  width: 250,
  backgroundColor: colors.nearWhite,
  color: colors.dark,
  boxShadow: ["inset 23px 0 25px -20px rgba(0,0,0,0.3)", "inset 3px 0 #ccc"].join(","),

  "@media(max-width: 450px)": {
    width: ["95vw", "calc(100vw - 25px)"],
  },
});

const Inner = styled("div")({
  overflowX: "hidden",
  maxHeight: "100%",
  overflowY: "auto",
  padding: "20px 20px",
});

const TabArea = styled("div")({
  position: "absolute",
  right: "100%",
  top: 20,
  bottom: 0,
  pointerEvents: "none",
  zIndex: -1,
});

const Tab = styled(Ui.PlainButton)({
  position: "sticky",
  top: 20,
  pointerEvents: "auto",
  backgroundColor: colors.dark,
  color: colors.white,
  fontSize: 16,
  padding: 2,
  boxShadow: [`0 2px ${colors.darker}`, "0 2px 8px rgba(0,0,0,0.35)"].join(","),
  ":hover": {
    backgroundColor: colors.darker,
    boxShadow: [`0 2px ${colors.black}`, "0 2px 8px rgba(0,0,0,0.35)"].join(","),
  },
});

class CloseLogic extends React.Component {
  state = {
    isClosed: feedClosedStorage.get() || this.props.shouldClose,
    userPrefersClose: feedClosedStorage.get(),
  };

  componentWillReceiveProps(nextProps) {
    if (this.state.userPrefersClose) return;
    if (this.props.shouldClose !== nextProps.shouldClose) {
      this.setState({isClosed: nextProps.shouldClose});
    }
  }

  handleToggle = () => {
    const {isClosed} = this.state;
    feedClosedStorage.set(!isClosed);
    this.setState({isClosed: !isClosed, userPrefersClose: !isClosed});
  };

  render() {
    const {isClosed} = this.state;
    return this.props.children(isClosed, this.handleToggle);
  }
}

const Feed = ({closed}) => (
  <Media query={feedBreakRaw}>
    {small => (
      <CloseLogic shouldClose={closed || small || isBot()}>
        {(isClosed, toggle) => (
          <React.Fragment>
            <InterpolateIfActive noDefaultStyle isActive={small && !isClosed}>
              {val =>
                val !== 0 && (
                  <Backdrop style={{opacity: val, pointerEvents: val > 0.5 ? "auto" : "none"}} />
                )
              }
            </InterpolateIfActive>
            <Placemaker isMobile={small} isClosed={isClosed}>
              <InterpolateIfActive noDefaultStyle isActive={!isClosed}>
                {val => (
                  <Container style={{transform: `translate3d(${100 * (1 - val)}%,0,0)`}}>
                    {val !== 0 && (
                      <Inner>
                        <Catch>
                          <Content />
                        </Catch>
                      </Inner>
                    )}
                    <TabArea>
                      <Tab onClick={toggle}>
                        <Ui.Icon.ArrowRight style={{transform: `scaleX(${2 * val - 1})`}} />
                      </Tab>
                    </TabArea>
                  </Container>
                )}
              </InterpolateIfActive>
            </Placemaker>
          </React.Fragment>
        )}
      </CloseLogic>
    )}
  </Media>
);

export default Feed;
