import React from "react";
import PropTypes from "prop-types";

export const titleFormatter = new Intl.DateTimeFormat(undefined, {
  weekday: "long",
  year: "numeric",
  month: "long",
  day: "numeric",
  hour: "numeric",
  minute: "numeric",
  second: "numeric",
});
export const shortFormatter = new Intl.DateTimeFormat(undefined, {
  year: "numeric",
  month: "short",
  day: "numeric",
});

export default class FromNow extends React.Component {
  static propTypes = {
    date: PropTypes.instanceOf(Date).isRequired,
  };

  componentDidMount() {
    this.scheduleNextUpdate();
  }

  componentWillUnmount() {
    if (this.updateId) clearTimeout(this.updateId);
  }

  shouldComponentUpdate = ({date: nextDate}) => {
    return !!(!this.props.date || this.props.date.getTime() !== nextDate.getTime());
  };

  scheduleNextUpdate() {
    const {date} = this.props;
    const now = new Date();
    const diffInS = date && date.getTime ? (now.getTime() - date.getTime()) / 1000 : 5;
    this.updateId = setTimeout(() => {
      this.forceUpdate();
      this.scheduleNextUpdate();
    }, diffInS ** 0.5 * 100);
  }

  render() {
    const {date, onlyDays, rough} = this.props;
    const now = new Date();
    if (!date || !date.getTime) return <span>unknown</span>;
    let timeStr = "right now";

    if (rough) {
      const daysLeft = Math.round((date.getTime() - now.setHours(0, 0, 0, 0)) / (24 * 3600 * 1000));
      if (daysLeft === 0) {
        timeStr = "today";
      } else if (daysLeft === 1) {
        timeStr = "tomorrow";
      } else if (daysLeft === -1) {
        timeStr = "yesterday";
      } else if (daysLeft > 0 && daysLeft < 31) {
        timeStr = `in ${daysLeft} days`;
      } else if (daysLeft < 0 && daysLeft > -31) {
        timeStr = `${-daysLeft} days ago`;
      } else {
        timeStr = `on ${shortFormatter.format(date)}`;
      }
    } else {
      const diffInS = (now.getTime() - date.getTime()) / 1000;
      if (diffInS > 0) {
        if (diffInS < 60) {
          timeStr = "a few seconds ago";
        } else if (diffInS < 60 * 60) {
          if (diffInS < 60 * 2) {
            timeStr = "a minute ago";
          } else {
            timeStr = `${Math.floor(diffInS / 60)} minutes ago`;
          }
        } else if (diffInS < 60 * 60 * 24) {
          if (diffInS < 60 * 60 * 2) {
            timeStr = "an hour ago";
          } else {
            timeStr = `${Math.floor(diffInS / (60 * 60))} hours ago`;
          }
        } else if (diffInS < 60 * 60 * 24 * 31 || onlyDays) {
          if (diffInS < 60 * 60 * 24 * 2) {
            timeStr = "a day ago";
          } else {
            timeStr = `${Math.floor(diffInS / (60 * 60 * 24))} days ago`;
          }
        } else {
          timeStr = `on ${shortFormatter.format(date)}`;
        }
      } else if (diffInS < -60) {
        if (diffInS > -60 * 60) {
          if (diffInS > -60 * 2) {
            timeStr = "in a minute";
          } else {
            timeStr = `in ${Math.floor(diffInS / -60)} minutes`;
          }
        } else if (diffInS > -60 * 60 * 24) {
          if (diffInS > -60 * 60 * 2) {
            timeStr = "in an hour";
          } else {
            timeStr = `in ${Math.floor(diffInS / (-60 * 60))} hours`;
          }
        } else if (diffInS > -60 * 60 * 24 * 31 || onlyDays) {
          if (diffInS > -60 * 60 * 24 * 2) {
            timeStr = "in a day";
          } else {
            timeStr = `in ${Math.floor(diffInS / (-60 * 60 * 24))} days`;
          }
        } else {
          timeStr = `on ${shortFormatter.format(date)}`;
        }
      }
    }

    return <span title={titleFormatter.format(date)}>{timeStr}</span>;
  }
}
