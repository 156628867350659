import React from "react";
import {api, uuidEvent, GetMe} from "../lib/api";
import styled from "react-emotion";
import {InterpolateIfActive} from "./Effects";
import colors from "../lib/colors";
import {getToken} from "../lib/auth";

const Outer = () => (
  <GetMe>{me => (!me || me.$meta.isLoaded) && <PushUpdates key={`${me && me.id}`} />}</GetMe>
);

const Pill = styled("div")({
  position: "fixed",
  bottom: 10,
  left: "50%",
  width: 200,
  background: colors.danger,
  zIndex: 3,
  fontSize: 11,
  marginLeft: -100,
  textAlign: "center",
  padding: "5px 10px",
  color: colors.white,
  boxShadow: "0 1px 15px rgba(0,0,0,0.1)",
});

class PushUpdates extends React.Component {
  state = {
    status: "initial", // initial, active, failed
  };

  socket = null;
  unmounting = false;
  retryTimeoutId = null;
  nextRetryDuration = 500;

  componentDidMount() {
    this.connect();
  }

  componentWillUnmount() {
    this.unmounting = true;
    if (this.socket) this.socket.close();
    if (this.retryTimeoutId) clearTimeout(this.retryTimeoutId);
  }

  connect() {
    try {
      this.socket = new WebSocket(process.env.REACT_APP_WS_ENDPOINT);
    } catch (e) {
      console.warn(`Error when connecting to WebSocket ${process.env.REACT_APP_WS_ENDPOINT}:`, e);
      this.socket = null;
      return;
    }
    this.socket.onopen = this.handleOpen;
    this.socket.onmessage = this.handleMessage;
    this.socket.onclose = this.handleClose;
  }

  handleOpen = () => {
    this.nextRetryDuration = 500;
    this.setState({status: "active"});
    this.socket.send(
      JSON.stringify({
        type: "register",
        payload: {authToken: getToken(), accountId: this.props.accountId},
      })
    );
  };

  handleClose = () => {
    if (this.unmounting) return;
    this.socket = null;
    this.setState({status: "failed"});

    this.retryTimeoutId = setTimeout(() => {
      this.retryTimeoutId = null;
      this.connect();
    }, this.nextRetryDuration);

    this.nextRetryDuration = Math.min(this.nextRetryDuration * 1.5, 1000 * 60 * 5);
  };

  handleMessage = message => {
    const data = JSON.parse(message.data);
    switch (data.type) {
      case "uuid":
        uuidEvent.emit(data.payload);
        break;
      case "action":
        const description = api.mutate.$descriptions[data.payload.actionName];
        if (!description) {
          console.warn(`Don't know action '${data.payload.actionName}'`);
          return;
        }
        api.cache.invalidate(description, data.payload.payload, data.payload.retVal);
        break;
      default:
        throw new Error(`unknown ws message with type  "${data.type}"`);
    }
  };

  render() {
    return (
      <InterpolateIfActive isActive={this.state.status === "failed"}>
        {val =>
          val !== 0 && (
            <Pill style={{opacity: val}}>
              Can&#8217;t connect to real-time update server. Trying to reconnect...
            </Pill>
          )
        }
      </InterpolateIfActive>
    );
  }
}

export default (typeof WebSocket !== "undefined" ? Outer : () => <noscript />);
