import Spinner from "./components/Spinner";
import Loadable from "react-loadable";

const createLoadablePageComp = loader =>
  Loadable({
    loader,
    loading: Spinner,
  });

const Routes = {
  Home: createLoadablePageComp(() => import(/* webpackChunkName: "Home" */ "./pages/Home")),
  Draw: createLoadablePageComp(() => import(/* webpackChunkName: "Draw" */ "./pages/Draw")),
  Gallery: createLoadablePageComp(() =>
    import(/* webpackChunkName: "Gallery" */ "./pages/Gallery")
  ),
  Picture: createLoadablePageComp(() =>
    import(/* webpackChunkName: "Picture" */ "./pages/Picture")
  ),
  User: createLoadablePageComp(() => import(/* webpackChunkName: "User" */ "./pages/User")),
  ResetPassword: createLoadablePageComp(() =>
    import(/* webpackChunkName: "ResetPassword" */ "./pages/ResetPassword")
  ),
  VerifyEmail: createLoadablePageComp(() =>
    import(/* webpackChunkName: "VerifyEmail" */ "./pages/VerifyEmail")
  ),
  ContestIndex: createLoadablePageComp(() =>
    import(/* webpackChunkName: "ContestIndex" */ "./pages/ContestIndex")
  ),
  ContestEntries: createLoadablePageComp(() =>
    import(/* webpackChunkName: "ContestEntries" */ "./pages/ContestEntries")
  ),
  ContestVote: createLoadablePageComp(() =>
    import(/* webpackChunkName: "ContestVote" */ "./pages/ContestVote")
  ),
  News: createLoadablePageComp(() => import(/* webpackChunkName: "News" */ "./pages/News")),
  NewsEntry: createLoadablePageComp(() =>
    import(/* webpackChunkName: "NewsEntry" */ "./pages/NewsEntry")
  ),
  About: createLoadablePageComp(() => import(/* webpackChunkName: "About" */ "./pages/About")),
  Imprint: createLoadablePageComp(() =>
    import(/* webpackChunkName: "Imprint" */ "./pages/Imprint")
  ),
  ToS: createLoadablePageComp(() => import(/* webpackChunkName: "ToS" */ "./pages/ToS")),

  Messages: createLoadablePageComp(() =>
    import(/* webpackChunkName: "Messages" */ "./pages/Messages")
  ),

  ClaimPictures: createLoadablePageComp(() =>
    import(/* webpackChunkName: "ClaimPictures" */ "./pages/ClaimPictures")
  ),
  Forum: createLoadablePageComp(() => import(/* webpackChunkName: "Forum" */ "./pages/Forum")),
  Board: createLoadablePageComp(() =>
    import(/* webpackChunkName: "Board" */ "./pages/Forum/Board")
  ),
  Thread: createLoadablePageComp(() =>
    import(/* webpackChunkName: "Thread" */ "./pages/Forum/Thread")
  ),
  RedirectThread: createLoadablePageComp(() =>
    import(/* webpackChunkName: "RedirectThread" */ "./pages/Forum/RedirectThread")
  ),
  Settings: createLoadablePageComp(() =>
    import(/* webpackChunkName: "Settings" */ "./pages/Settings")
  ),
  Admin: createLoadablePageComp(() => import(/* webpackChunkName: "Admin" */ "./pages/Admin")),
};
export default Routes;
