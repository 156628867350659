import React from "react";

const actives = {};

export default class AtMostOne extends React.Component {
  state = {
    isActive: false,
  };

  componentWillUnmount() {
    delete actives[this.props.id];
  }

  handleToggle = () => {
    const {id} = this.props;
    const {isActive} = this.state;
    if (isActive) {
      delete actives[id];
      this.setState({isActive: false});
    } else {
      if (actives[id]) actives[id]();
      actives[id] = () => this.setState({isActive: false});
      this.setState({isActive: true});
    }
  };

  render() {
    const {isActive} = this.state;
    return this.props.children(isActive, this.handleToggle);
  }
}
