import React from "react";
import {onChangeClaims, getClaims} from "../lib/claimPicture";
import {GetMe} from "../lib/api";
import styled from "react-emotion";
import Ui from "./ui";
import Route from "react-router-dom/Route";

const AlertPill = styled(Ui.PillOverlay)({
  position: "absolute",
  top: 0,
  right: 5,
});

class ClaimAlert extends React.Component {
  componentDidMount() {
    this.unsub = onChangeClaims(() => this.forceUpdate());
  }

  componentWillUnmount() {
    this.unsub();
  }

  render() {
    const count = Object.keys(getClaims()).length;
    if (!count) return null;
    return (
      <GetMe>
        {me => (
          <Route path="/claim">
            {({match}) =>
              !match && (
                <div>
                  {me && me.$meta.isLoaded && (
                    <AlertPill>
                      <div>
                        You have drawn {count} piq{count === 1 ? "" : "s"} when logged out.
                      </div>
                      <Ui.WhiteMiniActionButton to="/claim" css={{marginTop: 5}}>
                        Claim {count === 1 ? "it" : "them"}
                      </Ui.WhiteMiniActionButton>
                    </AlertPill>
                  )}
                  {!me && (
                    <AlertPill>
                      <div>
                        You have drawn {count} piq{count === 1 ? "" : "s"}.<br />
                        Register or log in to claim {count === 1 ? "it" : "them"}!
                      </div>
                    </AlertPill>
                  )}
                </div>
              )
            }
          </Route>
        )}
      </GetMe>
    );
  }
}

export default ClaimAlert;
