import React from "react";
import createApi from "./mate";
import MiniEvent from "./mini-event";
import {createIsReadyComponent, createGetRootComponent} from "./mate/react/decorators";
import fetch from "./fetch";

const context = require.context("../../shared_models/", true, /\.json$/);
const descriptions = context.keys().map(context);
export const apiErrorEvent = new MiniEvent();
export const uuidEvent = new MiniEvent();

let currentSessionId = null;
uuidEvent.addListener(uuid => {
  currentSessionId = uuid;
});

const fetcher = (query, sendResults) => {
  console.log("fetching", query);
  fetch("/", {
    method: "post",
    body: JSON.stringify({query}),
  })
    .then(res => {
      if (res.status < 300) {
        return res.jsonData;
      } else {
        return Promise.reject({status: res.status, error: new Error(res.jsonData.error)});
      }
    })
    .then(
      jsonData => {
        apiErrorEvent.emit({status: null, message: null, error: null, ok: true});
        sendResults(null, jsonData);
      },
      error => {
        const err = error.error || error;
        console.log("error!", err && err.message);
        apiErrorEvent.emit({status: error.status, message: err && err.message, err});
        sendResults(err);
      }
    );
};

const sensitiveKeys = new Set(["password", "oldPassword", "token"]);
const strippedData = data => {
  return Object.entries(data).reduce((m, [key, val]) => {
    m[key] = sensitiveKeys.has(key) ? "*****" : val;
    return m;
  }, {});
};

const dispatcher = (actionName, data, sendResult) => {
  console.log("dispatching", actionName, strippedData(data));
  const cleanData = Object.entries(data).reduce(
    (m, [key, val]) => {
      m[key] = typeof val === "string" ? val.trim() : val;
      return m;
    },
    {sessionId: currentSessionId}
  );
  fetch(`/dispatch/${actionName}`, {
    method: "post",
    body: JSON.stringify(cleanData),
  }).then(
    res => {
      const body = res.jsonData;
      if (res.status < 300) {
        sendResult(null, body.payload || {});
      } else {
        const errMessage =
          (body && body.message) ||
          (body && body.payload && body.payload.error) ||
          (body && body.error) ||
          res.statusText ||
          "unknown error";
        sendResult(errMessage, null);
        // apiErrorEvent.emit({status: res && res.status, message: errMessage, body});
      }
    },
    error => {
      const err = error.error || error;
      console.log("error!", err && err.message);
      apiErrorEvent.emit({status: error.status, message: err && err.message, err});
      sendResult((err && err.message ? err.message : err) || "error while sending data", null);
    }
  );
};

const mutations = {
  "comments.create": {
    type: "create",
    model: "comment",
    implicit: ({pictureId, newsId, contestId, authorId}) => [
      ...(pictureId
        ? [{desc: {type: "update", model: "picture"}, data: {id: pictureId, commentCount: null}}]
        : []),
      {
        desc: {
          type: "create",
          model: "commentSubscriber",
        },
        data: {pictureId, newsId, contestId, subscriberId: authorId},
      },
      {desc: {type: "create", model: "notification"}, data: {}},
      {desc: {type: "create", model: "feedItem"}, data: {}},
    ],
  },
  "comments.delete": {
    type: "update",
    model: "comment",
    implicit: ({id}, _, comments) => [
      ...(comments[id] && comments[id].value.pictureId
        ? [
            {
              desc: {type: "update", model: "picture"},
              data: {id: comments[id].value.pictureId, commentCount: null},
            },
          ]
        : []),
      {desc: {type: "delete", model: "notification"}, data: {}},
      {desc: {type: "delete", model: "feedItem"}, data: {}},
    ],
  },
  "comments.update": {
    type: "update",
    model: "comment",
  },
  "comments.subscribe": {
    type: "create",
    model: "commentSubscriber",
  },
  "comments.unsubscribe": {
    type: "delete",
    model: "commentSubscriber",
  },

  "users.login": {
    type: "create",
    model: "user",
  },
  "users.requestPasswordReset": {
    type: "update",
    model: "user",
  },
  "users.resetPassword": {
    type: "update",
    model: "user",
  },
  "users.register": {
    type: "create",
    model: "user",
    implicit: () => [{desc: {type: "create", model: "feedItem"}, data: {}}],
  },
  "users.resendVerificationEmail": {
    type: "update",
    model: "userEmail",
  },
  "users.verifyEmail": {
    type: "update",
    model: "userEmail",
    implicit: (_, {id}) => [
      {desc: {type: "update", model: "userEmail"}, data: {isVerified: undefined, id}},
    ],
  },

  "users.update": {
    type: "update",
    model: "user",
  },
  "users.setProfilePicture": {
    type: "update",
    model: "user",
    implicit: () => [{desc: {type: "create", model: "feedItem"}, data: {}}],
  },
  "users.follow": {
    type: "create",
    model: "follower",
    implicit: () => [{desc: {type: "create", model: "notification"}, data: {}}],
  },
  "users.unfollow": {
    type: "delete",
    model: "follower",
    implicit: () => [
      {desc: {type: "delete", model: "notification"}, data: {}},
      {desc: {type: "delete", model: "collectionMember"}, data: {}},
    ],
  },
  "users.changePassword": {
    type: "update",
    model: "user",
  },
  "users.addEmail": {
    type: "create",
    model: "userEmail",
  },
  "users.deleteEmail": {
    type: "delete",
    model: "userEmail",
  },
  "users.setPrimaryEmail": {
    type: "update",
    model: "userEmail",
    implicit: () => [{desc: {type: "update", model: "userEmail"}, data: {isPrimary: undefined}}],
  },
  "users.seenNews": {
    type: "update",
    model: "user",
    implicit: () => [{desc: {type: "update", model: "user"}, data: {lastNewsSeenAt: null}}],
  },

  "pictures.create": {
    // only called from server
    type: "create",
    model: "picture",
    implicit: () => [
      {desc: {type: "create", model: "notification"}, data: {}},
      {desc: {type: "create", model: "feedItem"}, data: {}},
      {desc: {type: "create", model: "collectionMember"}, data: {}},
    ],
  },
  "pictures.update": {
    type: "update",
    model: "picture",
    implicit: (_, {id}) => [
      {desc: {type: "delete", model: "pictureTag"}, data: {pictureId: id}},
      {desc: {type: "create", model: "notification"}, data: {}},
      {desc: {type: "create", model: "collectionMember"}, data: {}},
      {desc: {type: "delete", model: "collectionMember"}, data: {}},
    ],
  },
  "pictures.delete": {
    type: "delete",
    model: "picture",
    implicit: ({id}) => [
      {desc: {type: "delete", model: "notification"}, data: {}},
      {desc: {type: "update", model: "picture"}, data: {id, isDeleted: true}},
      {desc: {type: "delete", model: "feedItem"}, data: {}},
      {desc: {type: "delete", model: "collectionMember"}, data: {}},
    ],
  },
  "pictures.claim": {
    type: "update",
    model: "picture",
  },
  "pictures.like": {
    type: "create",
    model: "pictureLike",
    implicit: ({pictureId}) => [
      {desc: {type: "update", model: "picture"}, data: {id: pictureId, likes: null}},
      {desc: {type: "create", model: "notification"}, data: {}},
      {desc: {type: "create", model: "feedItem"}, data: {}},
    ],
  },
  "pictures.unlike": {
    type: "delete",
    model: "pictureLike",
    implicit: ({id}, _, likes) => [
      ...(likes[id]
        ? [
            {
              desc: {type: "update", model: "picture"},
              data: {id: likes[id].value.pictureId, likes: null},
            },
          ]
        : []),
      {desc: {type: "delete", model: "notification"}, data: {}},
      {desc: {type: "delete", model: "feedItem"}, data: {}},
    ],
  },
  "pictures.search": {
    type: "void",
  },

  "contests.enterPicture": {
    type: "create",
    model: "contestPicture",
    implicit: () => [{desc: {type: "create", model: "feedItem"}, data: {}}],
  },
  "contests.removePicture": {
    type: "delete",
    model: "contestPicture",
    implicit: () => [{desc: {type: "delete", model: "feedItem"}, data: {}}],
  },

  "notifications.seen": {
    type: "update",
    model: "notification",
  },
  "notifications.seenAll": {
    type: "update",
    model: "notification",
    implicit: (_1, _2, notifications) => [
      {
        desc: {type: "update", model: "notification"},
        data: {
          ids: Object.keys(notifications).map(id => parseInt(id, 10)),
          isSeen: true,
        },
      },
    ],
  },

  "forum.updateIsClosed": {
    type: "update",
    model: "thread",
  },
  "forum.deleteThread": {
    type: "delete",
    model: "thread",
    implicit: () => [
      {desc: {type: "update", model: "thread"}, data: {isDeleted: true}},
      {desc: {type: "delete", model: "feedItem"}, data: {}},
    ],
  },
  "forum.createThread": {
    type: "create",
    model: "thread",
    implicit: ({boardId}, {id}) => [
      {
        desc: {type: "update", model: "board"},
        data: {id: boardId, postCount: null, threadCount: null},
      },
      {desc: {type: "create", model: "feedItem"}, data: {}},
    ],
  },
  "forum.updateThreadTitle": {
    type: "update",
    model: "thread",
  },
  "forum.createPost": {
    type: "create",
    model: "post",
    implicit: ({threadId, authorId}) => [
      {
        desc: {type: "update", model: "thread"},
        data: {id: threadId, lastActiveAt: null, postCount: null},
      },
      {desc: {type: "update", model: "board"}, data: {postCount: null}},
      {desc: {type: "create", model: "feedItem"}, data: {}},
      {desc: {type: "create", model: "notification"}, data: {}},
      {
        desc: {type: "create", model: "threadSubscriber"},
        data: {threadId, subscriberId: authorId},
      },
    ],
  },
  "forum.updatePost": {
    type: "update",
    model: "post",
  },
  "forum.deletePost": {
    type: "update",
    model: "post",
    implicit: ({id}, _, posts) => [
      ...(posts[id] && posts[id].value.threadId
        ? [
            {
              desc: {type: "update", model: "thread"},
              data: {id: posts[id].value.threadId, postCount: null},
            },
          ]
        : []),
      {desc: {type: "update", model: "board"}, data: {postCount: null}},
      {desc: {type: "delete", model: "notification"}, data: {}},
      {desc: {type: "delete", model: "feedItem"}, data: {}},
    ],
  },
  "forum.subscribe": {
    type: "create",
    model: "threadSubscriber",
  },
  "forum.unsubscribe": {
    type: "delete",
    model: "threadSubscriber",
  },

  "messages.createFirst": {
    type: "create",
    model: "messageGroup",
    implicit: ({authorId, targetUserId}, {groupId}) => [
      {desc: {type: "create", model: "message"}, data: {groupId}},
      {desc: {type: "create", model: "messageGroupMember"}, data: {groupId}},
      {
        desc: {type: "create", model: "directMessageGroup"},
        data: {
          groupId,
          lowerUserId: Math.min(authorId, targetUserId),
          higherUserId: Math.max(authorId, targetUserId),
        },
      },
    ],
  },
  "messages.createMessage": {
    type: "create",
    model: "message",
    implicit: ({groupId}) => [
      {
        desc: {type: "update", model: "messageGroupMember"},
        data: {lastSeenAt: null, numUnseenMessages: null, groupId},
      },
    ],
  },
  "messages.deleteMessage": {
    type: "update",
    model: "message",
    implicit: ({id}, _, messages) => [
      ...(messages[id] && messages[id].value.groupId
        ? [
            {
              desc: {type: "update", model: "messageGroupMember"},
              data: {groupId: messages[id].value.groupId, numUnseenMessages: null},
            },
          ]
        : []),
    ],
  },
  "messages.seenMessages": {
    type: "update",
    model: "messageGroupMember",
    implicit: ({groupId, userId}) => [
      {
        desc: {type: "update", model: "messageGroupMember"},
        data: {lastSeenAt: null, numUnseenMessages: null, groupId, userId},
      },
    ],
  },

  "collections.create": {
    type: "create",
    model: "collection",
  },
  "collections.update": {
    type: "update",
    model: "collection",
  },
  "collections.delete": {
    type: "delete",
    model: "collection",
  },
  "collections.createMember": {
    type: "create",
    model: "collectionMember",
  },
  "collections.deleteMember": {
    type: "delete",
    model: "collectionMember",
  },
  "collections.setAsHighlight": {
    type: "update",
    model: "user",
  },

  "admin.createNews": {
    type: "create",
    model: "newsEntry",
  },
  "admin.updateNews": {
    type: "update",
    model: "newsEntry",
  },
  "admin.deleteNews": {
    type: "delete",
    model: "newsEntry",
  },

  "admin.createContest": {
    type: "create",
    model: "contest",
  },
  "admin.updateContest": {
    type: "update",
    model: "contest",
  },
  "admin.deleteContest": {
    type: "delete",
    model: "contest",
  },
  "admin.createPartner": {
    type: "create",
    model: "adPartner",
  },
  "admin.createCampaign": {
    type: "create",
    model: "adCampaign",
    implicit: () => [{desc: {type: "create", model: "adVariant"}, data: {}}],
  },
  "admin.createVariant": {
    type: "create",
    model: "adVariant",
  },
  "admin.updateCampaign": {
    type: "update",
    model: "adCampaign",
  },
  "admin.updateVariant": {
    type: "update",
    model: "adVariant",
    implicit: () => [
      {
        desc: {type: "update", model: "adVariant"},
        data: {imgUrl: null, imgHeight: null, imgWidth: null},
      },
    ],
  },

  // only invoked by server
  "dailyPictures.create": {
    type: "create",
    model: "dailyPicture",
    implicit: () => [{desc: {type: "create", model: "notification"}, data: {}}],
  },
  // only invoked by server
  "contests.finish": {
    type: "create",
    model: "contestPicture",
    implicit: () => [{desc: {type: "create", model: "notification"}, data: {}}],
  },
  // only invoked by server
  "contests.finishVoting": {
    type: "create",
    model: "feedItem",
  },
  // only invoked by server
  "achievements.create": {
    type: "create",
    model: "achievementReceiver",
  },
};

export const api = createApi(descriptions, fetcher, mutations, dispatcher);

export const GetRoot = createGetRootComponent(api);
export const GetMe = ({children, ...rest}) => (
  <GetRoot {...rest}>{root => children(root.loggedInUser)}</GetRoot>
);
export const IsReady = createIsReadyComponent(api);
